import {
  ADDON_PAYMENT_URL,
  CHANGE_PASSWORD_URL,
  CONTACT_US_URL,
  INBOX_URL,
  MEMBERSHIP_URL,
  PAYMENT_URL,
  PERSONAL_URL,
} from 'utility/routesURL';
import { BottomNavigation, BottomNavigationAction, Card, CircularProgress } from '@material-ui/core';
import { InboxIcon, MembershipIcon, PaymentMethodIcon, PersonalIcon } from 'components/Icons';
import React, { useEffect, useMemo, useState } from 'react';
import { paymentActions, personalActions } from 'redux/actionCreators';
import PERSONAL_CONSTANTS, { SESSION_STORAGE_NAMES } from 'constants/personalConstant';
import { useHistory, useLocation } from 'react-router-dom';

import CONSTANTS from 'constants/commonContants';
import ConfirmModal from 'components/ModalConfirm';
import LoginLog from 'components/LoginLog';
import ViewLoginLog from 'components/ViewLoginLog';
import { checkAvatarUrl } from 'services';
import classNames from 'classnames';
import format from 'number-format.js';
import { getIconUrl } from 'utility/helpers';
import moment from 'moment';
import { useDeviceCheck } from 'utility/screenDetect';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useTypedSelector } from 'redux/configureStore';
import { PAYMENT_PURPOSES } from 'constants/paymentConstants';

interface Props {
  children: React.ReactNode;
  membershipDetails: any;
  isEditPayment: boolean;
  isMakePayment: boolean;
  setIsMakePayment: (open: boolean) => void;
  setDirtyTabForm: Function;
  dirtyTabForm: boolean;
  isLoading: boolean;
  loginActivities: any[];
  setToggleMenu: (isShow: boolean) => void;
  toggleMenu: boolean;
}

const MenuAndInfo = (props: Props) => {
  const {
    children,
    membershipDetails,
    isLoading,
    isEditPayment,
    isMakePayment,
    setIsMakePayment,
    dirtyTabForm,
    loginActivities,
    setToggleMenu,
    toggleMenu,
  } = props;

  const history = useHistory();
  const [, , isMobile] = useDeviceCheck();
  const [avatar, setAvatar] = useState('');
  const [openLoginLog, setOpenLoginLog] = useState(false);

  const { t } = useTranslation();
  const location = useLocation();
  const currentPage = location.pathname;

  const isShowMenu = useMemo(() => {
    if (isMobile) return true;
    return ![CHANGE_PASSWORD_URL, CONTACT_US_URL].includes(currentPage);
  }, [currentPage, isMobile]);

  const {
    clearPaymentState,
    setOpenModal,
    getArrearInformation,
    getPaymentMethodFail,
    getPaymentMethod,
  } = paymentActions;
  const { resetPersonalState } = personalActions;

  const dispatch = useDispatch();
  const clearPaymentAction = (clearResponse?: boolean) => dispatch(clearPaymentState(clearResponse));
  const resetPersonalStateAction = () => dispatch(resetPersonalState());
  const setOpenModalAction = (isOpen: boolean, callback?: Function) => dispatch(setOpenModal(isOpen, callback));
  const getArrearInformationAction = (memberId: string) => dispatch(getArrearInformation(memberId));
  const getPaymentMethodErrorAction = (error: any) => dispatch(getPaymentMethodFail({ error: error }));
  const getPaymentMethodAction = (memberId: number) => dispatch(getPaymentMethod(memberId));

  const membershipError = useTypedSelector((state) => state.membership.error);
  const avatarURL = useTypedSelector((state) => state.membership.membershipDetails.avatar);
  const { paymentMethodsResponse } = useTypedSelector((state) => state.payment);

  const isMember = !!membershipDetails.memberId && membershipDetails.memberId > 0;

  const regexs = useTypedSelector((state) => state.personal.regexs);
  const dateFormat = regexs.find((re) => re.key === PERSONAL_CONSTANTS.DATE_REGEX) || {
    key: '',
    value: _env.DATE_FORMAT,
  };
  const currencyCode = regexs.find((re) => re.key === PERSONAL_CONSTANTS.CURRENCY_REGEX) || { key: '', value: '' };
  const currencyCodeFormat = regexs.find((re) => re.key === PERSONAL_CONSTANTS.CURRENCY_FORMAT_REGEX) || {
    key: '',
    value: '',
  };
  const showRecentActivityConfig = regexs.find((re) => re.key === PERSONAL_CONSTANTS.SHOW_RECENT_ACTIVITY) || {
    key: '',
    value: '',
  };

  const [callbackChangeTab, setCallbackChangeTab] = useState('');
  const [openUnsaveModal, setOpenUnsaveModal] = useState(false);

  useEffect(() => {
    if (avatarURL) {
      (async () => {
        const ok = await checkAvatarUrl(avatarURL);
        if (ok) {
          setAvatar(avatarURL);
        }
      })();
    } else {
      setAvatar('');
    }
  }, [avatarURL]);

  const handleOpenUnsavePayment = (newValue: string) => {
    setOpenModalAction(true, () => {
      sessionStorage.removeItem(SESSION_STORAGE_NAMES.PROCESS_PURPOSE);
      history.push(newValue);
    });
  };

  const handleToggleMenu = () => {
    setToggleMenu(!toggleMenu);
  };

  const handleOpenUnsavePersonal = (newValue: string) => {
    setOpenUnsaveModal(true);
    setCallbackChangeTab(newValue);
  };

  const addGAActions = (page: string) => {
    switch (page) {
      case MEMBERSHIP_URL:
        gtag('event', 'Tab', {
          event_action: 'Membership',
          event_label: 'MembershipClicked',
        });
        break;
      case PERSONAL_URL:
        gtag('event', 'Tab', {
          event_action: 'PersonalDetail',
          event_label: 'PersonalDetailClicked',
        });
        break;
      case PAYMENT_URL:
        gtag('event', 'Tab', {
          event_action: 'PaymentsCredits',
          event_label: 'PaymentsCreditsClicked',
        });
        break;
      case INBOX_URL:
        gtag('event', 'Tab', {
          event_action: 'Inbox',
          event_label: 'InboxClicked',
        });
        break;
      default:
        break;
    }
  };

  const handleChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    addGAActions(newValue);
    if (currentPage !== newValue) {
      resetPersonalStateAction();
      switch (true) {
        case currentPage === PAYMENT_URL && (isEditPayment || isMakePayment):
        case currentPage === ADDON_PAYMENT_URL:
        case sessionStorage.getItem(SESSION_STORAGE_NAMES.PROCESS_PURPOSE) === PAYMENT_PURPOSES.EDIT_PAYMENT:
          handleOpenUnsavePayment(newValue);
          break;
        case currentPage === PERSONAL_URL && dirtyTabForm:
          handleOpenUnsavePersonal(newValue);
          break;
        default:
          history.push(newValue);
      }
    }
  };

  useEffect(() => {
    if (membershipDetails.memberId) {
      getPaymentMethodAction(membershipDetails.memberId);
    }
  }, [membershipDetails.memberId]);

  useEffect(() => {
    if (membershipDetails.memberId) {
      getArrearInformationAction(membershipDetails.memberId);
    }
  }, [membershipDetails.memberId]);

  const renderBalance = (balance: Number) => {
    return (
      <>
        <div className="member-info__membership-status__balance">
          <b className={`member-info__membership-status__balance${balance && balance >= 0 ? '__green' : '__red'}`}>
            {`${currencyCode.value} ${format(currencyCodeFormat.value, Math.abs(Number(balance)))}`}
          </b>
        </div>
      </>
    );
  };

  const membershipStatus = (status: string) => {
    return t(`${CONSTANTS.MEMBER_STATUS_CODE}${status.toUpperCase()}`);
  };

  const paymentStatus = (status: string) => {
    return t(`${CONSTANTS.PAYMENT_STATUS_CODE}${status.toUpperCase()}`);
  };

  const renderPaymentIconStatus = (status: string) => {
    const paymentStatusText = paymentStatus(status);
    switch (status) {
      case CONSTANTS.PC_OK:
        return (
          <span className="ok">
            <img src={getIconUrl('OK')} alt="ok" />
            {paymentStatusText}
          </span>
        );
      case CONSTANTS.PC_ND:
        return (
          <span className="neutral">
            <img src={getIconUrl('Neutral')} alt="neutral" />
            {paymentStatusText}
          </span>
        );
      default:
        return (
          <span className="notok">
            <img src={getIconUrl('Not-OK')} alt="not-ok" />
            {paymentStatusText}
          </span>
        );
    }
  };

  const renderMembershipIconStatus = (status: string) => {
    const memberStatusText = membershipStatus(status);
    switch (status) {
      case CONSTANTS.MC_OK:
        return (
          <span className="ok">
            <img src={getIconUrl('OK')} alt="ok" />
            {memberStatusText}
          </span>
        );
      case CONSTANTS.MC_SS:
      case CONSTANTS.MC_BN:
        return (
          <span className="notok">
            <img src={getIconUrl('Not-OK')} alt="not-ok" />
            {memberStatusText}
          </span>
        );
      default:
        return (
          <span className="neutral">
            <img src={getIconUrl('Neutral')} alt="neutral" />
            {memberStatusText}
          </span>
        );
    }
  };

  const handleOpenMakePayment = () => {
    switch (true) {
      case currentPage === PAYMENT_URL && isEditPayment:
        setOpenModalAction(true);
        break;
      case currentPage === PERSONAL_URL && dirtyTabForm:
        setOpenUnsaveModal(true);
        break;
      case currentPage === ADDON_PAYMENT_URL:
        setOpenModalAction(true, () => {
          history.push(PAYMENT_URL);
          setIsMakePayment(true);
        });
        break;
      case paymentMethodsResponse && Object.keys(paymentMethodsResponse.paymentMethods).length > 0:
        history.push(PAYMENT_URL);
        setIsMakePayment(true);
        clearPaymentAction();
        break;
      default:
        getPaymentMethodErrorAction(t('SS_PAYMENT_NO_PAYMENT_METHODS'));
    }
  };

  const handleCloseUnsave = () => {
    setOpenUnsaveModal(false);
    history.push(callbackChangeTab);
  };

  if (isLoading || membershipError) return <CircularProgress className="loading" />;

  return (
    <div className="wrapper">
      {isMember ? (
        <div className="content-container">
          {((toggleMenu && isMobile) || !isMobile) && (
            <Card className="member-info">
              <div className="member-info__group">
                <img src={avatar || getIconUrl('Avatar')} alt="avatar"></img>
                <div className="member-info__details">
                  <p>{membershipDetails && `${membershipDetails.firstName} ${membershipDetails.lastName}`}</p>
                  <div className="member-info__id">
                    <strong>
                      {t('SS_ID')}: {membershipDetails.memberFirstId}
                    </strong>
                  </div>
                  <div className="member-info__member-since">
                    <strong>{t('SS_MEMBER_SINCE')}:</strong>
                    <strong>{moment(membershipDetails.joinDate).format(dateFormat.value.toUpperCase())}</strong>
                  </div>

                  <div className="member-info__info">
                    <button onClick={handleOpenMakePayment}>{t('SS_MAKE_A_PAYMENT')}</button>
                  </div>
                </div>
              </div>
              <div className="member-info__status">
                <div className="member-info__payment-status">
                  <strong>{t('SS_MEMBERSHIP_STATUS')}</strong>
                  {membershipDetails.memberStatusCode && renderMembershipIconStatus(membershipDetails.memberStatusCode)}
                </div>
                <div className="member-info__membership-status ">
                  <strong>{t('SS_ACCOUNT_BALANCE')}</strong>
                  {(membershipDetails.currentBalance !== 0 && renderBalance(membershipDetails.currentBalance)) ||
                    (membershipDetails.paymentStatusCode &&
                      renderPaymentIconStatus(membershipDetails.paymentStatusCode))}
                </div>
              </div>
            </Card>
          )}
          <button className="member-info__toggle" onClick={handleToggleMenu}>
            <img
              className={classNames({
                'member-info__toggle--avatar': !toggleMenu,
              })}
              src={toggleMenu ? getIconUrl('Close') : avatar ? avatar : getIconUrl('Avatar')}
              alt="avatar-close"
            />
          </button>

          <div className="content-container__menu-children">
            {isShowMenu && (
              <BottomNavigation value={currentPage} onChange={handleChange} classes={{ root: 'footer-menu' }}>
                <BottomNavigationAction
                  disableRipple
                  value={MEMBERSHIP_URL}
                  classes={{ root: 'footer-menu__root', selected: 'footer-menu__selected' }}
                  showLabel
                  label={<strong>{t('SS_MEMBERSHIP')}</strong>}
                  icon={<MembershipIcon />}
                />
                <BottomNavigationAction
                  disableRipple
                  value={PERSONAL_URL}
                  classes={{ root: 'footer-menu__root', selected: 'footer-menu__selected' }}
                  showLabel
                  label={<strong>{t('SS_PERSONAL')}</strong>}
                  icon={<PersonalIcon />}
                />
                <BottomNavigationAction
                  disableRipple
                  value={PAYMENT_URL}
                  classes={{ root: 'footer-menu__root-last', selected: 'footer-menu__selected' }}
                  showLabel
                  label={<strong>{t('SS_PAYMENT_AND_CREDIT')}</strong>}
                  icon={<PaymentMethodIcon />}
                />
                <BottomNavigationAction
                  disableRipple
                  value={INBOX_URL}
                  classes={{ root: 'footer-menu__root-last', selected: 'footer-menu__selected' }}
                  showLabel
                  label={<strong>{t('SS_INBOX')}</strong>}
                  icon={<InboxIcon />}
                />
              </BottomNavigation>
            )}
            {children}
          </div>
          {showRecentActivityConfig.value && (
            <ViewLoginLog
              openLoginLog={openLoginLog}
              setOpenLoginLog={setOpenLoginLog}
              latestActivity={loginActivities[0]}
            />
          )}
        </div>
      ) : (
        <div className="content-container">
          {children}
          {showRecentActivityConfig.value && (
            <ViewLoginLog
              openLoginLog={openLoginLog}
              setOpenLoginLog={setOpenLoginLog}
              latestActivity={loginActivities[0]}
            />
          )}
        </div>
      )}
      <ConfirmModal
        confirmLabel={t('SS_LEAVE')}
        confirmAction={handleCloseUnsave}
        displayMessage={t('SS_CONFIRM_MESS')}
        openModal={openUnsaveModal}
        handleOpenModal={setOpenUnsaveModal}
      />
      <LoginLog openLoginLog={openLoginLog} setOpenLoginLog={setOpenLoginLog}></LoginLog>
    </div>
  );
};

export default MenuAndInfo;
