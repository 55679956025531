import { LOGIN_LOG_DATE_FORMAT, SYSTEM_DATE_FORMAT } from 'constants/personalConstant';
import moment, { Moment } from 'moment';

export const updateLocaleMoment = (t: Function) => {
  moment.updateLocale('en', {
    months: t('SS_DATE_PICKER_MONTHS').split('_'),
    monthsShort: t('SS_DATE_PICKER_MONTHS_SHORT').split('_'),
    weekdaysShort: t('SS_DATE_PICKER_WEEKDAYS_SHORT').split('_'),
    relativeTime: {
      past: t('SS_MOMENT_RELATIVE_TIME_PAST'),
      s: t('SS_MOMENT_RELATIVE_TIME_S'),
      ss: t('SS_MOMENT_RELATIVE_TIME_SS'),
      m: t('SS_MOMENT_RELATIVE_TIME_M'),
      mm: t('SS_MOMENT_RELATIVE_TIME_MM'),
      h: t('SS_MOMENT_RELATIVE_TIME_H'),
      hh: t('SS_MOMENT_RELATIVE_TIME_HH'),
      d: t('SS_MOMENT_RELATIVE_TIME_D'),
    },
  });
};

export const custommMomentFrom = (date: Moment | string, t: Function, listMode = false) => {
  const diffHour = moment().diff(date, 'hour');
  const diffMinutes = moment().diff(date, 'minute');
  const formatTime = 'HH:mm';
  switch (true) {
    case diffHour > 24:
      return moment(date).format(LOGIN_LOG_DATE_FORMAT);
    case diffHour === 1:
      return listMode
        ? `${moment(date).format(formatTime)} (${t('SS_MOMENT_RELATIVE_TIME_H_ONE_PAST_CUSTOM')})`
        : t('SS_MOMENT_RELATIVE_TIME_H_ONE_PAST_CUSTOM');
    case diffHour < 24 && diffHour > 0:
      return listMode
        ? `${moment(date).format(formatTime)} (${t('SS_MOMENT_RELATIVE_TIME_H_PAST_CUSTOM', {
            hours: diffHour,
          })})`
        : t('SS_MOMENT_RELATIVE_TIME_H_PAST_CUSTOM', {
            hours: diffHour,
          });
    case diffMinutes === 1:
      return listMode
        ? `${moment(date).format(formatTime)} (${t('SS_MOMENT_RELATIVE_TIME_M_ONE_PAST_CUSTOM')})`
        : t('SS_MOMENT_RELATIVE_TIME_M_ONE_PAST_CUSTOM');
    case diffMinutes < 60 && diffMinutes > 0:
      return listMode
        ? `${moment(date).format(formatTime)} (${t('SS_MOMENT_RELATIVE_TIME_M_PAST_CUSTOM', {
            minutes: diffMinutes,
          })})`
        : t('SS_MOMENT_RELATIVE_TIME_M_PAST_CUSTOM', {
            minutes: diffMinutes,
          });
    default:
      return listMode ? `${moment(date).format(formatTime)} (${moment(date).fromNow()})` : moment(date).fromNow();
  }
};

export const monthRangeDates = (startDate: string, endDate: string) => {
  const goLiveDateMoment = moment(startDate, SYSTEM_DATE_FORMAT);
  const startDateMoment = moment(startDate, 'YYYY-MM-01').add(1, 'month');
  const endDateMoment = moment(endDate, SYSTEM_DATE_FORMAT);

  let result = [];

  if (goLiveDateMoment.isBefore(endDateMoment)) {
    result.push(goLiveDateMoment.format(SYSTEM_DATE_FORMAT));
  }

  while (startDateMoment.isSameOrBefore(endDateMoment)) {
    result.push(startDateMoment.format('YYYY-MM-01'));
    startDateMoment.add(1, 'month');
  }

  return result.slice(-12).reverse();
};

export const convertMonthToText = (month: string) => {
  switch (month) {
    case '01':
      return 'SS_MONTHS_JANUARY';
    case '02':
      return 'SS_MONTHS_FEBRUARY';
    case '03':
      return 'SS_MONTHS_MARCH';
    case '04':
      return 'SS_MONTHS_APRIL';
    case '05':
      return 'SS_MONTHS_MAY';
    case '06':
      return 'SS_MONTHS_JUNE';
    case '07':
      return 'SS_MONTHS_JULY';
    case '08':
      return 'SS_MONTHS_AUGUST';
    case '09':
      return 'SS_MONTHS_SEPTEMBER';
    case '10':
      return 'SS_MONTHS_OCTOBER';
    case '11':
      return 'SS_MONTHS_NOVEMBER';
    default:
      return 'SS_MONTHS_DECEMBER';
  }
};
