import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import React, { useState } from 'react';

import CONSTANTS from 'constants/commonContants';
import ConfirmModal from 'components/ModalConfirm';
import { PreviousIcon } from 'components/Icons';
import { cancelAddon } from 'services';
import format from 'number-format.js';
import { getIconUrl } from 'utility/helpers';
import moment from 'moment';
import PERSONAL_CONSTANTS from 'constants/personalConstant';
import { useDeviceCheck } from 'utility/screenDetect';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useTypedSelector } from 'redux/configureStore';
import emitNotify from 'utility/emitNotify';

interface AddonDetailsProps {
  addon: {
    addonId: number;
    addonName: string;
    startDate: string;
    endDate: string;
    mapCode: any;
    recurringFee: number;
    status: string;
    isCorporate: boolean;
    isMonthly: boolean;
    noAllowUnsubcribedOnSS: boolean;
  };
  dateFormat: { value: string; key: string };
  currencyCode: { value: string; key: string };
  currencyFormat: { value: string; key: string };
  handleClickCancel: (addonId: string) => void;
}

const AddonDetails = (props: AddonDetailsProps) => {
  const { addon, handleClickCancel, dateFormat, currencyCode, currencyFormat } = props;
  const [openAddon, setOpenAddon] = useState(false);
  const { t } = useTranslation();

  const convertDate = (date: string) =>
    new Date(date.substring(0, 4) + '/' + date.substring(4, 6) + '/' + date.substring(6, 8)).toLocaleDateString(
      CONSTANTS.EN_GB
    );
  const effectDate = moment(convertDate(addon.startDate), 'DD/MM/YYYY').format(dateFormat.value.toUpperCase());
  const endDate = moment(convertDate(addon.endDate), 'DD/MM/YYYY').format(dateFormat.value.toUpperCase());
  const handleClick = () => {
    gtag('event', 'Button', {
      event_action: 'MCAUnsubscribe',
      event_label: 'MCAUnsubscribeClicked',
    });
    handleClickCancel(addon.addonId.toString());
  };
  return (
    <Accordion
      classes={{ root: 'addon-details', expanded: 'addon-details__expanded' }}
      expanded={openAddon}
      onChange={(e, expanded) => {
        gtag('event', 'Button', {
          event_action: 'MCAProduct',
          event_label: 'MCAProductClicked',
        });
        setOpenAddon(expanded);
      }}
    >
      <AccordionSummary
        expandIcon={<img src={getIconUrl('ArrowDown')} alt="arrow-up" />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        classes={{ root: 'addons-root', content: 'addons-summary' }}
      >
        <img src={getIconUrl('AddonsPart')} alt="part" />
        {addon.addonName}
      </AccordionSummary>
      <AccordionDetails>
        <div className="addon-details__detail-group">
          <div className="addon-details__detail-field">
            <strong>{t('SS_ADDONS_EFFECTIVE_DATE')}</strong>
            <b>{effectDate}</b>
          </div>
          {addon.isMonthly && addon.status !== 'Active' && (
            <div className="addon-details__detail-field">
              <strong>{t('SS_ADDONS_END_DATE')}</strong>
              <b>{endDate}</b>
            </div>
          )}
          {addon.isMonthly && (
            <div className="addon-details__detail-field">
              <strong>{t('SS_ADDONS_RECURRING_AMOUNT')}</strong>
              <b>{`${currencyCode.value} ${format(currencyFormat.value, addon.recurringFee)}`}</b>
            </div>
          )}
          <div className="addon-details__action">
            {!addon.noAllowUnsubcribedOnSS && addon.isMonthly && addon.status === 'Active' && (
              <button className="addon-details__action--cancel" onClick={handleClick}>
                {t('SS_UNSUBSCRIBE')}
              </button>
            )}
          </div>
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

interface AddonsMobileProps {
  closeAddons: Function;
  addons: Array<{
    addonId: number;
    addonName: string;
    startDate: string;
    endDate: string;
    recurringFee: number;
    mapCode: any;
    status: string;
    isCorporate: boolean;
    isMonthly: boolean;
    noAllowUnsubcribedOnSS: boolean;
  }>;
}

const AddonsMobile = (props: AddonsMobileProps) => {
  const { closeAddons, addons } = props;
  const { t } = useTranslation();
  const [, , isMobile, isTablet] = useDeviceCheck();
  const { enqueueSnackbar } = useSnackbar();
  const regexs = useTypedSelector((state) => state.personal.regexs);
  const dateFormat = regexs.find((ph) => ph.key === PERSONAL_CONSTANTS.DATE_REGEX) || {
    key: '',
    value: _env.DATE_FORMAT,
  };
  const currencyCode = regexs.find((ph) => ph.key === PERSONAL_CONSTANTS.CURRENCY_REGEX) || {
    key: '',
    value: '',
  };
  const currencyFormat = regexs.find((ph) => ph.key === PERSONAL_CONSTANTS.CURRENCY_FORMAT_REGEX) || {
    key: '',
    value: '',
  };
  const { membershipDetails } = useTypedSelector((state) => state.membership);
  const [openConfirmCancel, setOpenConfirmCancel] = useState(false);
  const [cancelAddonId, setCancelAddonId] = useState('');
  const handleClickCancel = (addonId: string) => {
    setOpenConfirmCancel(true);
    setCancelAddonId(addonId);
  };

  const handleCancelAddon = async () => {
    if (cancelAddonId) {
      const { isSuccess, message } = await cancelAddon(membershipDetails.memberId, cancelAddonId);
      if (isSuccess) {
        emitNotify(
          enqueueSnackbar,
          t('SS_NOTIFICATION_MEMBERSHIP'),
          t('SS_NOTIFICATION_MEMBERSHIP_INFORMATION_SAVING'),
          'success'
        );
        closeAddons();
      } else {
        if (message === 'Member_Addon_Not_Found') {
          emitNotify(
            enqueueSnackbar,
            t('SS_NOTIFICATION_MEMBERSHIP'),
            t('SS_NOTIFICATION_MEMBERSHIP_CANCEL_ADDON_FAILED'),
            'error'
          );
        }
      }
    }
  };
  return (
    <div className="addons-container">
      <button className="addons-previous transparent-button" onClick={() => closeAddons()}>
        <PreviousIcon />
      </button>
      <div className="addons-content">
        <p>{t('SS_ADDONS')}</p>
        <div className="addons-content__accordion-group">
          {((isMobile || isTablet) && (
            <div className="addons-content__accordion-column">
              {addons.map((dt) => (
                <div className="addons-content__accordion-field" key={dt.addonId}>
                  <AddonDetails
                    addon={dt}
                    handleClickCancel={handleClickCancel}
                    dateFormat={dateFormat}
                    currencyCode={currencyCode}
                    currencyFormat={currencyFormat}
                  />
                </div>
              ))}
            </div>
          )) || (
              <>
                <div className="addons-content__accordion-column addons-content__accordion-column--odd">
                  {addons.map(
                    (dt, index) =>
                      index % 2 === 0 && (
                        <div className="addons-content__accordion-field" key={dt.addonId}>
                          <AddonDetails
                            addon={dt}
                            handleClickCancel={handleClickCancel}
                            dateFormat={dateFormat}
                            currencyCode={currencyCode}
                            currencyFormat={currencyFormat}
                          />
                        </div>
                      )
                  )}
                </div>
                <div className="addons-content__accordion-column">
                  {addons.map(
                    (dt, index) =>
                      index % 2 !== 0 && (
                        <div className="addons-content__accordion-field" key={dt.addonId}>
                          <AddonDetails
                            addon={dt}
                            handleClickCancel={handleClickCancel}
                            dateFormat={dateFormat}
                            currencyCode={currencyCode}
                            currencyFormat={currencyFormat}
                          />
                        </div>
                      )
                  )}
                </div>
              </>
            )}
        </div>
      </div>
      <ConfirmModal
        openModal={openConfirmCancel}
        handleOpenModal={setOpenConfirmCancel}
        displayMessage={
          <>
            {t('SS_MEMBERSHIP_ADDONS_CANCEL_CONFIRM_MESSAGE')}
            <br />
            {t('SS_MEMBERSHIP_ADDONS_END_DATE_MESSAGE', {
              endDate: moment(membershipDetails.billingDateAddons)
                .subtract(1, 'days')
                .format(dateFormat.value.toUpperCase()),
            })}
          </>
        }
        confirmLabel={t('SS_CONFIRM')}
        cancelLabel={t('SS_CANCEL')}
        confirmAction={handleCancelAddon}
      />
    </div>
  );
};

export default AddonsMobile;
