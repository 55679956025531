import { Accordion, AccordionDetails, AccordionSummary, AppBar, CircularProgress, Tab } from '@material-ui/core';
import { CheckIcon, ClockIcon, CrownIcon, HighlightIcon, PreviousIcon, SessionIcon } from 'components/Icons';
import React, { useEffect, useMemo, useState } from 'react';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import PERSONAL_CONSTANTS, {
  SESSION_STORAGE_NAMES,
  SYSTEM_DATE_FORMAT,
} from 'constants/personalConstant';
import { replaceToHTMLElement, resetHeight, updateElementHeight } from 'utility/helpers';

import { ADDON_PAYMENT_URL } from 'utility/routesURL';
import BusinessRuleModal from 'components/BusinessRuleModal';
import classNames from 'classnames';
import format from 'number-format.js';
import { getPreviewData } from 'services/upgradeMembership';
import moment from 'moment';
import { useDeviceCheck } from 'utility/screenDetect';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useTypedSelector } from 'redux/configureStore';

interface AddonProps {
  addons: any[];
  isAllowAddAddon: boolean;
  loadingAddons: boolean;
  clubId: number;
  planId: number;
  setToggleMenu: (isShow: boolean) => void;
}

const Addon: React.FC<AddonProps> = ({ addons, isAllowAddAddon, loadingAddons, clubId, planId, setToggleMenu }) => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const tJOL = i18n.getFixedT(`${i18n.language}-adyen`, 'translations');
  const [isOpenAddon, setOpenAddon] = useState(false);
  const [tabValue, setTabValue] = useState('');
  const [activeViewmoreItem, setActiveViewmoreItem] = useState<number>(-1);
  const [selectedItems, setSelectedItems] = useState<any[]>([]);
  const [selectedClassGroupItems, setSelectedClassGroupItems] = useState<any[]>([]);
  const [businessRuleMessage, setBusinessRuleMessage] = useState<string>('');

  const regexs = useTypedSelector((state) => state.personal.regexs);
  const { membershipDetails } = useTypedSelector((state) => state.membership);

  const [, , isMobile] = useDeviceCheck();

  const virtualAddonCodes = ['VX', 'VW'];
  const total = useMemo(
    () =>
      selectedItems.reduce(
        (count, item) => (item.amount.payNow ? count + item.amount.payNow : count + item.amount.originalAmount),
        0
      ) +
      selectedClassGroupItems.reduce(
        (count, item) => (item.amount.payNow ? count + item.amount.payNow : count + item.amount.originalAmount),
        0
      ),
    [selectedItems.length, selectedClassGroupItems.length]
  );

  const tabSummaries = useMemo(
    () => [
      {
        name: 'addons',
        hasNoAddon: addons.filter((item) => !item.isAddonClass && !virtualAddonCodes.includes(item.code)).length === 0,
      },
      { name: 'classes', hasNoAddon: addons.filter((item) => item.isAddonClass).length === 0 },
      {
        name: 'vs',
        hasNoAddon: addons.filter((item) => !item.isAddonClass && virtualAddonCodes.includes(item.code)).length === 0,
      },
    ],
    [addons.length]
  );
  const addGAActions = (tabName: string) => {
    switch (tabName) {
      case 'addons':
        gtag('event', 'Tab', {
          event_action: 'MBAAddonsTab',
          event_label: 'MBAAddonsTabClicked',
        });
        break;
      case 'classes':
        gtag('event', 'Tab', {
          event_action: 'MBAClassesTab',
          event_label: 'MBAClassesTabClicked',
        });
        break;
      case 'vs':
        gtag('event', 'Tab', {
          event_action: 'MBAVirtualStudioTab',
          event_label: 'MBAVirtualStudioTabClicked',
        });
        break;
    }
  };

  useEffect(() => {
    if (tabSummaries) {
      setTabValue(tabSummaries.find((item) => !item.hasNoAddon)?.name || 'addons');
    }
  }, [tabSummaries]);

  useEffect(() => {
    if (isOpenAddon && tabValue) {
      addGAActions(tabValue);
    }
  }, [isOpenAddon]);

  const currencyCode = regexs.find((regex) => regex.key === PERSONAL_CONSTANTS.CURRENCY_REGEX) || {
    key: '',
    value: '',
  };
  const currencyFormat = regexs.find((regex) => regex.key === PERSONAL_CONSTANTS.CURRENCY_FORMAT_REGEX) || {
    key: '',
    value: '',
  };

  useEffect(() => {
    if (addons.length && isOpenAddon) {
      resetHeight('addon__title');
      resetHeight('addon__description');
      resetHeight('addon__row');
      updateElementHeight('addon__title');
      updateElementHeight('addon__description');
      updateElementHeight('addon__row');
    }
  }, [addons, isOpenAddon, tabValue]);

  useEffect(() => {
    const body = document.getElementsByTagName('body')[0];

    body.onresize = () => {
      if (addons.length && isOpenAddon) {
        resetHeight('addon__title');
        resetHeight('addon__description');
        resetHeight('addon__row');
        updateElementHeight('addon__title');
        updateElementHeight('addon__description');
        updateElementHeight('addon__row');
      }
    };
  }, [addons, isOpenAddon]);

  const addItem = (item: any) => {
    gtag('event', 'Option', {
      event_action: 'MBAAddon',
      event_label: 'MBAAddonClicked',
    });
    const isExisted = selectedItems.some((existedItem: any) => existedItem.id === item.id);
    const existTypeCodeIndex = selectedItems.findIndex(
      (existedItem: any) => existedItem.addonTypeCode === item.addonTypeCode
    );
    if (!isExisted) {
      const cloneSelectedItems = selectedItems;
      if (existTypeCodeIndex !== -1) {
        cloneSelectedItems[existTypeCodeIndex] = item;
        setSelectedItems([...cloneSelectedItems]);
      } else {
        setSelectedItems([...selectedItems, item]);
      }
    } else {
      setSelectedItems(selectedItems.filter((existedItem: any) => existedItem.id !== item.id));
    }
  };

  const handleBusinessRule = async (newItem: any) => {
    const isExisted = selectedClassGroupItems.some((existedItem: any) => existedItem.id === newItem.id);
    let newSelectedItems = [...selectedClassGroupItems];

    if (!isExisted) {
      newSelectedItems = [...selectedClassGroupItems.filter((item: any) => !item.isBundle), newItem];
      const addonClassIds = newSelectedItems.map((item) => item.id);
      const boughtAddonClassIds = membershipDetails.addons
        .filter((item: any) => item.isAddonClass)
        .map((item: any) => item.addonId);
      if (addonClassIds.length) {
        const { addonClasses, messages } = await getPreviewData({
          clubId,
          planId,
          membershipLength: 0,
          startDate: moment(new Date()).format(SYSTEM_DATE_FORMAT),
          addonClassIds: [...boughtAddonClassIds, ...addonClassIds],
          addonIds: [],
        });
        setBusinessRuleMessage(messages[0] || '');
        const responseAddonClasses = addonClasses
          .filter((existedItem: any) => !boughtAddonClassIds.includes(existedItem.id))
          .map((item: any) => item.id);
        if (addonClassIds.sort().join('') !== responseAddonClasses.sort().join('')) {
          newSelectedItems = addons.filter((item: any) => responseAddonClasses.includes(item.id));
        }
      }
    } else {
      newSelectedItems = newSelectedItems.filter((existedItem: any) => existedItem.id !== newItem.id);
    }
    setSelectedClassGroupItems(newSelectedItems);
  };

  const handleCancel = () => {
    setOpenAddon(false);
    setActiveViewmoreItem(-1);
    setSelectedItems([]);
    setSelectedClassGroupItems([]);
  };

  const handlePayAddon = () => {
    gtag('event', 'Button', {
      event_action: 'MBAContinue',
      event_label: 'MBAContinueClicked',
    });
    const addonIds = [...selectedItems.map((item) => item.id), ...selectedClassGroupItems.map((item) => item.id)];
    sessionStorage.setItem(SESSION_STORAGE_NAMES.ADDON_IDS, JSON.stringify(addonIds));
    history.push(ADDON_PAYMENT_URL);
  };

  const handleChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    setTabValue(newValue);
    addGAActions(newValue);
  };

  const handleOnclickBuyAddon = () => {
    if (isAllowAddAddon) {
      gtag('event', 'Button', {
        event_action: 'MBuyAddon',
        event_label: 'MBuyAddonClicked',
      });
      setOpenAddon(true);
      if (isMobile) {
        setToggleMenu(false);
      }
    } else {
      setOpenAddon(false);
    }
  };

  return (
    <>
      <div
        className={classNames({
          addons: true,
        })}
      >
        <button className="addons__heading" disabled={!isAllowAddAddon} onClick={handleOnclickBuyAddon}>
          <span className="addons__crown-icon">
            <CrownIcon />
          </span>
          <span
            className={classNames({
              addons__title: true,
              'addons__title--allow-click': isAllowAddAddon,
            })}
          >
            {t('SS_MEMBERSHIP_BY_ADDON')}
          </span>
        </button>
        {!isAllowAddAddon && <p className="addons__description">{t('SS_MEMBERSHIP_BY_ADDON_NOT_AVAILABLE')}</p>}

        {isOpenAddon ? (
          addons.length > 0 ? (
            <>
              <TabContext value={tabValue || 'addons'}>
                <AppBar position="static" color="default" className="seperate-addons-tab__header">
                  <TabList
                    onChange={handleChange}
                    aria-label="simple tabs example"
                    variant="fullWidth"
                    className="seperate-addons-tab__tablist"
                  >
                    <Tab
                      label="Add-ons"
                      value="addons"
                      className={classNames({
                        'seperate-addons-tab__tab--hide': tabSummaries.find((item) => item.name === 'addons')
                          ?.hasNoAddon,
                      })}
                    />
                    <Tab
                      label="Classes"
                      value="classes"
                      className={classNames({
                        'seperate-addons-tab__tab--hide': tabSummaries.find((item) => item.name === 'classes')
                          ?.hasNoAddon,
                      })}
                    />
                    <Tab
                      label="Virtual studio"
                      value="vs"
                      className={classNames({
                        'seperate-addons-tab__tab--hide': tabSummaries.find((item) => item.name === 'vs')?.hasNoAddon,
                      })}
                    />
                  </TabList>
                </AppBar>

                <TabPanel value="addons" className="seperate-addons-tab__panel">
                  <div className="addons__container">
                    <div className="addons__container-heading">
                      <h3>{t('SS_MEMBERSHIP_SELECT_ADDON')}</h3>
                      <p>{t('SS_MEMBERSHIP_SELECT_ADDON_DESCRIPTION')}</p>
                    </div>
                    <div className="addons__items">
                      {addons.map(
                        (item) =>
                          !item.isAddonClass &&
                          !virtualAddonCodes.includes(item.code) && (
                            <div
                              key={item.id}
                              className={`addon__wrapper ${selectedItems.some((existedItem: any) => existedItem.id === item.id) ? 'active' : ''
                                }`}
                            >
                              <button className="addon" onClick={() => addItem(item)}>
                                <div className="addon__title">{item.name}</div>
                                <div
                                  className="addon__description"
                                  dangerouslySetInnerHTML={{
                                    __html: replaceToHTMLElement(tJOL(item.shortDescription.key)),
                                  }}
                                ></div>
                                <div className="addon__row">
                                  <div className="addon__col">
                                    {item.duration ? (
                                      <>
                                        <span className="addon__information-icon">
                                          <ClockIcon />
                                        </span>
                                        <span className="addon__information-title">{item.duration}</span>
                                        <span className="addon__information-description">
                                          {t('SS_MEMBERSHIP_BY_ADDON_MIN_SESSION')}
                                        </span>
                                      </>
                                    ) : null}
                                  </div>
                                  <div className="addon__col">
                                    {item.session ? (
                                      <>
                                        <span className="addon__information-icon">
                                          <SessionIcon />
                                        </span>
                                        <span className="addon__information-title">{item.session}</span>
                                        <span className="addon__information-description">
                                          {item.session > 1
                                            ? t('SS_MEMBERSHIP_BY_ADDON_SESSIONS')
                                            : t('SS_MEMBERSHIP_BY_ADDON_SESSION')}
                                        </span>
                                      </>
                                    ) : null}
                                  </div>
                                </div>

                                <div className="addon__price">
                                  <b>
                                    {`${currencyCode.value} ${format(
                                      currencyFormat.value,
                                      item.amount.recurring || item.amount.payNow
                                    )}${item.amount.recurring ? `/${t('SS_MEMBERSHIP_BY_ADDON_MONTH')}` : ''}`}
                                  </b>
                                </div>
                                {item.isFeature ? (
                                  <span className="addon__highlight">
                                    <HighlightIcon />
                                    <span>{t('SS_MEMBERSHIP_BY_ADDON_BEST_VALUE')}</span>
                                  </span>
                                ) : null}
                                <span className="addon__icon">
                                  <CheckIcon />
                                </span>
                              </button>
                              <Accordion
                                classes={{ root: 'addon-viewmore', expanded: 'addon-viewmore__expanded' }}
                                expanded={activeViewmoreItem === item.id}
                                onChange={() => {
                                  setActiveViewmoreItem(activeViewmoreItem === item.id ? -1 : item.id);
                                }}
                              >
                                <AccordionSummary
                                  aria-controls="addon-viewmore__content"
                                  id={`addon${item.id}__header`}
                                  classes={{ root: 'addon-viewmore__root', content: 'addon-viewmore__summary' }}
                                >
                                  <PreviousIcon />
                                </AccordionSummary>
                                <AccordionDetails className="addon-viewmore__content">
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: replaceToHTMLElement(tJOL(item.description.key)),
                                    }}
                                  ></div>
                                </AccordionDetails>
                              </Accordion>
                            </div>
                          )
                      )}
                    </div>
                  </div>
                </TabPanel>
                <TabPanel value="classes" className="seperate-addons-tab__panel">
                  <div className="addons__container">
                    <div className="addons__container-heading">
                      <h3>{t('SS_MEMBERSHIP_SELECT_CLASSES')}</h3>
                      <p>{t('SS_MEMBERSHIP_SELECT_CLASSES_DESCRIPTION')}</p>
                    </div>
                    <div className="addons__items">
                      {addons
                        .filter((item) => item.isAddonClass)
                        .sort((item) => (item.isBundle ? 1 : -1))
                        .map((item) => (
                          <div
                            key={item.id}
                            className={`addon__wrapper ${selectedClassGroupItems.some((existedItem: any) => existedItem.id === item.id)
                              ? 'active'
                              : ''
                              }`}
                          >
                            <button className="addon" onClick={() => handleBusinessRule(item)}>
                              <div className="addon__title">{item.name}</div>
                              <div
                                className="addon__description"
                                dangerouslySetInnerHTML={{
                                  __html: replaceToHTMLElement(tJOL(item.shortDescription.key)),
                                }}
                              ></div>
                              <div className="addon__row">
                                <div className="addon__col"></div>
                                <div className="addon__col"></div>
                              </div>

                              <div className="addon__price">
                                <b>
                                  {`${currencyCode.value} ${format(
                                    currencyFormat.value,
                                    item.amount.recurring || item.amount.payNow
                                  )}${item.amount.recurring ? `/${t('SS_MEMBERSHIP_BY_ADDON_MONTH')}` : ''}`}
                                </b>
                              </div>
                              {item.isFeature ? (
                                <span className="addon__highlight">
                                  <HighlightIcon />
                                  <span>{t('SS_MEMBERSHIP_BY_ADDON_BEST_VALUE')}</span>
                                </span>
                              ) : null}
                              <span className="addon__icon">
                                <CheckIcon />
                              </span>
                            </button>
                            <Accordion
                              classes={{ root: 'addon-viewmore', expanded: 'addon-viewmore__expanded' }}
                              expanded={activeViewmoreItem === item.id}
                              onChange={() => {
                                setActiveViewmoreItem(activeViewmoreItem === item.id ? -1 : item.id);
                              }}
                            >
                              <AccordionSummary
                                aria-controls="addon-viewmore__content"
                                id={`addon${item.id}__header`}
                                classes={{ root: 'addon-viewmore__root', content: 'addon-viewmore__summary' }}
                              >
                                <PreviousIcon />
                              </AccordionSummary>
                              <AccordionDetails className="addon-viewmore__content">
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: replaceToHTMLElement(tJOL(item.description.key)),
                                  }}
                                ></div>
                              </AccordionDetails>
                            </Accordion>
                          </div>
                        ))}
                    </div>
                  </div>
                </TabPanel>

                <TabPanel value="vs" className="seperate-addons-tab__panel">
                  <div className="addons__container">
                    <div className="addons__container-heading"></div>
                    <div className="addons__items">
                      {addons.map(
                        (item) =>
                          !item.isAddonClass &&
                          virtualAddonCodes.includes(item.code) && (
                            <div
                              key={item.id}
                              className={`addon__wrapper ${selectedItems.some((existedItem: any) => existedItem.id === item.id) ? 'active' : ''
                                }`}
                            >
                              <button className="addon" onClick={() => addItem(item)}>
                                <div className="addon__title">{item.name}</div>
                                <div
                                  className="addon__description"
                                  dangerouslySetInnerHTML={{
                                    __html: replaceToHTMLElement(tJOL(item.shortDescription.key)),
                                  }}
                                ></div>
                                <div className="addon__row">
                                  <div className="addon__col">
                                    {item.duration ? (
                                      <>
                                        <span className="addon__information-icon">
                                          <ClockIcon />
                                        </span>
                                        <span className="addon__information-title">{item.duration}</span>
                                        <span className="addon__information-description">
                                          {t('SS_MEMBERSHIP_BY_ADDON_MIN_SESSION')}
                                        </span>
                                      </>
                                    ) : null}
                                  </div>
                                  <div className="addon__col">
                                    {item.session ? (
                                      <>
                                        <span className="addon__information-icon">
                                          <SessionIcon />
                                        </span>
                                        <span className="addon__information-title">{item.session}</span>
                                        <span className="addon__information-description">
                                          {item.session > 1
                                            ? t('SS_MEMBERSHIP_BY_ADDON_SESSIONS')
                                            : t('SS_MEMBERSHIP_BY_ADDON_SESSION')}
                                        </span>
                                      </>
                                    ) : null}
                                  </div>
                                </div>

                                <div className="addon__price">
                                  <b>
                                    {`${currencyCode.value} ${format(
                                      currencyFormat.value,
                                      item.amount.recurring || item.amount.payNow
                                    )}${item.amount.recurring ? `/${t('SS_MEMBERSHIP_BY_ADDON_MONTH')}` : ''}`}
                                  </b>
                                </div>
                                {item.isFeature ? (
                                  <span className="addon__highlight">
                                    <HighlightIcon />
                                    <span>{t('SS_MEMBERSHIP_BY_ADDON_BEST_VALUE')}</span>
                                  </span>
                                ) : null}
                                <span className="addon__icon">
                                  <CheckIcon />
                                </span>
                              </button>
                              <Accordion
                                classes={{ root: 'addon-viewmore', expanded: 'addon-viewmore__expanded' }}
                                expanded={activeViewmoreItem === item.id}
                                onChange={() => {
                                  setActiveViewmoreItem(activeViewmoreItem === item.id ? -1 : item.id);
                                }}
                              >
                                <AccordionSummary
                                  aria-controls="addon-viewmore__content"
                                  id={`addon${item.id}__header`}
                                  classes={{ root: 'addon-viewmore__root', content: 'addon-viewmore__summary' }}
                                >
                                  <PreviousIcon />
                                </AccordionSummary>
                                <AccordionDetails className="addon-viewmore__content">
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: replaceToHTMLElement(tJOL(item.description.key)),
                                    }}
                                  ></div>
                                </AccordionDetails>
                              </Accordion>
                            </div>
                          )
                      )}
                    </div>
                  </div>
                </TabPanel>
              </TabContext>
              <div className="addons__actions text-right">
                <button className="btn btn-cancel btn-effect" onClick={handleCancel}>
                  {t('SS_CANCEL')}
                </button>
                <button
                  className="btn btn-primary btn-effect"
                  disabled={!addons.length || !total}
                  onClick={handlePayAddon}
                >
                  {t('SS_MEMBERSHIP_SELECT_ADDON_CONTINUE')}
                </button>
              </div>
            </>
          ) : loadingAddons ? (
            <div className="list-transactions__loading">
              <CircularProgress className="loading-table" />
            </div>
          ) : (
            <p className="addons__no-addons">{t('SS_MEMBERSHIP_NO_ADDON')}</p>
          )
        ) : null}
      </div>
      <BusinessRuleModal
        isOpen={!!tJOL(businessRuleMessage)}
        content={tJOL(businessRuleMessage)}
        isCF={false}
        setOpen={() => setBusinessRuleMessage('')}
      />
    </>
  );
};

export default Addon;
