import AdyenCheckout from '@adyen/adyen-web';
import '@adyen/adyen-web/dist/adyen.css';
import { Card, Checkbox, FormControlLabel } from '@material-ui/core';
import ListTransactions from 'components/ListTransactions';
import MakePayment from 'components/MakePayment';
import ConfirmModal from 'components/ModalConfirm';
import SelectField from 'components/SelectField';
import { ANSWER } from 'constants/commonContants';
import { DONE_MAKE_PAYMENT_TYPES, PAYMENT_ERRORS, PAYMENT_PURPOSES } from 'constants/paymentConstants';
import PERSONAL_CONSTANTS, { SESSION_STORAGE_NAMES, SYSTEM_DATE_FORMAT } from 'constants/personalConstant';
import moment from 'moment';
import format from 'number-format.js';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { paymentActions } from 'redux/actionCreators';
import { useTypedSelector } from 'redux/configureStore';
import { getTransactions } from 'services';
import { Transaction } from 'types/IPayment';
import { IUser } from 'types/IUser';
import { translations } from 'utility/adyenTranslations';
import { ConvertExpiryDate } from 'utility/checkExpiredCard';
import { debounce } from 'utility/debounce';
import { elementExistInDom, getIconUrl, isNumber } from 'utility/helpers';
import { MEMBERSHIP_URL } from 'utility/routesURL';
import { useDeviceCheck } from 'utility/screenDetect';
import { convertMonthToText, monthRangeDates } from 'utility/updateLocaleMoment';
import { 
  hasParamsThreeDs,
  hasPayload, 
  hasPaymentMethodType, 
  hasRedirectResult, 
  hasResultCode 
} from 'utility/adyen';

interface MembershipInfoProps {
  userInfo: IUser;
  setIsEditPayment: Function;
  isEditPayment: boolean;
  setIsMakePayment: Function;
  isMakePayment: boolean;
}

interface VerifyThreeDSBody {
  paymentData: string;
  verifyData: {
    md?: string;
    paRes?: string;
    details?: any;
  };
  isVerifyForPayment?: boolean;
}

interface SubmitUpdatePaymentBody {
  paymentData: string;
  verifyData: {
    md?: string;
    paRes?: string;
    details?: any;
  };
}

const UpdatePaymentPage = (props: any) => {
  const { error } = props;
  const { t, i18n } = useTranslation();
  const [, , isMobile] = useDeviceCheck();
  const tAdyen = i18n.getFixedT(`${i18n.language}-adyen`, 'translations');
  const [isCheckAffirmativeSubcriptionTerms, setIsCheckAffirmativeSubcriptionTerms] = useState(false);

  const handleCheckAffirmativeSubscriptionTerms = (e: any) => {
    setIsCheckAffirmativeSubcriptionTerms(e.target.checked);
  };
  const { regexs } = useTypedSelector((state) => state.personal);
  const { totalRecurringFee } = useTypedSelector((state) => state.membership);

  const currencyCode = regexs.find((regex) => regex.key === PERSONAL_CONSTANTS.CURRENCY_REGEX) || {
    key: '',
    value: '',
  };
  const currencyFormat = regexs.find((regex) => regex.key === PERSONAL_CONSTANTS.CURRENCY_FORMAT_REGEX) || {
    key: '',
    value: '',
  };

  useEffect(() => {
    const submitPaymentButton = document.getElementsByClassName('adyen-checkout__button--pay')[0] as HTMLInputElement;
    if (submitPaymentButton && totalRecurringFee > 0) {
      submitPaymentButton.disabled = !isCheckAffirmativeSubcriptionTerms;
    }
  }, [isCheckAffirmativeSubcriptionTerms, totalRecurringFee]);

  const renderAffirmativeSubcriptionTerms = () =>
    totalRecurringFee > 0 ? (
      <div className="edit-payment__affirmative-subscription-terms">
        <FormControlLabel
          control={
            <Checkbox
              name="checkAffirmativeSubcriptionTerms"
              onChange={handleCheckAffirmativeSubscriptionTerms}
              checked={isCheckAffirmativeSubcriptionTerms}
            />
          }
          label={t('SS_AFFIRMATIVE_SUBSCRIPTION_TERMS_MAKE_AND_EDIT_PAYMENT', {
            currency: currencyCode.value,
            recurringFeeAmount: format(currencyFormat.value, totalRecurringFee),
          })}
        />
        {!isCheckAffirmativeSubcriptionTerms && (
          <span className="reminder">{t('SS_AFFIRMATIVE_SUBSCRIPTION_TERMS_REMIND_EDIT')}</span>
        )}
      </div>
    ) : (
      <></>
    );

  const renderError = () => {
    switch (error) {
      case PAYMENT_ERRORS.NETWORK_ERROR:
        return t('CONNECTION_INTERRUPTED');
      case PAYMENT_ERRORS.PAYMENT_IS_IN_PROGRESS:
        return t('PAYMENT_IS_LOCKED');
      default:
        return tAdyen(error);
    }
  };
  const renderErrorByScreen = () => {
    switch (true) {
      case !isMobile:
        return (
          <div className="payment-title">
            <p>{t('SS_PAYMENT_INFORMATION')}</p>
            {error && <div className="payment-error">{renderError()}</div>}
          </div>
        );
      default:
        return error && <div className="payment-error">{renderError()}</div>;
    }
  };
  return (
    <div className="edit-payment">
      {renderErrorByScreen()}
      {renderAffirmativeSubcriptionTerms()}
      <div id="dropin-container1"></div>
    </div>
  );
};

const MakePaymentPage = (props: any) => {
  const {
    isMobile,
    amountValueRef,
    amountValue,
    setOpenConfirmPayExistCard,
    showCheckboxSaveForNext,
    paymentAction,
    makePaymentSuccess,
    isVerifySuccess,
    triggerOpenLeavePaymentConfirm,
    showExistCard,
    saveForNextCheckboxRef,
    t,
    showErrorTnC,
    setShowErrorTnc,
    termsAndConditionsErrorRef,
    scrollAndSetErrorTermsAndConditions,
    handleInputChanged,
    showRegisterCardFragment,
  } = props;
  const groupButtonClass = isMobile ? 'payment-bottom-button' : 'payment-bottom-button-desktop';
  return (
    <>
      <MakePayment
        handleInputChanged={handleInputChanged}
        showRegisterCardFragment={showRegisterCardFragment}
        amountValueRef={amountValueRef}
        amountValue={amountValue}
        setOpenConfirmPayExistCard={setOpenConfirmPayExistCard}
        isHideNextRecurring={!showCheckboxSaveForNext || (paymentAction && makePaymentSuccess && !isVerifySuccess)}
        showExistCard={showExistCard}
        saveForNextCheckboxRef={saveForNextCheckboxRef}
        showErrorTnC={showErrorTnC}
        setShowErrorTnc={setShowErrorTnc}
        termsAndConditionsErrorRef={termsAndConditionsErrorRef}
        scrollAndSetErrorTermsAndConditions={scrollAndSetErrorTermsAndConditions}
      />
      <div className={groupButtonClass}>
        <button tabIndex={8} className="payment-bottom-button__cancel" onClick={triggerOpenLeavePaymentConfirm}>
          {t('SS_CANCEL')}
        </button>
      </div>
    </>
  );
};

export const Payments = (props: MembershipInfoProps) => {
  const { setIsEditPayment, isEditPayment, setIsMakePayment, isMakePayment } = props;

  const { t } = useTranslation();

  const [, , isMobile] = useDeviceCheck();
  const location = useLocation();
  const query = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const history = useHistory();
  //redux store
  const {
    getPaymentMethodFail,
    editPayment,
    makePaymentFail,
    clearPaymentState,
    verifyAfterThreeDS,
    verifyAfterThreeDSEditPayment,
    verifyAfterThreeDSRegisterCard,
    setOpenModal,
    callbackPayment,
    makePayment,
    payWithRegisterCard,
  } = paymentActions;
  const {
    paymentMethodsResponse,
    editPaymentSuccess,
    payWithRegisterCardSuccess,
    makePaymentSuccess,
    donePayWithRegisterCard,
    doneUpdatePayment,
    paymentResponse,
    paymentData,
    error,
    isOpenConfirm,
    callbackConfirm,
    callbackPaymentRes,
    loadingCallback,
    doneMakePayment,
    doneMakePaymentWithReceived,
  } = useTypedSelector((state) => state.payment);
  const { membershipDetails, totalRecurringFee } = useTypedSelector((state) => state.membership);
  const { paymentAction } = useTypedSelector((state) => state.payment);
  const { regexs } = useTypedSelector((state) => state.personal);

  const ssGoLive = regexs.find((ph) => ph.key === PERSONAL_CONSTANTS.SS_GO_LIVE_DATE) || {
    key: '',
    value: '',
  };

  const primary = membershipDetails.payment?.primary;
  const paymentMethod = membershipDetails.paymentMethod;
  const previousPrimary = membershipDetails.previousPayment?.primary;
  const [creditImg, setCreditImg] = useState(getIconUrl('CardPayment'));
  const [isCreditCard, setIsCreditCard] = useState(false);
  const [isVerifySuccess, setIsVerifySuccess] = useState(false);
  const [renderCard, setRenderCard] = useState(false);
  const { arrearInfo } = useTypedSelector((state) => state.payment);
  const [amountValue, setAmountValue] = useState<any>(
    Number(sessionStorage.getItem(SESSION_STORAGE_NAMES.AMOUNT_VALUE)) || 'init'
  );
  const [currentAmount, setCurrentAmount] = useState<any>(
    Number(sessionStorage.getItem(SESSION_STORAGE_NAMES.AMOUNT_VALUE)) || arrearInfo?.totalOutstanding
  );
  const [showRegisterCardFragment, setShowRegisterCardFragment] = useState(true);
  const [openConfirmPayExistCard, setOpenConfirmPayExistCard] = useState(false);
  const [showExistCard, setShowExistCard] = useState(true);
  const [showCheckboxSaveForNext, setShowCheckboxSaveForNext] = useState(true);
  const [showErrorTnC, setShowErrorTnc] = useState(false);
  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const [loadingTransaction, setLoadingTransaction] = useState(true);
  const [adyenCheckout, setAdyenCheckout] = useState<any>();

  const [filterMonthTransaction, setFilterMonthTransaction] = useState(moment().format('YYYY-MM-01'));

  const amountValueRef = useRef<HTMLInputElement>(null);
  const saveForNextCheckboxRef = useRef<HTMLInputElement>(null);
  const termsAndConditionsErrorRef = useRef<HTMLDivElement>(null);
  const allowProccessPaymentRef = useRef<boolean>(true);

  const dispatch = useDispatch();
  const clearPaymentAction = (clearResponse?: boolean) => dispatch(clearPaymentState(clearResponse));
  const editPaymentAction = (membershipDetails: any, data: any, dropinForm: any) =>
    dispatch(editPayment(membershipDetails, data, dropinForm));
  const makePaymentFailAction = (error: any) => dispatch(makePaymentFail(error));
  const verifyAfterThreeDSAction = (body: VerifyThreeDSBody) => dispatch(verifyAfterThreeDS(body));
  const VerifyAfterThreeDSRegisterCardAction = (body: VerifyThreeDSBody) =>
    dispatch(verifyAfterThreeDSRegisterCard(body));
  const verifyAfterThreeDSEditPaymentAction = (body: SubmitUpdatePaymentBody) =>
    dispatch(verifyAfterThreeDSEditPayment(body));
  const setOpenModalAction = (isOpen: boolean, callback?: Function) => dispatch(setOpenModal(isOpen, callback));
  const makePaymentAction = (
    amountValue: number,
    payment: {
      paymentMethod: any;
      browserInfo: any;
      paymentType: string;
    },
    enableRecurring: boolean,
    dropinForm: any
  ) => dispatch(makePayment(amountValue, payment, enableRecurring, dropinForm));

  const payWithRegisterCardAction = (amount: number) => dispatch(payWithRegisterCard(amount));
  const callbackPaymentAction = (token: string) => dispatch(callbackPayment(token));
  const getPaymentMethodErrorAction = (error: any) => dispatch(getPaymentMethodFail({ error: error }));
  const currentDate = moment().format(SYSTEM_DATE_FORMAT);
  const goLiveDate = moment(ssGoLive.value).format(SYSTEM_DATE_FORMAT);
  const monthRanges = useMemo(() => monthRangeDates(goLiveDate, currentDate), [goLiveDate, currentDate]);
  const mapMonthYearData = monthRanges.map((m) => {
    return { label: convertMonthToText(m.split('-')[1]), value: m };
  });

  const defaultMonth = mapMonthYearData.find((m) => m.value === moment().format('YYYY-MM-01'));

  const isPrimaryExist = primary && primary.paymentMethod;

  const isPreviousPaymentCredit =
    !isPrimaryExist &&
    ((previousPrimary && previousPrimary.paymentMethod === PERSONAL_CONSTANTS.CREDIT_CARD) ||
      (previousPrimary && previousPrimary.paymentMethod === PERSONAL_CONSTANTS.CC));

  const setCreditCard = () => {
    setCreditImg(getIconUrl('CardPayment'));
    setIsCreditCard(true);
  };

  const setDirectDebit = () => {
    setCreditImg(getIconUrl('DebitCard'));
    setIsCreditCard(false);
  };

  const setCash = () => {
    setCreditImg(getIconUrl('Cash'));
    setIsCreditCard(false);
  };

  useEffect(() => {
    switch (paymentMethod) {
      case PERSONAL_CONSTANTS.CC:
      case PERSONAL_CONSTANTS.CREDIT_CARD:
      case PERSONAL_CONSTANTS.EFT:
      case PERSONAL_CONSTANTS.CONVERSION:
        setCreditCard();
        break;
      case PERSONAL_CONSTANTS.DD:
      case PERSONAL_CONSTANTS.DIRECT_DEBIT:
        setDirectDebit();
        break;
      default:
        setCash();
    }
  }, [paymentMethod]);

  const scrollAndSetErrorTermsAndConditions = () => {
    termsAndConditionsErrorRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'start',
    });
    setShowErrorTnc(true);
  };

  const removeQueryParam = (param: string) => {
    if (query.has(param)) {
      query.delete(param);
      history.replace({
        search: query.toString(),
      });
    }
  };

  useEffect(() => {
    switch (true) {
      case doneMakePayment === DONE_MAKE_PAYMENT_TYPES.NORMAL:
      case doneMakePaymentWithReceived === DONE_MAKE_PAYMENT_TYPES.NORMAL:
        handleLeaveMakePayment();
        history.push(MEMBERSHIP_URL);
        break;
      case donePayWithRegisterCard === DONE_MAKE_PAYMENT_TYPES.NORMAL:
        setOpenModalAction(false);
        handleLeaveMakePayment();
        history.push(MEMBERSHIP_URL);
        break;
      case doneUpdatePayment:
        handleLeave();
        history.push(MEMBERSHIP_URL);
        break;
      default:
        break;
    }
  }, [doneMakePayment, doneMakePaymentWithReceived, donePayWithRegisterCard, doneUpdatePayment]);

  useEffect(() => {
    document.title = t('SS_PAYMENT_AND_CREDIT');
  }, [t('SS_LOCAL')]);

  useEffect(() => {
    const token = query.get(PERSONAL_CONSTANTS.TOKEN);

    if (token && !callbackPaymentRes && !loadingCallback && getProcessPurpose()) {
      callbackPaymentAction(token);

      removeQueryParam('token');

      if (getProcessPurpose() === PAYMENT_PURPOSES.EDIT_PAYMENT) {
        setRenderCard(true);
      }
    }
  }, [loadingCallback, callbackPaymentRes]);

  const handleVerifyAfterThreeDSEditPayment = (
    isPaymentDataExist: boolean, 
    callbackPaymentRes: any
  ) => {
    const { 
      paRes, 
      md
    } = callbackPaymentRes;
    const paymentData = localStorage.getItem(PERSONAL_CONSTANTS.PAYMENT_DATA) || '';
    
    if (isPaymentDataExist && hasParamsThreeDs(callbackPaymentRes)) {
      verifyAfterThreeDSEditPaymentAction({
        paymentData,
        verifyData: { 
          md, 
          paRes 
        },
      });
    }
  }

  const handleVerifyAfterThreeDSMakePayment = (
    isPaymentDataExist: boolean, 
    callbackPaymentRes: any
  ) => {
    const {
      md, 
      paRes, 
      payload, 
      redirectResult
    } = callbackPaymentRes;
    const paymentData = localStorage.getItem(PERSONAL_CONSTANTS.PAYMENT_DATA) || '';

    if (isPaymentDataExist && hasParamsThreeDs(callbackPaymentRes)) {
      verifyAfterThreeDSAction({
        paymentData,
        verifyData: { 
          md, 
          paRes 
        },
        isVerifyForPayment: true,
      });
    } else if (
      hasPaymentMethodType(callbackPaymentRes) && 
      hasPayload(callbackPaymentRes)
    ) {
      verifyAfterThreeDSAction({
        paymentData,
        verifyData: { 
          details: { 
            payload 
          } 
        },
        isVerifyForPayment: true,
      });
    } else if (
      hasPaymentMethodType(callbackPaymentRes) && 
      hasRedirectResult(callbackPaymentRes)
    ) {
      verifyAfterThreeDSAction({
        paymentData,
        verifyData: { 
          details: { 
            redirectResult 
          } 
        },
        isVerifyForPayment: true,
      });
    }
  }

  const handleVerifyAfterThreeDSExistCard = (
    isPaymentDataExist: boolean, 
    callbackPaymentRes: any
  ) => {
    const {
      md, 
      paRes, 
      payload, 
    } = callbackPaymentRes;
    const paymentData = localStorage.getItem(PERSONAL_CONSTANTS.PAYMENT_DATA) || '';

    if (isPaymentDataExist && hasParamsThreeDs(callbackPaymentRes)) {
      VerifyAfterThreeDSRegisterCardAction({
        paymentData,
        verifyData: { 
          md, 
          paRes 
        },
        isVerifyForPayment: true,
      });
    } else if (
      hasPaymentMethodType(callbackPaymentRes) && 
      hasPayload(callbackPaymentRes) && 
      hasResultCode(callbackPaymentRes)
    ) {
      VerifyAfterThreeDSRegisterCardAction({
        paymentData,
        verifyData: { 
          details: { 
            payload 
          } 
        },
        isVerifyForPayment: true,
      });
    }
  }

  useEffect(() => {
    if (callbackPaymentRes && !editPaymentSuccess) {
      const paymentData = localStorage.getItem(PERSONAL_CONSTANTS.PAYMENT_DATA) || '';
      const isPaymentDataExist: boolean = paymentData.length > 0; 
      const processPurpose = getProcessPurpose();
      switch(processPurpose) { 
        case PAYMENT_PURPOSES.EDIT_PAYMENT: 
          handleVerifyAfterThreeDSEditPayment(isPaymentDataExist, callbackPaymentRes); 
          break;
        case PAYMENT_PURPOSES.MAKE_PAYMENT:
          handleVerifyAfterThreeDSMakePayment(isPaymentDataExist, callbackPaymentRes);
          break; 
        case PAYMENT_PURPOSES.MAKE_PAYMENT_EXIST_CARD: 
          handleVerifyAfterThreeDSExistCard(isPaymentDataExist, callbackPaymentRes);
          break;
      }
    }
  }, [callbackPaymentRes, editPaymentSuccess]);

  useEffect(() => {
    if (isVerifySuccess && getProcessPurpose() === PAYMENT_PURPOSES.EDIT_PAYMENT) {
      setRenderCard(true);
    }
    if (
      (getProcessPurpose() === PAYMENT_PURPOSES.MAKE_PAYMENT_EXIST_CARD ||
        getProcessPurpose() === PAYMENT_PURPOSES.MAKE_PAYMENT) &&
      isVerifySuccess
    ) {
      handleLeaveMakePayment();
    } else if (
      (getProcessPurpose() === PAYMENT_PURPOSES.MAKE_PAYMENT_EXIST_CARD ||
        getProcessPurpose() === PAYMENT_PURPOSES.MAKE_PAYMENT) &&
      !isVerifySuccess
    ) {
      setRenderCard(true);
      handleChangeToMakePayment(true);
    }
  }, [isVerifySuccess]);

  useEffect(() => {
    if (getProcessPurpose() === PAYMENT_PURPOSES.EDIT_PAYMENT && !isVerifySuccess && paymentMethodsResponse) {
      checkHandleChangeEditPayment(true);
    }
  }, [isVerifySuccess, paymentMethodsResponse]);

  useEffect(() => {
    if (error && getProcessPurpose() === PAYMENT_PURPOSES.MAKE_PAYMENT_EXIST_CARD) {
      setShowExistCard(true);
    }
  }, [error]);

  useEffect(() => {
    if (error) {
      allowProccessPaymentRef.current = true;
      setShowRegisterCardFragment(true);
    }
  }, [error]);

  useEffect(() => {
    const initMakePayment =  async () => {
      if (
        (isMakePayment &&
          paymentMethodsResponse &&
          !makePaymentSuccess &&
          !document.getElementById('dropin-container')!.hasChildNodes()) ||
        (currentAmount && document.getElementById('dropin-container'))
      ) {
        setShowRegisterCardFragment(true);
        const configuration: any = {
          paymentMethodsResponse: paymentMethodsResponse,
          clientKey: _env.ADYEN_KEY,
          locale: PERSONAL_CONSTANTS.EN_US,
          translations: translations(t, isMakePayment),
          environment: _env.ADYEN_ENV,
          setStatusAutomatically: false,
          onSubmit: (state: any, component: any) => {
            if (!allowProccessPaymentRef.current) {
              return;
            }
            const { paymentMethod, shopperName, shopperEmail, browserInfo } = state?.data;
            let paymentMethodCustom = { ...paymentMethod };
            if (shopperEmail) {
              paymentMethodCustom = { ...paymentMethod, ...shopperName, shopperEmail };
            }
            const amount = Number(amountValueRef.current?.value);
            const checkSaveForNext = !!saveForNextCheckboxRef.current?.checked;
            sessionStorage.setItem(SESSION_STORAGE_NAMES.SAVE_FOR_NEXT_RECURRING, checkSaveForNext ? ANSWER.YES : ANSWER.NO);
            setAmountValue(amount);
            if (state.isValid && amount > 0) {
              if (sessionStorage.getItem(SESSION_STORAGE_NAMES.AGREE_TNC) === ANSWER.YES) {
                setShowRegisterCardFragment(false);
  
                if (paymentMethod) {
                  const payment = {
                    paymentMethod: paymentMethodCustom,
                    browserInfo: browserInfo,
                    paymentType: paymentMethod.type,
                  };
                  if (shopperEmail) {
                    if (membershipDetails.emailAddress?.toLowerCase() === shopperEmail?.toLowerCase()) {
                      setProcessPurpose(PAYMENT_PURPOSES.MAKE_PAYMENT);
                      makePaymentAction(amount, payment, checkSaveForNext, component);
                      allowProccessPaymentRef.current = false;
                    } else {
                      makePaymentFailAction(t('ONLINE_PAYMENT_EMAIL_NOT_MATCH_EMAIL_DETAIL'));
                    }
                  } else {
                    setProcessPurpose(PAYMENT_PURPOSES.MAKE_PAYMENT);
                    makePaymentAction(amount, payment, checkSaveForNext, component);
                    allowProccessPaymentRef.current = false;
                  }
                }
              } else {
                scrollAndSetErrorTermsAndConditions();
              }
            } else {
              if (sessionStorage.getItem(SESSION_STORAGE_NAMES.AGREE_TNC) !== ANSWER.YES) {
                scrollAndSetErrorTermsAndConditions();
              }
            }
          },
          onChange: (state: any, dropin: any) => {
            const { isValid } = state;
            const verifyButton: any = document.getElementsByClassName('adyen-checkout__button')[0];
  
            if (verifyButton) {
              if (isValid) {
                verifyButton.classList.remove('adyen-checkout__button--gray-out');
              } else {
                verifyButton.classList.add('adyen-checkout__button--gray-out');
              }
            }
            allowProccessPaymentRef.current = true;
          },
  
          onError: (err: any) => {
            console.log('Error - ', err);
          },
  
          paymentMethodsConfiguration: {
            paywithgoogle: {
              configuration: {
                merchantName: _env.GOOGLE_MERCHANT_NAME,
                merchantId: _env.GOOGLE_MERCHANT_ID,
                gatewayMerchantId: _env.GOOGLE_GATEWAY_MERCHANT_ID,
              },
              amount: {
                value:
                  Number(isNumber(currentAmount) ? currentAmount : amountValueRef.current?.value) *
                  _env.MINOR_UNIT[_env.COUNTRY_CODE],
                currency: membershipDetails.currencyCode,
              },
              //Set this to PRODUCTION when you're ready to accept live payments
              environment: _env.ADYEN_ENV,
            },
            applepay: {
              amount: {
                value:
                  Number(isNumber(currentAmount) ? currentAmount : amountValueRef.current?.value) *
                  _env.MINOR_UNIT[_env.COUNTRY_CODE],
                currency: membershipDetails.currencyCode,
              },
              configuration: {
                merchantName: _env.APPLE_MERCHANT_NAME,
                merchantId: _env.APPLE_MERCHANT_ID,
              },
            },
            card: {
              hasHolderName: true,
              holderNameRequired: true,
              enableStoreDetails: true,
              positionHolderNameOnTop: false,
              hideCVC: false,
              name: t('SS_PAYMENT_PAYMENT_METHOD'),
            },
          },
        };
        if (adyenCheckout) {
          adyenCheckout.unmount();
        }
        const checkout = await AdyenCheckout(configuration);
        const adyencheckout = checkout.create('dropin', {
          onSelect: (component: any) => {
            setShowCheckboxSaveForNext(component.props.name === t('SS_PAYMENT_PAYMENT_METHOD'));
          },
        });
        if(elementExistInDom('#dropin-container')) {
          adyencheckout.mount('#dropin-container');
        }
        setAdyenCheckout(adyencheckout);
      }
    }
    initMakePayment();
  }, [isMakePayment, makePaymentSuccess, paymentMethodsResponse, currentAmount]);

  useEffect(() => {
    const initEditPayment = async () => {
      if (isEditPayment && paymentMethodsResponse && !editPaymentSuccess) {
        const configuration: any = {
          paymentMethodsResponse: {
            paymentMethods: paymentMethodsResponse.paymentMethods.filter(
              (pm: any) => pm.type === PERSONAL_CONSTANTS.SCHEME
            ),
            groups: paymentMethodsResponse.groups,
          },
          clientKey: _env.ADYEN_KEY,
          locale: PERSONAL_CONSTANTS.EN_US,
          translations: translations(t),
          environment: _env.ADYEN_ENV,
          setStatusAutomatically: false,
          onSubmit: (state: any, component: any) => {
            if (!allowProccessPaymentRef.current) {
              return;
            }
  
            const { paymentMethod } = state?.data;
            if (state.isValid) {
              if (paymentMethod) {
                editPaymentAction(membershipDetails, state.data, component);
                setProcessPurpose(PAYMENT_PURPOSES.EDIT_PAYMENT);
                allowProccessPaymentRef.current = false;
              }
            }
          },
  
          onChange: ({ isValid }: { isValid: boolean }) => {
            const verifyButton: any = document.getElementsByClassName('adyen-checkout__button')[0];
            if (verifyButton) {
              if (isValid) {
                verifyButton.classList.remove('adyen-checkout__button--gray-out');
              } else {
                verifyButton.classList.add('adyen-checkout__button--gray-out');
              }
            }
            allowProccessPaymentRef.current = true;
          },
          onError: (err: any) => {
            console.log('Error - ', err);
          },
          paymentMethodsConfiguration: {
            card: {
              hasHolderName: true,
              holderNameRequired: true,
              enableStoreDetails: true,
              positionHolderNameOnTop: false,
              hideCVC: false,
              name: t('SS_PAYMENT_PAYMENT_METHOD'),
            },
          },
        };
        const checkout = await AdyenCheckout(configuration);
        if(elementExistInDom('#dropin-container1')) {
          checkout.create('dropin').mount('#dropin-container1');
        }
        setImmediate(() => {
          (document.getElementsByClassName('adyen-checkout__button--pay')[0] as HTMLInputElement).disabled =
            !!totalRecurringFee;
        });
      }
    }
    initEditPayment();
  }, [isEditPayment, editPaymentSuccess]);

  useEffect(() => { 
    if (
      (editPaymentSuccess || makePaymentSuccess || payWithRegisterCardSuccess) &&
      Object.keys(paymentResponse).length !== 0
    ) {
      setIsVerifySuccess(true);
      setIsEditPayment(false);
    }
    if (getProcessPurpose() === PAYMENT_PURPOSES.EDIT_PAYMENT && error) {
      setIsEditPayment(true);
      setIsVerifySuccess(false);
    }
  }, [paymentResponse, editPaymentSuccess, payWithRegisterCardSuccess, error]);

  useEffect(() => {
    const handleOnAdditionalDetailsWithAdyen = async () => {
      if (
        paymentAction &&
        editPaymentSuccess &&
        getProcessPurpose() === PAYMENT_PURPOSES.EDIT_PAYMENT &&
        !isVerifySuccess
      ) {
        const configuration = {
          locale: 'en_US',
          environment: _env.ADYEN_ENV,
          clientKey: _env.ADYEN_KEY,
          onAdditionalDetails: handleOnAdditionalDetails,
          
        };
        const checkout = await AdyenCheckout(configuration);
        if(elementExistInDom("#dropin-container1")) {
            checkout &&
            checkout
              .createFromAction({
                ...paymentAction
              }, {
                size: '05',
              })
              .mount('#dropin-container1');
        }
      }
   
      if (
        paymentAction &&
        makePaymentSuccess &&
        getProcessPurpose() === PAYMENT_PURPOSES.MAKE_PAYMENT &&
        !isVerifySuccess
      ) { 
          const configuration = {
            locale: 'en_US',
            environment: _env.ADYEN_ENV,
            clientKey: _env.ADYEN_KEY,
            onAdditionalDetails: handleMakePaymentOnAdditionalDetails,
            onError: (err: any) => {
              console.log('Error - ', err);
            },
          };
          const checkout = await AdyenCheckout(configuration); 
          if(elementExistInDom("#dropin-container")) {
              checkout &&
                checkout
                .createFromAction({
                  ...paymentAction
                }, {
                  size: '05' 
                })
                .mount('#dropin-container');
          } 
      }
  
      if (
        paymentAction &&
        payWithRegisterCardSuccess &&
        getProcessPurpose() === PAYMENT_PURPOSES.MAKE_PAYMENT_EXIST_CARD &&
        !isVerifySuccess
      ) {
        const configuration = {
          locale: 'en_US',
          environment: _env.ADYEN_ENV,
          clientKey: _env.ADYEN_KEY,
          onAdditionalDetails: handleRegisterCardOnAdditionalDetails,
        };
  
        const checkout = await AdyenCheckout(configuration);
        if(elementExistInDom("#dropin-container1")) {
          checkout &&
          checkout
            .createFromAction({
              ...paymentAction
            }, {
              size: '05',
            })
            .mount('#dropin-container1');
        }
  
        setShowExistCard(false);
      }
    }
    handleOnAdditionalDetailsWithAdyen();
  }, [paymentAction, isVerifySuccess, editPaymentSuccess, makePaymentSuccess, payWithRegisterCardSuccess]);

  useEffect(() => {
    setLoadingTransaction(true);
    (async () => {
      const { data } =
        filterMonthTransaction !== 'all'
          ? await getTransactions(
            membershipDetails.memberId,
            moment(filterMonthTransaction).format(SYSTEM_DATE_FORMAT),
            moment(filterMonthTransaction).endOf('month').add(1, 'days').format(SYSTEM_DATE_FORMAT)
          )
          : await getTransactions(
            membershipDetails.memberId,
            moment(mapMonthYearData[mapMonthYearData.length - 1].value).format(SYSTEM_DATE_FORMAT),
            moment(mapMonthYearData[0].value).endOf('month').add(1, 'days').format(SYSTEM_DATE_FORMAT)
          );
      setTransactions(data);
      setLoadingTransaction(false);
    })();
  }, [filterMonthTransaction]);

  const handleOnAdditionalDetails = (state: any, _: any) => {
    verifyAfterThreeDSEditPaymentAction({
      verifyData: { details: state.data.details },
      paymentData: state.data.paymentData,
    });
  };

  const handleMakePaymentOnAdditionalDetails = (state: any, _: any) => { 
    verifyAfterThreeDSAction({
      verifyData: { details: state.data.details },
      paymentData: state.data.paymentData,
      isVerifyForPayment: true,
    });
  };

  const handleRegisterCardOnAdditionalDetails = (state: any, _: any) => {
    VerifyAfterThreeDSRegisterCardAction({
      verifyData: { details: state.data.details },
      paymentData: state.data.paymentData,
      isVerifyForPayment: true,
    });
  };

  const handleChangeToEditPayment = (change: boolean) => {
    setIsEditPayment(change);
  };

  const handleChangeToMakePayment = (change: boolean) => {
    setIsMakePayment(change);
  };

  const checkHandleChangeEditPayment = (change: boolean) => {
    if (paymentMethodsResponse && Object.keys(paymentMethodsResponse.paymentMethods).length > 0) {
      handleChangeToEditPayment(change);
    } else {
      getPaymentMethodErrorAction(t('SS_PAYMENT_NO_PAYMENT_METHODS'));
    }
  };

  const triggerOpenMakePayment = () => {
    if (paymentMethodsResponse && Object.keys(paymentMethodsResponse.paymentMethods).length > 0) {
      gtag('event', 'Button', {
        event_action: 'PCMakePayment',
        event_label: 'PCMakePaymentClicked',
      });
      handleChangeToMakePayment(true);
    } else {
      getPaymentMethodErrorAction(t('SS_PAYMENT_NO_PAYMENT_METHODS'));
    }
  };

  useEffect(() => {
    if (paymentData) {
      localStorage.setItem(SESSION_STORAGE_NAMES.PAYMENT_DATA, paymentData);
    }
  }, [paymentData]);

  const handleLeave = () => {
    setIsVerifySuccess(false);
    handleChangeToEditPayment(false);
    if (callbackConfirm) {
      callbackConfirm();
    }
  };

  const handleLeaveMakePayment = () => {
    setIsVerifySuccess(false);
    handleChangeToMakePayment(false);
    if (callbackConfirm) {
      callbackConfirm();
    }
  };

  const setProcessPurpose = (value: string) => {
    sessionStorage.setItem(SESSION_STORAGE_NAMES.PROCESS_PURPOSE, value);
  };

  const getProcessPurpose = () => {
    return sessionStorage.getItem(SESSION_STORAGE_NAMES.PROCESS_PURPOSE);
  };

  const renderNoPrimaryMethod = () => {
    return (
      <div className="payment-container">
        <p className="payment-container__no-payment">{t('SS_PAYMENT_NO_PRIMARY_METHOD')}</p>
      </div>
    );
  };

  const convertPaymentMethod = (value: string) => {
    switch (value) {
      case PERSONAL_CONSTANTS.CREDIT_CARD:
      case PERSONAL_CONSTANTS.CC:
        return PERSONAL_CONSTANTS.CARD;
      case PERSONAL_CONSTANTS.DD:
      case PERSONAL_CONSTANTS.DIRECT_DEBIT:
        return PERSONAL_CONSTANTS.DIRECT_DEBIT;
      default:
        return value;
    }
  };

  const triggerOpenLeavePaymentConfirm = () => {
    setOpenModalAction(true);
  };

  const handlePayWithExistingCard = () => {
    const amount = Number(amountValueRef.current?.value);
    setAmountValue(amount);
    payWithRegisterCardAction(amount);
    setProcessPurpose(PAYMENT_PURPOSES.MAKE_PAYMENT_EXIST_CARD);
  };

  const handleInputChanged = debounce(() => {
    const amount = Number(amountValueRef.current?.value);
    setCurrentAmount(amount);
  }, 200);

  const handleChangeMonth = (selectedMonth: string) => {
    gtag('event', 'Select', {
      event_action: 'PCPaymentDate',
      event_label: 'PCPaymentDateChanged',
    });
    setFilterMonthTransaction(selectedMonth);
  };

  if (Object.keys(membershipDetails).length === 0) {
    return null;
  }

  return (
    <>
      <div className="payment-container">
        {!isPrimaryExist && !isPreviousPaymentCredit && renderNoPrimaryMethod()}
        {!isEditPayment && !isMakePayment && !renderCard && (isPrimaryExist || isPreviousPaymentCredit) && (
          <>
            <Card className="payment">
              <div className="payment__method">
                <p>{t('SS_PAYMENT_INFORMATION')}</p>
                <div className="payment__method-actions">
                  {!isMobile && (
                    <button className="transparent-button" onClick={triggerOpenMakePayment}>
                      <span>{t('SS_MAKE_A_PAYMENT')}</span>
                    </button>
                  )}
                  {isCreditCard && (
                    <button className="transparent-button" onClick={() => checkHandleChangeEditPayment(true)}>
                      <img src={getIconUrl('EditPayment')} alt="edit-payment" />
                    </button>
                  )}
                </div>
              </div>
              <div className="payment__method-name">
                <img src={creditImg} alt="card-payment" />
                <b>{convertPaymentMethod((isPrimaryExist && primary.paymentMethod) || PERSONAL_CONSTANTS.CARD)}</b>
              </div>
              {isPrimaryExist && convertPaymentMethod(primary.paymentMethod) === PERSONAL_CONSTANTS.CARD && <hr />}
              {isPrimaryExist && convertPaymentMethod(primary.paymentMethod) === PERSONAL_CONSTANTS.CARD && (
                <div className="payment__method-info">
                  <div className="payment__method-suspended">
                    <strong>{t('SS_PAYMENT_CARD_INFORMATION')}</strong>
                  </div>
                  <b>
                    {t('SS_PAYMENT_CARD_ENDING')}{' '}
                    {primary && primary.accountNumber?.substring(primary.accountNumber.length - 4)}
                  </b>
                  <b>
                    {t('SS_PAYMENT_EXPIRES')}{' '}
                    {primary && primary.expiryDate && ConvertExpiryDate(primary.expiryDate, true)}
                  </b>
                </div>
              )}
              {isPrimaryExist && primary.paymentMethod === PERSONAL_CONSTANTS.DIRECT_DEBIT && (
                <div>
                  <div className="payment__method-info">
                    <strong>{t('SS_PAYMENT_ACCOUNT_NAME')}</strong>
                    <b>{primary.accountName}</b>
                  </div>
                  <div className="payment__method-info">
                    <strong>{t('SS_PAYMENT_ACCOUNT_NUMBER')}</strong>
                    <b>******{primary.accountNumber.substring(primary.accountNumber.length - 4)}</b>
                  </div>
                </div>
              )}
            </Card>
            <div className="transactions__wrapper">
              <div className="transactions__filter">
                <SelectField
                  filterOpts={mapMonthYearData}
                  defaultOpt={defaultMonth || goLiveDate}
                  handleChange={handleChangeMonth}
                />
              </div>
              <ListTransactions transactions={transactions} loadingTransaction={loadingTransaction}></ListTransactions>
            </div>
          </>
        )}

        {isEditPayment && (
          <>
            <UpdatePaymentPage error={error} />
            <div className={isMobile ? 'payment-bottom-button' : 'payment-bottom-button-desktop'}>
              <button tabIndex={8} className="payment-bottom-button__cancel" onClick={triggerOpenLeavePaymentConfirm}>
                {t('SS_CANCEL')}
              </button>
            </div>
          </>
        )}

        {isMakePayment && (
          <MakePaymentPage
            isMobile={isMobile}
            amountValueRef={amountValueRef}
            handleInputChanged={handleInputChanged}
            amountValue={amountValue}
            setOpenConfirmPayExistCard={setOpenConfirmPayExistCard}
            paymentAction={paymentAction}
            makePaymentSuccess={makePaymentSuccess}
            isVerifySuccess={isVerifySuccess}
            triggerOpenLeavePaymentConfirm={triggerOpenLeavePaymentConfirm}
            showCheckboxSaveForNext={showCheckboxSaveForNext}
            showExistCard={showExistCard}
            saveForNextCheckboxRef={saveForNextCheckboxRef}
            showErrorTnC={showErrorTnC}
            setShowErrorTnc={setShowErrorTnc}
            termsAndConditionsErrorRef={termsAndConditionsErrorRef}
            scrollAndSetErrorTermsAndConditions={scrollAndSetErrorTermsAndConditions}
            t={t}
            showRegisterCardFragment={showRegisterCardFragment}
          />
        )}
      </div>

      <ConfirmModal
        openModal={isOpenConfirm && (getProcessPurpose() === PAYMENT_PURPOSES.EDIT_PAYMENT || isEditPayment)}
        handleOpenModal={setOpenModalAction}
        displayMessage={t('SS_CONFIRM_MESS')}
        confirmLabel={t('SS_LEAVE')}
        cancelLabel={t('SS_CANCEL')}
        confirmAction={() => {
          handleLeave();
          clearPaymentAction(true);
          sessionStorage.removeItem(SESSION_STORAGE_NAMES.PROCESS_PURPOSE);
        }}
      />

      <ConfirmModal
        openModal={isOpenConfirm && (getProcessPurpose() === PAYMENT_PURPOSES.MAKE_PAYMENT || isMakePayment)}
        handleOpenModal={setOpenModalAction}
        displayMessage={t('SS_CONFIRM_MESS')}
        confirmLabel={t('SS_LEAVE')}
        cancelLabel={t('SS_CANCEL')}
        confirmAction={() => {
          handleLeaveMakePayment();
          clearPaymentAction(true);
          setRenderCard(false);
          sessionStorage.removeItem(SESSION_STORAGE_NAMES.SAVE_FOR_NEXT_RECURRING);
          sessionStorage.removeItem(SESSION_STORAGE_NAMES.PAYMENT_TYPE);
          sessionStorage.removeItem(SESSION_STORAGE_NAMES.PROCESS_PURPOSE);
          sessionStorage.removeItem(SESSION_STORAGE_NAMES.AGREE_TNC);

          if (membershipDetails.currentBalance < 0) {
            sessionStorage.setItem(SESSION_STORAGE_NAMES.AMOUNT_VALUE, `${-membershipDetails.currentBalance}`);
          } else {
            sessionStorage.removeItem(SESSION_STORAGE_NAMES.AMOUNT_VALUE);
          }
        }}
      />

      <ConfirmModal
        openModal={openConfirmPayExistCard}
        handleOpenModal={setOpenConfirmPayExistCard}
        displayMessage={t('SS_PAYMENT_CONFIRM_PAY_EXISTING_CARD')}
        confirmLabel={t('SS_YES')}
        cancelLabel={t('SS_CANCEL')}
        confirmAction={handlePayWithExistingCard}
      />
    </>
  );
};

export default Payments;
