import { baseApi } from './base';
import { SEARCH_INBOX, RECENT_ACTIVITY } from './apis';

export async function searchInbox(searchParams: any): Promise<any> {
  return await baseApi.post(SEARCH_INBOX, searchParams);
}

export async function getRecentActivity(body: any): Promise<any> {
  return await baseApi.post(RECENT_ACTIVITY, body);
}
