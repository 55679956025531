import { Button, CircularProgress, FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import React, { useEffect, useMemo, useState } from 'react';
import Select, { SingleValue } from 'react-select';
import { getAllClubs, submitInquiry } from 'services';

import { CustomInput } from 'components/InputField';
import { MEMBERSHIP_URL } from 'utility/routesURL';
import ModalConFirm from 'components/ModalConfirm';
import constants from 'constants/personalConstant';
import PERSONAL_CONSTANTS from 'constants/personalConstant';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useTypedSelector } from 'redux/configureStore';
import emitNotify from 'utility/emitNotify';

interface ContactUsProps { }

const ContactUs = (props: ContactUsProps) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { regexs, fields: inputFields } = useTypedSelector((state) => state.personal);
  const { membershipDetails } = useTypedSelector((state) => state.membership);

  const [confirmBeforeExit, setConfirmBeforeExit] = useState(false);
  const [emailAddress, setEmailAddress] = useState(membershipDetails.emailAddress || '');
  const [inquiry, setInquiry] = useState('');
  const [mobilePhone, setMobilePhone] = useState(membershipDetails.mobileNumber || '');
  const [methodContact, setMethodContact] = useState('phone');
  const [contactClub, setContactClub] = useState<SingleValue<{ value: string; label: string }>>();
  const [clubs, setClubs] = useState<
    {
      value: string;
      label: string;
    }[]
  >();
  const [emailError, setEmailError] = useState('');
  const [mobilePhoneError, setMobilePhoneError] = useState('');
  const [inquiryError, setInquiryError] = useState('');

  const emailField = inputFields.find((ip) => ip.key === constants.EMAIL_KEY);
  const mobilePhoneField = inputFields.find((ip) => ip.key === constants.MOBILE_PHONE_KEY);

  useEffect(() => {
    document.title = t('SS_CONTACT_US');
  }, [t('SS_LOCAL')]);

  useEffect(() => {
    fetchClubs();
    document.getElementById('input-MobileNumber')?.focus();
  }, []);

  useEffect(() => {
    const defaultClub = clubs?.find((item) => item.value === membershipDetails.clubId);

    if (defaultClub) {
      setContactClub({
        label: defaultClub.label || '',
        value: defaultClub.value || '',
      });
    }
  }, [clubs]);

  const fetchClubs = async () => {
    try {
      const { data } = await getAllClubs();
      if (data) {
        setClubs(
          data
            .map((item: any) => {
              return { value: item.id, label: item.displayName };
            })
            .sort((club: any, nextClub: any) => (club.label >= nextClub.label ? 1 : -1))
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  const { enqueueSnackbar } = useSnackbar();

  const backToMembership = () => {
    history.push(MEMBERSHIP_URL);
  };

  const customMobilePhoneField = {
    field: { ...mobilePhoneField, maxLength: 25, isRequired: true, isVisible: true, editable: true },
    regexKey: constants.PREFIX_VALIDATION_REGEX.replace('{{PhoneType}}', constants.MOBILE_PHONE),
    invalidMessage: 'SS_PERSONAL_INVALID_MOBILE_PHONE',
    requiredMessage: 'SS_PERSONAL_REQUIRED_MOBILE_PHONE',
    setError: setMobilePhoneError,
  };

  const emailConfig = useMemo(
    () => ({
      field: {
        ...emailField,
        isRequired: true,
        isVisible: true,
        editable: true,
      },
      regexKey: PERSONAL_CONSTANTS.JOL_EMAIL_REGEX,
      invalidMessage: 'SS_PERSONAL_INVALID_EMAIL',
      requiredMessage: 'SS_PERSONAL_REQUIRED_EMAIL',
      setError: setEmailError,
    }),
    [emailField]
  );

  const inquiryConfig = {
    field: {
      maxLength: 500,
      isRequired: true,
      isVisible: true,
      editable: true,
    },
    requiredMessage: 'SS_CONTACT_US_REQUIRED_INQUIRY',
    setError: setInquiryError,
  };

  const handleChangeMethodContact = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMethodContact((event.target as HTMLInputElement).value);
  };

  const handleSubmit = async () => {
    gtag('event', 'Button', {
      event_action: 'CUSubmitContactUs',
      event_label: 'CUSubmitContactUsClicked',
    });
    if (validateFields()) {
      const body = {
        mobileNumber: mobilePhone,
        membershipStatus: t(`${constants.MEMBER_STATUS_CODE}${membershipDetails.memberStatusCode.toUpperCase()}`),
        emailAddress: emailAddress,
        inquiry: inquiry,
        preferredContact: methodContact,
        clubId: contactClub?.value,
      };
      const { isSuccess } = await submitInquiry(membershipDetails.memberId, body);
      if (isSuccess) {
        emitNotify(enqueueSnackbar, t('SS_CONTACT_US'), t('SS_NOTIFICATION_CONTACT_US_SENDING'), 'success');
        history.push(MEMBERSHIP_URL);
      }
    }
  };

  const handleCancel = () => {
    setConfirmBeforeExit(true);
  };

  const handleChangeContactClub = (newValue: SingleValue<{ value: string; label: string }>) => {
    setContactClub(newValue);
  };

  const onValidateField = (newValue: any, configs: any) => {
    const { field, regexKey, invalidMessage, requiredMessage } = configs;

    if (!field || !field.editable) {
      return {
        isValid: true,
        error: '',
      };
    }
    const regexObj = regexs.find((reg) => reg.key === regexKey) || {
      key: '',
      value: '',
    };
    const regex = new RegExp(regexObj.value);
    if (newValue && !regex.test(newValue)) {
      configs.setError(t(invalidMessage));
      return {
        isValid: false,
        error: t(invalidMessage),
      };
    } else if (field.isRequired && !newValue) {
      configs.setError(t(requiredMessage));
      return {
        isValid: false,
        error: t(requiredMessage),
      };
    } else {
      return {
        isValid: true,
        error: '',
      };
    }
  };

  const onBlur = (newValue: any, configs: any) => {
    const { error } = onValidateField(newValue, configs);
    configs.setError(error);
  };

  const onChangeInputValue = (newValue: any, updateValue: (newValue: string) => void, configs: any) => {
    const { error } = onValidateField(newValue, configs);
    configs.setError(error);
    updateValue(newValue);
  };

  const checkFieldErrors = () => !!emailError || !!mobilePhoneError || !!inquiryError;
  const isChangedField = (newValue = '', oldValue = '') => {
    if (!newValue && !oldValue) {
      return false;
    }
    return newValue !== oldValue;
  };

  const checkChangedForm = () => {
    if (membershipDetails) {
      const dirtyEmailAddress = isChangedField(emailAddress, membershipDetails.emailAddress);
      const dirtyMobilePhone = isChangedField(mobilePhone, membershipDetails.mobileNumber);
      const dirtyInquiry = isChangedField(inquiry, '');
      return dirtyEmailAddress || dirtyMobilePhone || dirtyInquiry;
    }
  };

  const checkDisable = () => {
    return !checkChangedForm() || checkFieldErrors();
  };

  const onFilterPhoneRegex = (value: string, onChange: Function, isRequired?: boolean, phoneName?: string) => {
    if (
      phoneName === constants.MOBILE_PHONE &&
      (!customMobilePhoneField.field || !customMobilePhoneField.field.editable)
    ) {
      return true;
    }
    if (phoneName && value) {
      const keyString =
        phoneName === constants.EMERGENCY_NUMBER
          ? constants.PREFIX_VALIDATION_REGEX.replace('{{PhoneType}}', constants.MOBILE_PHONE)
          : constants.PREFIX_VALIDATION_REGEX.replace('{{PhoneType}}', phoneName);
      const keyStringDefault = constants.VALIDATION_REGEX_DEFAULT;
      const filterPhoneObj = regexs.find((ph) => ph.key === keyString || ph.key === keyStringDefault) || {
        key: '',
        value: '',
      };
      onChange(value);

      const regex = new RegExp(filterPhoneObj.value);
      if (!regex.test(value)) {
        if (phoneName === constants.MOBILE_PHONE) {
          setMobilePhoneError(t('SS_PERSONAL_INVALID_MOBILE_PHONE'));
          return false;
        }
      } else {
        phoneName === constants.MOBILE_PHONE && setMobilePhoneError('');
      }
    } else if (phoneName) {
      onChange(value);
      if (isRequired) {
        if (phoneName === constants.MOBILE_PHONE) {
          setMobilePhoneError(t('SS_PERSONAL_REQUIRED_MOBILE_PHONE'));
          return false;
        }
      } else {
        phoneName === constants.MOBILE_PHONE && setMobilePhoneError('');
      }
    } else {
      phoneName === constants.MOBILE_PHONE && setMobilePhoneError('');
      const filterValue = value.replace(/[^-.0-9\s]/g, '').replace(/\s/g, '');
      onChange(filterValue);
    }
    return true;
  };

  const renderMembershipStatus = (status: string) => {
    const memberStatusText = t(`${constants.MEMBER_STATUS_CODE}${status.toUpperCase()}`);
    switch (status) {
      case constants.MC_OK:
        return <span className="ok">{memberStatusText}</span>;
      case constants.MC_SS:
      case constants.MC_BN:
        return <span className="notok">{memberStatusText}</span>;
      default:
        return <span className="neutral">{memberStatusText}</span>;
    }
  };

  const validateFields = (): boolean => {
    const validateEmail = onValidateField(emailAddress, emailConfig);
    const validateInquiry = onValidateField(inquiry, inquiryConfig);
    const isValidMobilePhone = onFilterPhoneRegex(
      mobilePhone,
      setMobilePhone,
      customMobilePhoneField.field.isRequired,
      constants.MOBILE_PHONE
    );
    return validateEmail.isValid && isValidMobilePhone && validateInquiry.isValid;
  };

  if (!clubs) {
    return (
      <div className="contact-us__center">
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className="contact-us__wrapper">
      <div className="contact-us__title">{t('SS_CONTACT_US')}</div>
      <div className="contact-us__content">
        <div className="contact-us__row">
          <span className="contact-us__field--name">{t('SS_CONTACT_US_MEMBERSHIP_NUMBER')}</span>
          <span className="contact-us__field--value">{membershipDetails.memberFirstId}</span>
        </div>
        <div className="contact-us__row">
          <span className="contact-us__field--name">{t('SS_MEMBERSHIP_STATUS')}</span>
          <span className="contact-us__field--value">{renderMembershipStatus(membershipDetails.memberStatusCode)}</span>
        </div>
        <div className="contact-us__row contact-us__row--input">
          <span className="contact-us__field--label">
            {t('SS_PERSONAL_MOBILE_NUMBER')}
            {customMobilePhoneField.field.isRequired && <i className="contact-us__field--label--required">*</i>}
          </span>
          <span className="contact-us__field--input">
            <CustomInput
              label={t('SS_PERSONAL_MOBILE_NUMBER')}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                onFilterPhoneRegex(
                  e.target.value,
                  setMobilePhone,
                  customMobilePhoneField.field.isRequired,
                  constants.MOBILE_PHONE
                )
              }
              value={mobilePhone}
              errorMessage={mobilePhoneError}
              maxLength={customMobilePhoneField.field.maxLength}
              isRequired={customMobilePhoneField.field.isRequired}
              emptyMessage={t('SS_PERSONAL_REQUIRED_MOBILE_PHONE')}
              onBlur={() => onBlur(mobilePhone, customMobilePhoneField)}
              hideLabel
            />
          </span>
        </div>

        <div className="contact-us__row contact-us__row--input">
          <span className="contact-us__field--label">
            {t('SS_CONTACT_US_EMAIL_ADDRESS')}
            {emailConfig.field.isRequired && <i className="contact-us__field--label--required">*</i>}
          </span>
          <span className="contact-us__field--input">
            <CustomInput
              label={t('SS_CONTACT_US_EMAIL_ADDRESS')}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                onChangeInputValue(e.target.value, setEmailAddress, emailConfig)
              }
              value={emailAddress}
              maxLength={emailConfig.field.maxLength || 255}
              isRequired={emailConfig.field.isRequired}
              errorMessage={emailError}
              emptyMessage={t('SS_PERSONAL_REQUIRED_EMAIL')}
              hideLabel
              onBlur={() => onBlur(emailAddress, emailConfig)}
            />
          </span>
        </div>

        <div className="contact-us__row contact-us__row--input">
          <span className="contact-us__field--label">
            {t('SS_CONTACT_US_CONTACT_CLUB')}
            <i className="contact-us__field--label--required">*</i>
          </span>
          <span className="contact-us__field--input">
            <Select
              className="contact-us__field--select"
              options={clubs}
              defaultValue={clubs.filter((option) => option.value === membershipDetails.clubId)[0]}
              onChange={handleChangeContactClub}
              styles={{
                option: (styles, state) => ({
                  ...styles,
                  backgroundColor: state.isSelected ? 'var(--primary-color)' : '',
                  ':hover': {
                    ...styles[':hover'],
                    backgroundColor: state.isSelected ? 'var(--primary-color)' : 'var(--default-hover-color)',
                  },
                }),
              }}
            />
          </span>
        </div>

        <div className="contact-us__row contact-us__row--input">
          <span className="contact-us__field--label">
            {t('SS_CONTACT_US_INQUIRY')}
            {inquiryConfig.field.isRequired && <i className="contact-us__field--label--required">*</i>}
          </span>
          <span className="contact-us__field--textarea">
            <CustomInput
              label={t('SS_CONTACT_US_INQUIRY')}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                onChangeInputValue(e.target.value, setInquiry, inquiryConfig);
              }}
              value={inquiry}
              maxLength={inquiryConfig.field.maxLength || 255}
              placeholder={t('SS_CONTACT_US_INQUIRY_PLACEHOLDER')}
              isRequired={false}
              errorMessage={inquiryError}
              emptyMessage={t('SS_CONTACT_US_REQUIRED_INQUIRY')}
              componentConfig={{ rows: 4 }}
              hideLabel
              multiline
              onBlur={() => onBlur(inquiry, inquiryConfig)}
            />
            <span className="contact-us__field--characters-count">
              {inquiryConfig.field.maxLength - inquiry.length > 1
                ? t('SS_CONTACT_US_CHARACTERS', { count: inquiryConfig.field.maxLength - inquiry.length })
                : t('SS_CONTACT_US_CHARACTER', { count: inquiryConfig.field.maxLength - inquiry.length })}
            </span>
          </span>
        </div>

        <div className="contact-us__row contact-us__row--input">
          <span className="contact-us__field--label">{t('SS_CONTACT_US_METHOD')}</span>
          <span className="contact-us__field--value">
            <RadioGroup name="method" value={methodContact} onChange={handleChangeMethodContact}>
              <FormControlLabel value="phone" control={<Radio />} label={t('SS_CONTACT_US_METHOD_PHONE')} />
              <FormControlLabel value="email" control={<Radio />} label={t('SS_CONTACT_US_METHOD_EMAIL')} />
            </RadioGroup>
          </span>
        </div>

        <div className="contact-us__button-actions">
          <Button className="contact-us__button-actions--save" onClick={handleSubmit} disabled={checkDisable()}>
            {t('SS_SUBMIT')}
          </Button>
          <Button onClick={handleCancel}>{t('SS_CANCEL')}</Button>
        </div>
      </div>
      <ModalConFirm
        cancelLabel={t('SS_NO')}
        confirmLabel={t('SS_YES')}
        confirmAction={backToMembership}
        displayMessage={t('SS_CONTACT_CONFIRM_MESS')}
        openModal={confirmBeforeExit}
        handleOpenModal={setConfirmBeforeExit}
      />
    </div>
  );
};

export default ContactUs;
