import { TextField } from '@material-ui/core';
import classNames from 'classnames';
import { CardIcon, CheckIcon, CloseIcon } from 'components/Icons';
import commonConstants from 'constants/commonContants';
import PERSONAL_CONSTANTS, { VIRTUAL_ADDON_CODES } from 'constants/personalConstant';
import moment from 'moment';
import format from 'number-format.js';
import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTypedSelector } from 'redux/configureStore';
import { replaceToHTMLElement } from 'utility/helpers';
import { PackagePreviewProps, SelectedDataProps } from '../types';

interface PackagePreviewInterface extends PackagePreviewProps {
  setViewDetail: (viewDetail: boolean) => void;
  showCloseButton: boolean;
  setSelectedItem: (data: SelectedDataProps) => void;
  selectedItem: SelectedDataProps | undefined;
}

const PackagePreview: React.FC<PackagePreviewInterface> = ({
  startDate,
  accessLevel,
  commitmentLength,
  previewData,
  basicPlan,
  setViewDetail,
  showCloseButton,
  setSelectedItem,
  selectedItem,
}) => {
  const { t, i18n } = useTranslation();
  const tJOL = i18n.getFixedT(`${i18n.language}-adyen`, 'translations');
  const { regexs } = useTypedSelector((state) => state.personal);
  const { user } = useTypedSelector((state) => state.login);
  const currencyCode = regexs.find((regex) => regex.key === PERSONAL_CONSTANTS.CURRENCY_REGEX) || {
    key: '',
    value: '',
  };
  const currencyFormat = regexs.find((regex) => regex.key === PERSONAL_CONSTANTS.CURRENCY_FORMAT_REGEX) || {
    key: '',
    value: '',
  };
  const [promoCode, setPromoCode] = useState({ value: selectedItem?.promotionCode || '' });

  const formatDate = 'ddd, DD MMM YYYY';
  const brandCode = useMemo(() => user.profile?.brandCode || '', [user]);

  const applyPromoError = previewData.promotions.find((item) => ['Promotion_Is_Invalid'].includes(item.message));
  const applyPromoSuccess = previewData.promotions.find((item) => item.isAvailableOnline && item.isSuccess);

  const handleApplyPromoCode = () => {
    setSelectedItem({
      ...selectedItem,
      promotionCode: promoCode.value,
      needToCallPreview: true,
    } as SelectedDataProps);
  };

  const handleRemovePromoCode = () => {
    setSelectedItem({
      ...selectedItem,
      promotionCode: null,
      needToCallPreview: true,
    } as SelectedDataProps);
  };

  useEffect(() => {
    if (previewData.promotions.length === 0 || applyPromoError) {
      setPromoCode({ value: '' });
    }
  }, [previewData]);

  return (
    <div className="order">
      <div className="order__heading">
        <div className="order__heading__title">{t('SS_UPGRADE_MEMBERSHIP_YOUR_PLAN_DETAILS')}</div>
        <div className="order__heading__detail">
          <div className="order__heading__detail__total-payment row-detail">
            <div className="row-detail__title">
              {t('SS_UPGRADE_MEMBERSHIP_TO_PAY_THIS_MONTH')}
              <div className="row-detail__title--note">{t('SS_UPGRADE_MEMBERSHIP_INCLUDE_OF_VAT')}</div>
            </div>
            <div className="row-detail__price">
              {t('SS_UPGRADE_MEMBERSHIP_MONEY_FORMAT', {
                currency: currencyCode.value,
                amount: previewData && format(currencyFormat.value, previewData.paynow.amount),
                perMonth: '',
              })}
            </div>
          </div>
          <div className="order__heading__detail__monthly-payment row-detail">
            <div className="row-detail__title">
              {t('SS_UPGRADE_MEMBERSHIP_MONTHLY_RECURRING_PAYMENT')}
              <div className="row-detail__title--note">{t('SS_UPGRADE_MEMBERSHIP_INCLUDE_OF_VAT')}</div>
            </div>
            <div className="row-detail__price">
              {t('SS_UPGRADE_MEMBERSHIP_MONEY_FORMAT', {
                currency: currencyCode.value,
                amount: previewData && format(currencyFormat.value, previewData.recurring.amount),
                perMonth: '',
              })}
            </div>
          </div>
          <div className="order__heading__detail__button-payment">
            <span className="order__heading__detail__button-payment__icon">
              <CardIcon />
            </span>
            <div>{t('SS_UPGRADE_MEMBERSHIP_PAYABLE_CREDIT_CARD_ONLY')}</div>
          </div>
        </div>
      </div>
      <div className="order__content">
        <div className="order__content__location">
          <div className="order__content__location__title">
            <p>{t('SS_UPGRADE_MEMBERSHIP_LOCATION')}</p>
            <div className="order__content__location__title--strong">{basicPlan.club.name}</div>
          </div>
          <div className="order__content__location__date">
            <p>{t('SS_UPGRADE_MEMBERSHIP_START_DATE')}</p>
            <div className="order__content__location__date--strong">{moment(startDate).format(formatDate)}</div>
          </div>
        </div>
        <div className="order__content__detail">
          <div className="order__content__detail__item">
            <div>
              {t('SS_UPGRADE_MEMBERSHIP_YOUR_PLAN_INCLUDES')}
              <div
                dangerouslySetInnerHTML={{
                  __html: replaceToHTMLElement(tJOL(basicPlan.shortDescription?.key)),
                }}
              />
              <div>{basicPlan.club.name}</div>
            </div>
            <div>
              {t('SS_UPGRADE_MEMBERSHIP_MONEY_FORMAT', {
                currency: currencyCode.value,
                amount: format(currencyFormat.value, basicPlan.recurring.amount),
                perMonth: '/mth',
              })}
            </div>
          </div>
          <div className="order__content__detail__item">
            <div>
              {t('SS_UPGRADE_MEMBERSHIP_YOUR_CLASSES_INCLUDES')}
              {previewData &&
                previewData.addonClasses.map((item: any) => <div key={`classes-includes-${item.id}`}>{item.name}</div>)}
            </div>
            <div>
              {(previewData &&
                previewData.addonClasses.reduce((total, item) => total + item.amount.recurring, 0) &&
                t('SS_UPGRADE_MEMBERSHIP_MONEY_FORMAT', {
                  currency: currencyCode.value,
                  amount: format(
                    currencyFormat.value,
                    previewData.addonClasses.reduce((total, item) => total + item.amount.recurring, 0)
                  ),
                  perMonth: '',
                })) ||
                t('SS_UPGRADE_MEMBERSHIP_INCLUDED')}
            </div>
          </div>
          <div className="order__content__detail__item">
            <div>
              {t('SS_UPGRADE_MEMBERSHIP_ACCESS_LEVEL')}
              <div
                dangerouslySetInnerHTML={{
                  __html: replaceToHTMLElement(tJOL(accessLevel.name)),
                }}
              />
            </div>
            <div>
              {(accessLevel.recurring.amount &&
                t('SS_UPGRADE_MEMBERSHIP_MONEY_FORMAT', {
                  currency: currencyCode.value,
                  amount: format(currencyFormat.value, accessLevel.recurring.amount),
                  perMonth: '',
                })) ||
                t('SS_UPGRADE_MEMBERSHIP_INCLUDED')}
            </div>
          </div>

          <div className="order__content__detail__item">
            <div>
              {t('SS_UPGRADE_MEMBERSHIP_COMMITMENT_LENGTH')}
              <div>{commitmentLength.name}</div>
            </div>
            <div>
              {(commitmentLength.recurring.amount &&
                t('SS_UPGRADE_MEMBERSHIP_MONEY_FORMAT', {
                  currency: currencyCode.value,
                  amount: format(currencyFormat.value, commitmentLength.recurring.amount),
                  perMonth: '',
                })) ||
                t('SS_UPGRADE_MEMBERSHIP_INCLUDED')}
            </div>
          </div>
          {previewData && previewData.recurring.addonFees.some((item) => !item.isAddonClass) && (
            <div className="order__content__detail__item">
              <div>
                {t('SS_UPGRADE_MEMBERSHIP_RECURRING_ADD-ONS')}
                {previewData.recurring.addonFees.map(
                  (item) => !item.isAddonClass && <div key={`addon-recurring-${item.addonId}`}>{item.addonName}</div>
                )}
              </div>
              <div>
                {t('SS_UPGRADE_MEMBERSHIP_MONEY_FORMAT', {
                  currency: currencyCode.value,
                  amount: format(
                    currencyFormat.value,
                    previewData.recurring.addonFees.reduce(
                      (total, item): any => (!item.isAddonClass ? total + item.amount : total),
                      0
                    )
                  ),
                  perMonth: '/mth',
                })}
              </div>
            </div>
          )}
        </div>
        <div className="order__divide"></div>
        <div className="order__content__total">
          {applyPromoSuccess && (
            <div className="order__content__total__original">
              <div>
                <div className="order__content__total__original__title">
                  <div>
                    {t('SS_UPGRADE_MEMBERSHIP_TOTAL_MONTHLY_RECURRING_PAYMENT')}
                    <div>{t('SS_UPGRADE_MEMBERSHIP_INCLUDE_OF_VAT')}</div>
                  </div>
                </div>
                <div>
                  {t('SS_UPGRADE_MEMBERSHIP_MONEY_FORMAT', {
                    currency: currencyCode.value,
                    amount: previewData && format(currencyFormat.value, previewData.recurring.originalAmount),
                    perMonth: '',
                  })}
                </div>
              </div>
            </div>
          )}
          {applyPromoSuccess && !!previewData.recurring.discountAmount && (
            <div className="order__content__detail__item">
              <div>
                {t('SS_UPGRADE_MEMBERSHIP_PROMO_CODE_DISCOUNT')}
                <div>
                  {previewData &&
                    (previewData.recurring.addonFees.find((item) => item?.promotion)?.promotion.code ||
                      previewData.recurring.packageFees.find((item) => item?.promotion)?.promotion.code)}
                </div>
              </div>
              <div>
                {previewData && (
                  <>
                    -
                    {t('SS_UPGRADE_MEMBERSHIP_MONEY_FORMAT', {
                      currency: currencyCode.value,
                      amount: format(currencyFormat.value, previewData.recurring.discountAmount),
                      perMonth: '',
                    })}
                  </>
                )}
              </div>
            </div>
          )}
          <div className="order__content__total__new">
            <div>
              <div className="order__content__total__new__title">
                <div>
                  {t('SS_UPGRADE_MEMBERSHIP_TOTAL_MONTHLY_RECURRING_PAYMENT')}
                  <div>{t('SS_UPGRADE_MEMBERSHIP_INCLUDE_OF_VAT')}</div>
                </div>
                <div>
                  {t('SS_UPGRADE_MEMBERSHIP_FIRST_RECURRING_BILLING')}
                  {` - <${moment(previewData?.firstBilling.startDate).format(formatDate)}>`}
                </div>
              </div>
              <div>
                {t('SS_UPGRADE_MEMBERSHIP_MONEY_FORMAT', {
                  currency: currencyCode.value,
                  amount: previewData && format(currencyFormat.value, previewData.recurring.amount),
                  perMonth: '',
                })}
              </div>
            </div>
          </div>
        </div>

        {previewData && previewData.addons.length > 0 && (
          <div className="order__content__detail order__content__detail--no-border">
            <div className="order__content__detail__title">{t('SS_UPGRADE_MEMBERSHIP_ADD-ONS')}</div>
            {previewData.addons.map((item) => (
              <div className="order__content__detail__item" key={`addons-${item.id}`}>
                <div>
                  <div>{item.name}</div>
                </div>
                <div>
                  <div>
                    {t('SS_UPGRADE_MEMBERSHIP_MONEY_FORMAT', {
                      currency: currencyCode.value,
                      amount: format(currencyFormat.value, item.amount.payNow),
                      perMonth: '',
                    })}
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
        <div className="order__content__detail order__content__detail--no-border">
          <div className="order__content__detail__title">{t('SS_UPGRADE_MEMBERSHIP_PRO_RATA_PACKAGE_PLAN')}</div>
          <div className="order__content__detail__item">
            <div>
              <div
                dangerouslySetInnerHTML={{
                  __html: replaceToHTMLElement(tJOL(basicPlan.shortDescription?.key)),
                }}
              />
            </div>
            <div>
              <div>
                {t('SS_UPGRADE_MEMBERSHIP_MONEY_FORMAT', {
                  currency: currencyCode.value,
                  amount:
                    previewData &&
                    format(
                      currencyFormat.value,
                      previewData.paynow.packageFees.reduce(
                        (total, item): any => (item.feeCode === 'PR' ? total + item.originalAmount : total),
                        0
                      )
                    ),
                  perMonth: '',
                })}
              </div>
            </div>
          </div>
          {previewData &&
            previewData.paynow.addonFees.map(
              (item) =>
                item.feeCode === 'PR' && (
                  <div key={item.addonId} className="order__content__detail__item">
                    <div>
                      <div>{item.addonName}</div>
                    </div>
                    <div>
                      <div>
                        {t('SS_UPGRADE_MEMBERSHIP_MONEY_FORMAT', {
                          currency: currencyCode.value,
                          amount: format(currencyFormat.value, item.originalAmount),
                          perMonth: '',
                        })}
                      </div>
                    </div>
                  </div>
                )
            )}
        </div>
        <div className="order__content__prorata">
          <div className="row-detail">
            <div className="row-detail__title">
              {t('SS_UPGRADE_MEMBERSHIP_PRO_RATA_FIRST_MONTH_PAYMENT')}
              <div className="row-detail__title--note">{t('SS_UPGRADE_MEMBERSHIP_INCLUDE_OF_VAT')}</div>
            </div>
            <div className="row-detail__price">
              {t('SS_UPGRADE_MEMBERSHIP_MONEY_FORMAT', {
                currency: currencyCode.value,
                amount:
                  previewData &&
                  format(
                    currencyFormat.value,
                    previewData.paynow.packageFees.reduce(
                      (total, item): any => (item.feeCode === 'PR' ? total + item.originalAmount : total),
                      0
                    ) +
                    previewData.paynow.addonFees.reduce(
                      (total, item): any => (item.feeCode === 'PR' ? total + item.originalAmount : total),
                      0
                    )
                  ),
                perMonth: '',
              })}
            </div>
          </div>

          <div className="order__content__total__new order__content__total__new--gray">
            <div>
              <div className="order__content__total__new__title">
                <div>
                  {t('SS_UPGRADE_MEMBERSHIP_TOTAL_PAYMENT')}
                  <div>{t('SS_UPGRADE_MEMBERSHIP_INCLUDE_OF_VAT')}</div>
                </div>
              </div>
              <div>
                {t('SS_UPGRADE_MEMBERSHIP_MONEY_FORMAT', {
                  currency: currencyCode.value,
                  amount: previewData && format(currencyFormat.value, previewData.paynow.originalAmount),
                  perMonth: '',
                })}
              </div>
            </div>
          </div>
          {applyPromoSuccess && !!previewData.paynow.discountAmount && (
            <div className="order__content__detail__item">
              <div>
                {t('SS_UPGRADE_MEMBERSHIP_PROMO_CODE_DISCOUNT')}
                <div>
                  {previewData &&
                    (previewData.paynow.addonFees.find((item) => item?.promotion)?.promotion.code ||
                      previewData.paynow.packageFees.find((item) => item?.promotion)?.promotion.code)}
                </div>
              </div>
              <div>
                {previewData && (
                  <>
                    -
                    {t('SS_UPGRADE_MEMBERSHIP_MONEY_FORMAT', {
                      currency: currencyCode.value,
                      amount: format(currencyFormat.value, previewData.paynow.discountAmount),
                      perMonth: '',
                    })}
                  </>
                )}
              </div>
            </div>
          )}
        </div>
        <div className="order__content__totally">
          <div className="row-detail">
            <div className="row-detail__title">
              {t('SS_UPGRADE_MEMBERSHIP_TO_PAY_THIS_MONTH')}
              <div className="row-detail__title--note">{t('SS_UPGRADE_MEMBERSHIP_INCLUDE_OF_VAT')}</div>
            </div>
            <div className="row-detail__price">
              {t('SS_UPGRADE_MEMBERSHIP_MONEY_FORMAT', {
                currency: currencyCode.value,
                amount: previewData && format(currencyFormat.value, previewData.paynow.amount),
                perMonth: '',
              })}
            </div>
          </div>
        </div>
        <div
          className={classNames({
            'order__promo-code': true,
            'order__promo-code--applied': applyPromoSuccess,
          })}
        >
          <div>
            <TextField
              placeholder={t('SS_UPGRADE_MEMBERSHIP_ENTER_PROMO_CODE')}
              value={promoCode.value}
              onChange={(event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                setPromoCode({ value: event.target.value });
              }}
              disabled={applyPromoSuccess}
            />
            {previewData.promotions.find((item) => item.isAvailableOnline && item.isSuccess) ? (
              <button onClick={handleRemovePromoCode}>
                <CloseIcon /> {t('SS_UPGRADE_MEMBERSHIP_REMOVE_PROMO_CODE')}
              </button>
            ) : (
              <button onClick={handleApplyPromoCode}>{t('SS_UPGRADE_MEMBERSHIP_APPLY_PROMO_CODE')}</button>
            )}
            <div
              className={classNames({
                'status-message': true,
                'status-message--cf': brandCode === commonConstants.CF_BRAND,
                'status-message--error': applyPromoError,
                'status-message--success': applyPromoSuccess,
              })}
            >
              {applyPromoError && t('SS_UPGRADE_MEMBERSHIP_PROMO_CODE_ERROR')}
              {applyPromoSuccess && (
                <>
                  <CheckIcon /> {t('SS_UPGRADE_MEMBERSHIP_PROMO_CODE_SUCCESS')}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      {showCloseButton && (
        <span className="order__close" onClick={() => setViewDetail(false)}>
          <CloseIcon />
        </span>
      )}
    </div>
  );
};

export default PackagePreview;
