import {
  getArrearInfoService,
  getBillingStatusService,
  getConfigService,
  getMembershipService,
  getPackagePlansService,
} from 'services';

import { Middleware } from 'redux';
import {
  membershipActions,
  notificationActions,
  paymentActions,
  personalActions,
} from 'redux/actionCreators';
import { setConnectedToSignalR } from 'redux/actionCreators/notification';
import { getBillingStatusSuccess } from 'redux/actionCreators/system';

import { notificationActionTypes as actions } from '../actionTypes';

import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';

declare const window: Record<string, Record<string, string>>;

const MEMBER_INFORMATION_UPDATED_RECEIVED = 'MemberInformationUpdatedReceived';
const MEMBER_PAYMENT_UPDATED_RECEIVED = 'MemberPaymentUpdatedReceived';
const MEMBER_PAYMENT_MADE_RECEIVED = 'MemberPaymentMadeReceived';
const MEMBER_REGISTER_TO_GROUP = 'RegisterToGroup';
const MEMBER_REMOVE_FROM_GROUP = 'RemoveFromGroup';
const MEMBER_ADDON_ADDED_RECEIVED = 'MemberAddonAddedReceived';
const MEMBER_ADDON_CANCELED_RECEIVED = 'MemberAddonCanceledReceived';
const MEMBER_MEMBERSHIP_UPGRADED = 'MemberMembershipUpgradedReceived';
const MEMBER_INQUIRY_SUBMITTED = 'MemberInquirySubmittedReceived';
const MAINTENANCE_MODE_UPDATED = 'MaintenanceModeUpdatedReceived';
const BILLING_MODE_UPDATED = 'BillingModeUpdatedReceived';

const { setNotification } = notificationActions;
const { getPackagePlansSuccess, getMembershipSuccess } = membershipActions;
const { getArrearInformationSuccess } = paymentActions;
const { getConfigSuccess } = personalActions;

const connectToGroup = (connection: HubConnection, groupList: string[]) => {
  groupList.forEach((group) => {
    connection.send(MEMBER_REGISTER_TO_GROUP, group);
  });
};

const disconnectFromGroup = (connection: HubConnection, groupList: string[]) => {
  groupList.forEach((group) => {
    connection.send(MEMBER_REMOVE_FROM_GROUP, group);
  });
};

const signalRMiddleware: Middleware<{}, any> = ({ dispatch, getState }) => {
  const connection = new HubConnectionBuilder()
    .withUrl(`${window._env.SS_API_DEV_URL}/ntf/notification`)
    .withAutomaticReconnect()
    .build();
  connection
    .start()
    .then(() => {
      // Connect to the group after 1s
      dispatch(setConnectedToSignalR());
      setImmediate(() => {
        connection.on(
          MEMBER_INFORMATION_UPDATED_RECEIVED,
          async (memberId, obj: { isSuccess: boolean; message: string; messageId: string }) => {
            dispatch(
              setNotification({
                title: 'SS_NOTIFICATION_PERSONAL_DETAILS',
                type: 'normal',
                message: 'SS_NOTIFICATION_PERSONAL_INFORMATION_UPDATED',
                id: obj.messageId,
                isSuccess: obj.isSuccess,
              })
            );

            const { data: memberShipDetails } = await getMembershipService();
            const { data: packagePlans } = await getPackagePlansService(memberId);

            dispatch(
              getPackagePlansSuccess({
                brands: packagePlans.accessClub?.brands,
                clusterName: packagePlans.clusterName,
                onlinePaymentMethod: packagePlans.onlinePaymentMethod,
                addons: packagePlans.addons,
                totalRecurringFee: packagePlans.totalRecurringFee,
              })
            );
            dispatch(getMembershipSuccess({ ...memberShipDetails, addons: packagePlans.addons }));
          }
        );
        connection.on(
          MEMBER_PAYMENT_UPDATED_RECEIVED,
          async (memberId, obj: { isSuccess: boolean; message: string; messageId: string }) => {
            dispatch(
              setNotification({
                title: 'SS_NOTIFICATION_PAYMENT_AND_CREDITS',
                type: 'normal',
                message: 'SS_NOTIFICATION_PAYMENT_UPDATED_SUCCESS',
                id: obj.messageId,
                isSuccess: obj.isSuccess,
              })
            );

            const { data: memberShipDetails } = await getMembershipService();
            const { data: packagePlans } = await getPackagePlansService(memberId);

            dispatch(
              getPackagePlansSuccess({
                brands: packagePlans.accessClub?.brands,
                clusterName: packagePlans.clusterName,
                onlinePaymentMethod: packagePlans.onlinePaymentMethod,
                addons: packagePlans.addons,
                totalRecurringFee: packagePlans.totalRecurringFee,
              })
            );
            dispatch(getMembershipSuccess({ ...memberShipDetails, addons: packagePlans.addons }));
          }
        );
        connection.on(
          MEMBER_PAYMENT_MADE_RECEIVED,
          async (memberId, obj: { isSuccess: boolean; message: string; messageId: string }) => {
            dispatch(
              setNotification({
                title: 'SS_NOTIFICATION_PAYMENT_AND_CREDITS',
                type: 'normal',
                message: obj.message || 'SS_NOTIFICATION_PAYMENT_MAKE_PAYMENT_SUCCESS',
                id: obj.messageId,
                isSuccess: obj.isSuccess,
              })
            );

            const { data: memberShipDetails } = await getMembershipService();
            const { data: packagePlans } = await getPackagePlansService(memberId);
            const arrears = await getArrearInfoService(memberId);
            dispatch(
              getPackagePlansSuccess({
                brands: packagePlans.accessClub?.brands,
                clusterName: packagePlans.clusterName,
                onlinePaymentMethod: packagePlans.onlinePaymentMethod,
                addons: packagePlans.addons,
                totalRecurringFee: packagePlans.totalRecurringFee,
              })
            );
            dispatch(getMembershipSuccess({ ...memberShipDetails, addons: packagePlans.addons }));
            dispatch(getArrearInformationSuccess(arrears.data));
          }
        );
        connection.on(
          MEMBER_ADDON_ADDED_RECEIVED,
          async (memberId, obj: { isSuccess: boolean; message: string; messageId: string }) => {
            dispatch(
              setNotification({
                title: 'SS_NOTIFICATION_MEMBERSHIP',
                type: 'addAddon',
                message: obj.message,
                id: obj.messageId,
                isSuccess: obj.isSuccess,
              })
            );

            const { data: memberShipDetails } = await getMembershipService();
            const { data: packagePlans } = await getPackagePlansService(memberId);

            dispatch(
              getPackagePlansSuccess({
                brands: packagePlans.accessClub?.brands,
                clusterName: packagePlans.clusterName,
                onlinePaymentMethod: packagePlans.onlinePaymentMethod,
                addons: packagePlans.addons,
                totalRecurringFee: packagePlans.totalRecurringFee,
              })
            );
            dispatch(getMembershipSuccess({ ...memberShipDetails, addons: packagePlans.addons }));
          }
        );
        connection.on(
          MEMBER_ADDON_CANCELED_RECEIVED,
          async (memberId, obj: { isSuccess: boolean; message: string; messageId: string }) => {
            dispatch(
              setNotification({
                title: 'SS_NOTIFICATION_MEMBERSHIP',
                type: 'cancelAddon',
                message: obj.message,
                id: obj.messageId,
                isSuccess: obj.isSuccess,
              })
            );

            const { data: memberShipDetails } = await getMembershipService();
            const { data: packagePlans } = await getPackagePlansService(memberId);

            dispatch(
              getPackagePlansSuccess({
                brands: packagePlans.accessClub?.brands,
                clusterName: packagePlans.clusterName,
                onlinePaymentMethod: packagePlans.onlinePaymentMethod,
                addons: packagePlans.addons,
                totalRecurringFee: packagePlans.totalRecurringFee,
              })
            );
            dispatch(getMembershipSuccess({ ...memberShipDetails, addons: packagePlans.addons }));
          }
        );
        connection.on(
          MEMBER_MEMBERSHIP_UPGRADED,
          async (memberId, obj: { isSuccess: boolean; message: string; messageId: string }) => {
            dispatch(
              setNotification({
                title: 'SS_NOTIFICATION_MEMBERSHIP',
                type: 'normal',
                message: obj.message || 'SS_NOTIFICATION_MEMBER_MEMBERSHIP_UPGRADED_SUCCESS',
                id: obj.messageId,
                isSuccess: obj.isSuccess,
              })
            );

            const { data: memberShipDetails } = await getMembershipService();
            const { data: packagePlans } = await getPackagePlansService(memberId);

            dispatch(
              getPackagePlansSuccess({
                brands: packagePlans.accessClub?.brands,
                clusterName: packagePlans.clusterName,
                onlinePaymentMethod: packagePlans.onlinePaymentMethod,
                addons: packagePlans.addons,
                totalRecurringFee: packagePlans.totalRecurringFee,
              })
            );
            dispatch(getMembershipSuccess({ ...memberShipDetails, addons: packagePlans.addons }));
          }
        );

        connection.on(
          MEMBER_INQUIRY_SUBMITTED,
          async (memberId, obj: { isSuccess: boolean; message: string; messageId: string }) => {
            dispatch(
              setNotification({
                title: 'SS_CONTACT_US',
                type: 'normal',
                message: 'SS_NOTIFICATION_CONTACT_US_SUBMITTED',
                id: obj.messageId,
                isSuccess: obj.isSuccess,
              })
            );
          }
        );
        connection.on(
          MAINTENANCE_MODE_UPDATED,
          async (obj: { isSuccess: boolean; message: string; messageId: string }) => {
            const { data } = await getConfigService();
            if (data.data) {
              dispatch(getConfigSuccess(data.data));
            }
          }
        );
        connection.on(BILLING_MODE_UPDATED, async (obj: { isSuccess: boolean; message: string; messageId: string }) => {
          const { data } = await getBillingStatusService();
          if (data) {
            dispatch(getBillingStatusSuccess(data.isBillingNow));
          }
        });
      });
    })
    .catch((error) => console.log(error));

  return (next) => (action) => {
    switch (action.type) {
      case actions.SEND_NOTIFICATION: {
        connection.send(action.payload);
        return;
      }
      case actions.CONNECT_TO_GROUP: {
        if (action.groupList) connectToGroup(connection, action.groupList);
        return;
      }
      case actions.DISCONNECT_FROM_GROUP: {
        if (action.groupList) disconnectFromGroup(connection, action.groupList);
        connection.stop();
        return;
      }
    }
    return next(action);
  };
};

export default signalRMiddleware;
