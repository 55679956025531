import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import {
  Checkbox,
  FormControl,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormGroup,
  Grid,
} from '@material-ui/core';
import LazyLoad from 'react-lazyload';
import format from 'number-format.js';

import { useTypedSelector } from 'redux/configureStore';
import PERSONAL_CONSTANTS, { SESSION_STORAGE_NAMES } from 'constants/personalConstant';
import { useDeviceCheck } from 'utility/screenDetect';
import { getIconUrl } from 'utility/helpers';
import { AddonInterface } from 'components/Addon/types';
import { ANSWER } from 'constants/commonContants';

interface ListAddOnPaymentProps {
  showErrorTnC: boolean;
  setShowErrorTnc: Function;
  termsAndConditionsErrorRef: any;
  addons: AddonInterface[];
  subTotal: number;
  recurringAmount: number;
}

const AddOnTableMobile = (props: any) => {
  const { addon, dateFormat, currencyFormat } = props;
  const { t } = useTranslation();
  const [openAddon, setOpenAddon] = useState(false);
  const formatDate = moment(addon.actionDate).format(dateFormat);
  return (
    <LazyLoad
      height={100}
      offset={[-100, 100]}
      placeholder={
        <Accordion
          classes={{ root: 'addon-details', expanded: 'addon-details__expanded' }}
          expanded={false}
          onChange={() => { }}
        >
          <AccordionSummary
            aria-controls="panel1a-content"
            id="panel1a-header"
            classes={{ root: 'addons-root', content: 'addons-summary' }}
          ></AccordionSummary>
        </Accordion>
      }
    >
      <Accordion
        classes={{ root: 'addon-details', expanded: 'addon-details__expanded' }}
        expanded={openAddon}
        onChange={(e, expanded) => setOpenAddon(expanded)}
      >
        <AccordionSummary
          expandIcon={<img src={getIconUrl('ArrowDown')} alt="arrow-up" />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          classes={{ root: 'addons-root', content: 'addons-summary' }}
        >
          {addon.name}
        </AccordionSummary>
        <AccordionDetails>
          <div className="addon-details__detail-group">
            <div className="addon-details__detail-field">
              <span>{t('SS_PAY_ADDONS_DATE')}</span>
              <strong>{formatDate}</strong>
            </div>
            <div className="addon-details__detail-field">
              <span>{t('SS_PAY_ADDONS_PRODUCT_NAME')}</span>
              <strong>{addon.name}</strong>
            </div>
            <div className="addon-details__detail-field">
              <span>{t('SS_PAY_ADDONS_AMOUNT')}</span>
              <strong>{format(currencyFormat, addon.amount.originalAmount)}</strong>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </LazyLoad>
  );
};

const AddonsTable = (props: any) => {
  const { addons, dateFormat, currencyFormat, subTotal } = props;
  const { t } = useTranslation();
  return (
    <TableContainer component={Paper} classes={{ root: 'table-container' }}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>{t('SS_PAY_ADDONS_DATE')}</TableCell>
            <TableCell align="left">{t('SS_PAY_ADDONS_PRODUCT_NAME')}</TableCell>
            <TableCell align="left">{t('SS_PAY_ADDONS_AMOUNT')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {addons.map((tr: any, index: number): any => {
            const formatDate = moment(tr.actionDate).format(dateFormat);
            return (
              <TableRow key={`transaction-${index}`}>
                <TableCell width="20%">{formatDate}</TableCell>
                <TableCell align="left" width="50%">
                  {tr.name}
                </TableCell>
                <TableCell align="left" width="20%">
                  {format(currencyFormat, tr.amount.originalAmount)}
                </TableCell>
              </TableRow>
            );
          })}

          <TableRow>
            <TableCell className="list-addon-payment__no-border" />
            <TableCell colSpan={1} className="list-addon-payment__no-border">
              <span className="list-addon-payment__subtotal">{t('SS_PAY_ADDONS_TOTAL')}</span>
            </TableCell>
            <TableCell align="left" className="list-addon-payment__no-border">
              {format(currencyFormat, subTotal)}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const RecurringAddOnList = (props: any) => {
  const { currencyFormat, recurringAmount, recurringAddons } = props;
  const [openAddon, setOpenAddon] = useState(false);
  const { t } = useTranslation();
  return (
    <Accordion
      classes={{ root: 'recurring-addon', expanded: 'recurring-addon__expanded' }}
      expanded={openAddon}
      onChange={(e, expanded) => setOpenAddon(expanded)}
    >
      <AccordionSummary
        expandIcon={<img src={getIconUrl('ArrowDown')} alt="arrow-up" />}
        aria-controls="panel1a-content"
        classes={{ root: 'recurring-addon__root', content: 'recurring-addon__summary' }}
      >
        {t('SS_PAY_ADDONS_DETAILS_RECURRING')}
        <span>
          {format(currencyFormat.value, recurringAmount)}/{t('SS_MEMBERSHIP_BY_ADDON_MONTH')}
        </span>
      </AccordionSummary>
      <AccordionDetails classes={{ root: 'recurring-addon__detail-root' }}>
        <div className="recurring-addon__detail-group">
          {recurringAddons.map((addon: AddonInterface) => (
            <div className="recurring-addon__detail-field" key={addon.id}>
              <span>{addon.name}</span>
              <span>
                {format(currencyFormat.value, addon.amount.recurring)}/{t('SS_MEMBERSHIP_BY_ADDON_MONTH')}
              </span>
            </div>
          ))}
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

export const ListAddOnPayment = (props: ListAddOnPaymentProps) => {
  const [, , isMobile] = useDeviceCheck();
  const { t } = useTranslation();
  const { showErrorTnC, setShowErrorTnc, termsAndConditionsErrorRef, addons, subTotal, recurringAmount } = props;
  const { membershipDetails } = useTypedSelector((state) => state.membership);
  const [agreeTnc, setAgreeTnC] = useState(sessionStorage.getItem(SESSION_STORAGE_NAMES.AGREE_TNC) === ANSWER.YES);

  const regexs = useTypedSelector((state) => state.personal.regexs);
  const recurringAddons = useMemo(() => addons.filter((item) => item.amount.recurring > 0), [addons.length]);
  const dateFormat = regexs.find((re) => re.key === PERSONAL_CONSTANTS.DATE_REGEX) || {
    key: '',
    value: _env.DATE_FORMAT,
  };

  const currencyFormat = regexs.find((re) => re.key === PERSONAL_CONSTANTS.CURRENCY_FORMAT_REGEX) || {
    key: '',
    value: '',
  };

  const handleTermsAndConditions = (e: any) => {
    setAgreeTnC(e.target.checked);
    sessionStorage.setItem(SESSION_STORAGE_NAMES.AGREE_TNC, e.target.checked ? ANSWER.YES : ANSWER.NO);
    if (e.target.checked) {
      setShowErrorTnc(false);
    }
  };

  const renderContent = () => {
    return (
      addons.length > 0 && (
        <>
          {renderAddonsTable()}
          {isMobile && (
            <div className="addon-payment__total">
              <span>{t('SS_PAY_ADDONS_TOTAL')}</span>
              <span>{format(currencyFormat.value, subTotal)}</span>
            </div>
          )}
          <FormGroup className="addon-payment__group">
            <Grid container alignItems="center">
              <Grid item sm={9} className="addon-payment__term_conditions addon-payment__term_conditions--margin">
                <FormControl>
                  <Checkbox name="checkTermAndCondition" onChange={handleTermsAndConditions} checked={agreeTnc} />
                  <span
                    className="addon-payment__term_conditions--link"
                    dangerouslySetInnerHTML={{
                      __html: t('SS_PAYMENT_TERM_AND_CONDITIONS', {
                        termsConditions: membershipDetails?.endPoints?.addonTermsAndConditionUrl || '#',
                      }),
                    }}
                  />
                </FormControl>
                <div ref={termsAndConditionsErrorRef} className="addon-payment__term_conditions--error">
                  {showErrorTnC && t('SS_PAYMENT_TERM_AND_CONDITIONS_ERROR')}
                </div>
              </Grid>
            </Grid>
          </FormGroup>

          {recurringAddons.length > 0 && (
            <div className="addon-payment__details">
              <span>{t('SS_PAY_ADDONS_DETAILS_TITLE')}</span>
              <div className="addon-payment__details__row">
                <span>{t('SS_PAY_ADDONS_DETAILS_START_DATE')}</span>
                <span>
                  {t('SS_PAY_ADDONS_DETAILS_TODAY')}: <strong>{moment().format(dateFormat.value.toUpperCase())}</strong>
                </span>
              </div>
              {(isMobile && (
                <RecurringAddOnList
                  currencyFormat={currencyFormat}
                  recurringAmount={recurringAmount}
                  recurringAddons={recurringAddons}
                />
              )) || (
                  <div className="addon-payment__details__row">
                    <span>{t('SS_PAY_ADDONS_DETAILS_RECURRING')}</span>
                    <span>
                      {format(currencyFormat.value, recurringAmount)}/{t('SS_MEMBERSHIP_BY_ADDON_MONTH')}
                    </span>
                    <div className="addon-payment__details__row--sub">
                      {recurringAddons.map((addon) => (
                        <div className="addon-payment__details__row" key={addon.id}>
                          <span>{addon.name}</span>
                          <span>
                            {format(currencyFormat.value, addon.amount.recurring)}/{t('SS_MEMBERSHIP_BY_ADDON_MONTH')}
                          </span>
                        </div>
                      ))}
                    </div>
                  </div>
                )}

              <div className="addon-payment__details__row">
                <span>{t('SS_PAY_ADDONS_DETAILS_FIRST_RECURRING_BILLING_DATE')}</span>
                <span>{moment(membershipDetails.billingDateAddons).format(dateFormat.value.toUpperCase())}</span>
              </div>
            </div>
          )}
        </>
      )
    );
  };
  const renderAddonsTable = () => {
    return isMobile ? (
      addons.map((addon: any, index: any) => (
        <AddOnTableMobile
          key={addon.id}
          addon={addon}
          dateFormat={dateFormat.value.toUpperCase()}
          currencyFormat={currencyFormat.value}
          subTotal={subTotal}
        />
      ))
    ) : (
      <AddonsTable
        addons={addons}
        dateFormat={dateFormat.value.toUpperCase()}
        currencyFormat={currencyFormat.value}
        subTotal={subTotal}
      />
    );
  };

  return (
    <div className="list-addon-payment-container">
      {addons.length > 0 && <div className="list-addon-payment__title">{t('SS_PAYMENT_ADDONS_LIST')}</div>}
      <div className="list-addon-payment__table">{renderContent()}</div>
    </div>
  );
};

export default ListAddOnPayment;
