import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import {
  Checkbox,
  FormControl,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormGroup,
  InputLabel,
  Grid,
} from '@material-ui/core';
import LazyLoad from 'react-lazyload';
import format from 'number-format.js';
import NumberFormat from 'react-number-format';

import { useTypedSelector } from 'redux/configureStore';
import PERSONAL_CONSTANTS, { SESSION_STORAGE_NAMES } from 'constants/personalConstant';
import { useDeviceCheck } from 'utility/screenDetect';
import { getIconUrl } from 'utility/helpers';
import { CURRENCY_FORMAT_BY_COUNTRY, CURRENCY_FORMAT_BY_COUNTRY_DEFAULT } from 'constants/paymentConstants';
import { ANSWER } from 'constants/commonContants';

interface ListArrearsPaymentProps {
  amountValueRef: any;
  amountValue: number;
  showErrorTnC: boolean;
  setShowErrorTnc: Function;
  termsAndConditionsErrorRef: any;
  handleInputChanged: any;
}

const ArrearsTableMobile = (props: any) => {
  const { transaction, dateFormat, currencyFormat } = props;
  const { t } = useTranslation();
  const [openAddon, setOpenAddon] = useState(false);
  const formatDate = moment(transaction.actionDate).format(dateFormat);
  return (
    <LazyLoad
      height={100}
      offset={[-100, 100]}
      placeholder={
        <Accordion
          classes={{ root: 'addon-details', expanded: 'addon-details__expanded' }}
          expanded={false}
          onChange={() => { }}
        >
          <AccordionSummary
            aria-controls="panel1a-content"
            id="panel1a-header"
            classes={{ root: 'addons-root', content: 'addons-summary' }}
          ></AccordionSummary>
        </Accordion>
      }
    >
      <Accordion
        classes={{ root: 'addon-details', expanded: 'addon-details__expanded' }}
        expanded={openAddon}
        onChange={(e, expanded) => setOpenAddon(expanded)}
      >
        <AccordionSummary
          expandIcon={<img src={getIconUrl('ArrowDown')} alt="arrow-up" />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          classes={{ root: 'addons-root', content: 'addons-summary' }}
        >
          {transaction.feeDescription}
        </AccordionSummary>
        <AccordionDetails>
          <div className="addon-details__detail-group">
            <div className="addon-details__detail-field">
              <strong>{t('SS_PAY_ARREARS_DATE')}</strong>
              <span>{formatDate}</span>
            </div>
            <div className="addon-details__detail-field">
              <strong>{t('SS_PAY_ARREARS_DESCRIPTION')}</strong>
              <span>{transaction.feeDescription}</span>
            </div>
            <div className="addon-details__detail-field">
              <strong>{t('SS_PAY_ARREARS_OUTSTANDING_AMOUNT')}</strong>
              <span>{format(currencyFormat, transaction.amountOutstanding)}</span>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </LazyLoad>
  );
};

const ArrearsTable = (props: any) => {
  const { transactions, dateFormat, currencyFormat, subTotal } = props;
  const { t } = useTranslation();
  return (
    <TableContainer component={Paper} classes={{ root: 'table-container' }}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>{t('SS_PAY_ARREARS_DATE')}</TableCell>
            <TableCell align="left">{t('SS_PAY_ARREARS_DESCRIPTION')}</TableCell>
            <TableCell align="left">{t('SS_PAY_ARREARS_OUTSTANDING_AMOUNT')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {transactions.map((tr: any, index: number): any => {
            const formatDate = moment(tr.actionDate).format(dateFormat);
            return (
              <TableRow key={`transaction-${index}`}>
                <TableCell width="20%">{formatDate}</TableCell>
                <TableCell align="left" width="50%">
                  {tr.feeDescription}
                </TableCell>
                <TableCell align="left" width="20%">
                  {format(currencyFormat, tr.amountOutstanding)}
                </TableCell>
              </TableRow>
            );
          })}

          <TableRow>
            <TableCell className="list-arrears__no-border" />
            <TableCell colSpan={1} className="list-arrears__no-border">
              <span className="list-arrears__subtotal">{t('SS_PAY_ARREARS_SUBTOTAL')}</span>
            </TableCell>
            <TableCell align="left" className="list-arrears__no-border">
              {format(currencyFormat, subTotal)}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export const ListArrearsPayment = (props: ListArrearsPaymentProps) => {
  const [, , isMobile] = useDeviceCheck();
  const { t } = useTranslation();
  const { amountValueRef, showErrorTnC, setShowErrorTnc, termsAndConditionsErrorRef, handleInputChanged } = props;
  const [payAmountError, setPayAmountError] = useState('');
  const [inputRef, setInputRef] = useState<HTMLInputElement>();
  const { arrearInfo } = useTypedSelector((state) => state.payment);
  const { membershipDetails } = useTypedSelector((state) => state.membership);
  const [agreeTnc, setAgreeTnC] = useState(sessionStorage.getItem(SESSION_STORAGE_NAMES.AGREE_TNC) === ANSWER.YES);

  const regexs = useTypedSelector((state) => state.personal.regexs);

  const dateFormat = regexs.find((re) => re.key === PERSONAL_CONSTANTS.DATE_REGEX) || {
    key: '',
    value: _env.DATE_FORMAT,
  };
  const currencyFormat = regexs.find((re) => re.key === PERSONAL_CONSTANTS.CURRENCY_FORMAT_REGEX) || {
    key: '',
    value: '',
  };

  const isArrear = useMemo(() => arrearInfo && arrearInfo.fees && arrearInfo.fees.length > 0, [
    arrearInfo?.fees.length,
  ]);

  const validateAmount = () => {
    const value = Number(amountValueRef.current.value);
    if (inputRef && (isNaN(value) || value <= 0)) {
      setPayAmountError(t('SS_PAY_AMOUNT_ERROR'));
    } else {
      setPayAmountError('');
    }
  };

  const getInputRef = (element: any) => {
    setInputRef(element);
  };

  const handleTermsAndConditions = (e: any) => {
    setAgreeTnC(e.target.checked);
    sessionStorage.setItem(SESSION_STORAGE_NAMES.AGREE_TNC, e.target.checked ? ANSWER.YES : ANSWER.NO);
    if (e.target.checked) {
      setShowErrorTnc(false);
    }
  };

  useEffect(() => {
    amountValueRef.current.value =
      Number(sessionStorage.getItem(SESSION_STORAGE_NAMES.AMOUNT_VALUE)) || arrearInfo?.totalOutstanding;
  }, []);

  useEffect(() => {
    inputRef?.focus();
  }, [inputRef]);

  const renderContent = () => {
    return isArrear ? (
      <>
        {renderArrearsTable()}
        <FormGroup className="make-payment__group">
          <Grid container alignItems="center">
            <Grid item sm={5} xs={6}>
              <InputLabel>{t('SS_PAY_ARREARS_PAYMENT_AMOUNT')}</InputLabel>
            </Grid>
            <Grid item sm={7} xs={6} className="make-payment__amount-input">
              <div className="amount-error">{payAmountError && <span>{payAmountError}</span>}</div>
              <NumberFormat
                defaultValue={
                  Number(sessionStorage.getItem(SESSION_STORAGE_NAMES.AMOUNT_VALUE)) || arrearInfo?.totalOutstanding
                }
                className="make-payment__input"
                {...(CURRENCY_FORMAT_BY_COUNTRY[_env.COUNTRY_CODE.toUpperCase()] || CURRENCY_FORMAT_BY_COUNTRY_DEFAULT)}
                onValueChange={(v) => {
                  amountValueRef.current.value = v.floatValue;
                  handleInputChanged();
                }}
                getInputRef={getInputRef}
                ref={amountValueRef}
                onBlur={validateAmount}
              />
            </Grid>
            <Grid item sm={9} className="make-payment__term_conditions make-payment__term_conditions--margin">
              <FormControl>
                <Checkbox name="checkTermAndCondition" onChange={handleTermsAndConditions} checked={agreeTnc} />
                <span
                  className="make-payment__term_conditions--link"
                  dangerouslySetInnerHTML={{
                    __html: t('SS_PAYMENT_TERM_AND_CONDITIONS', {
                      termsConditions: membershipDetails?.endPoints?.termsAndConditionUrl || '#',
                    }),
                  }}
                />
              </FormControl>
              <div ref={termsAndConditionsErrorRef} className="make-payment__term_conditions--error">
                {showErrorTnC && t('SS_PAYMENT_TERM_AND_CONDITIONS_ERROR')}
              </div>
            </Grid>
          </Grid>
        </FormGroup>
      </>
    ) : (
      <>
        <p className="list-arrears__no-fees">{t('SS_PAYMENT_NO_ARREARS')}</p>
        <div className="make-payment-container">
          <FormGroup className="make-payment__group">
            <Grid container alignItems="center">
              <Grid item sm={12} className="make-payment__no-amount">
                <span className="margin-20-right">{t('SS_PAY_ARREARS_PAYMENT_AMOUNT')}</span>
                <NumberFormat
                  defaultValue={
                    Number(sessionStorage.getItem(SESSION_STORAGE_NAMES.AMOUNT_VALUE)) || arrearInfo?.totalOutstanding
                  }
                  className="make-payment__input"
                  {...(CURRENCY_FORMAT_BY_COUNTRY[_env.COUNTRY_CODE.toUpperCase()] ||
                    CURRENCY_FORMAT_BY_COUNTRY_DEFAULT)}
                  onValueChange={(v) => {
                    amountValueRef.current.value = v.floatValue;
                    handleInputChanged();
                  }}
                  getInputRef={getInputRef}
                  ref={amountValueRef}
                  onBlur={validateAmount}
                />
                <div className="make-payment__amount-error">{payAmountError && <span>{payAmountError}</span>}</div>
              </Grid>
              <Grid item sm={9} className="make-payment__term_conditions">
                <FormControl>
                  <Checkbox name="checkTermAndCondition" onChange={handleTermsAndConditions} checked={agreeTnc} />
                  <span
                    className="make-payment__term_conditions--link"
                    dangerouslySetInnerHTML={{
                      __html: t('SS_PAYMENT_TERM_AND_CONDITIONS', {
                        termsConditions: membershipDetails?.endPoints?.termsAndConditionUrl || '#',
                      }),
                    }}
                  />
                </FormControl>
                <div ref={termsAndConditionsErrorRef} className="make-payment__term_conditions--error">
                  {showErrorTnC && t('SS_PAYMENT_TERM_AND_CONDITIONS_ERROR')}
                </div>
              </Grid>
            </Grid>
          </FormGroup>
        </div>
      </>
    );
  };
  const renderArrearsTable = () => {
    return isMobile ? (
      arrearInfo.fees.map((tr: any, index: any) => (
        <ArrearsTableMobile
          key={`transaction-${index}`}
          transaction={tr}
          dateFormat={dateFormat.value.toUpperCase()}
          currencyFormat={currencyFormat.value}
          subTotal={arrearInfo.totalOutstanding}
        />
      ))
    ) : (
      <ArrearsTable
        transactions={arrearInfo.fees}
        dateFormat={dateFormat.value.toUpperCase()}
        currencyFormat={currencyFormat.value}
        subTotal={arrearInfo.totalOutstanding}
      />
    );
  };
  return (
    <div className="list-arrears-container">
      {isArrear && (
        <div className="list-arrears__title">
          <p>{t('SS_PAYMENT_ARREARS_LIST')}</p>
        </div>
      )}

      <div className="list-arrears__table">{renderContent()}</div>
    </div>
  );
};

export default ListArrearsPayment;
