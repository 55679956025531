import {
  getPaymentMethod,
  getPaymentMethodSuccess,
  getPaymentMethodFail,
  editPayment,
  editPaymentSuccess,
  editPaymentFail,
  doneUpdatePayment,
  clearPaymentState,
  verifyAfterThreeDSEditPayment,
  verifyAfterThreeDS,
  verifyAfterThreeDSRegisterCard,
  setOpenModal,
  callbackPayment,
  callbackPaymentSuccess,
  callbackPaymentFail,
  getArrearInformation,
  getArrearInformationSuccess,
  getArrearInformationFail,
  makePayment,
  makePaymentSuccess,
  makePaymentFail,
  saveCardOnlineFail,
  doneMakePayment,
  doneMakePaymentWithReceived,
  payWithRegisterCard,
  payWithRegisterCardSuccess,
  payWithRegisterCardFail,
  donePayWithRegisterCard,
} from './payment';
import {
  getMembership,
  getMembershipSuccess,
  getMembershipFail,
  getPackagePlansSuccess,
  getBillingdateAddonsSuccess,
} from './membership';
import {
  editPersonalInfo,
  editPersonalInfoSuccess,
  editPersonalInfoFail,
  getConfig,
  getConfigSuccess,
  getConfigFail,
  resetPersonalState,
  getPersonalFields,
  getPersonalFieldsSuccess,
  getPersonalFieldsFail,
  updateMarketingPreferences,
  updateMarketingPreferencesSuccess,
  updateMarketingPreferencesFail,
} from './personal';
import { setUser, removeUser } from './login';
import { getTheme, getThemeFail, getThemeSuccess } from './theme';

import {
  setNotification,
  sendNotification,
  connectToGroup,
  disconnectFromGroup,
  setConnectedToSignalR,
} from './notification';

import { getBillingStatus, getBillingStatusSuccess, getBillingStatusFail } from './system';

export const paymentActions = {
  getPaymentMethod,
  getPaymentMethodSuccess,
  getPaymentMethodFail,
  editPayment,
  editPaymentSuccess,
  editPaymentFail,
  doneUpdatePayment,
  clearPaymentState,
  verifyAfterThreeDSEditPayment,
  verifyAfterThreeDS,
  verifyAfterThreeDSRegisterCard,
  setOpenModal,
  callbackPayment,
  callbackPaymentSuccess,
  callbackPaymentFail,
  getArrearInformation,
  getArrearInformationSuccess,
  getArrearInformationFail,
  payWithRegisterCard,
  payWithRegisterCardSuccess,
  payWithRegisterCardFail,
  makePayment,
  makePaymentSuccess,
  makePaymentFail,
  saveCardOnlineFail,
  doneMakePayment,
  doneMakePaymentWithReceived,
  donePayWithRegisterCard,
};

export const membershipActions = {
  getMembership,
  getMembershipSuccess,
  getMembershipFail,
  getPackagePlansSuccess,
  getBillingdateAddonsSuccess,
};

export const personalActions = {
  editPersonalInfo,
  editPersonalInfoSuccess,
  editPersonalInfoFail,
  getConfig,
  getConfigSuccess,
  getConfigFail,
  resetPersonalState,
  getPersonalFields,
  getPersonalFieldsSuccess,
  getPersonalFieldsFail,
  updateMarketingPreferences,
  updateMarketingPreferencesSuccess,
  updateMarketingPreferencesFail,
};

export const themeActions = {
  getTheme,
  getThemeFail,
  getThemeSuccess,
};

export const loginActions = { setUser, removeUser };

export const notificationActions = {
  setNotification,
  sendNotification,
  connectToGroup,
  disconnectFromGroup,
  setConnectedToSignalR,
};

export const systemActions = {
  getBillingStatus,
  getBillingStatusSuccess,
  getBillingStatusFail,
};
