import COMMON_CONSTANTS from './commonContants';
import { NumberFormatPropsBase } from 'react-number-format';

export default {
  ...COMMON_CONSTANTS,
  NETWORK_ERROR: 'Network Error',
};

export const ADYEN_FAIL_MESSAGES = [
  'ONLINE_PAYMENT_FUNDING_SOURCE_IS_INVALID_WITH_DEBIT_METHOD',
  'ONLINE_PAYMENT_METHOD_IS_EMPTY',
  'ONLINE_PAYMENT_METHOD_IS_NOT_ALLOWED',
  'ONLINE_PAYMENT_FUNDING_SOURCE_IS_INVALID_WITH_CREDIT_METHOD',
];

export const DONE_MAKE_PAYMENT_TYPES = {
  ADDONS: 'addons',
  UPGRADE: 'upgrade',
  NORMAL: 'normal',
};

export const PAYMENT_PURPOSES = {
  EDIT_PAYMENT: 'EDIT_PAYMENT',
  MAKE_PAYMENT: 'MAKE_PAYMENT',
  MAKE_PAYMENT_EXIST_CARD: 'MAKE_PAYMENT_EXIST_CARD',
};

export const PAYMENT_ERRORS = {
  NETWORK_ERROR: 'Network Error',
  PAYMENT_IS_IN_PROGRESS: 'PAYMENT_IS_IN_PROGRESS',
  MEMBER_ADDON_AMOUNT_NOT_MATCH: 'Member_Addon_Amount_Not_Match',
};

export const ADYEN_RESULT_CODE = {
  ONE_0_ONE: '101',
  AUTHORISED: 'Authorised',
  RECEIVED: 'Received',
  REFUSED: 'Refused',
  REDIRECT_SHOPPER: 'RedirectShopper',
  IDENTIFY_SHOPPER: 'IdentifyShopper',
  CHALLENGE_SHOPPER: 'ChallengeShopper',
  PRESENT_TO_SHOPPER: 'PresentToShopper',
};

export const CURRENCY_FORMAT_BY_COUNTRY_DEFAULT: NumberFormatPropsBase = {
  thousandSeparator: ',',
  thousandsGroupStyle: 'thousand',
  decimalSeparator: '.',
  decimalScale: 2,
  fixedDecimalScale: false,
  allowNegative: false,
};

export const CURRENCY_FORMAT_BY_COUNTRY: Record<string, NumberFormatPropsBase> = {
  ID: {
    thousandSeparator: '.',
    thousandsGroupStyle: 'thousand',
    decimalSeparator: ',',
    decimalScale: 0,
    fixedDecimalScale: false,
    allowNegative: false,
  },
  MY: CURRENCY_FORMAT_BY_COUNTRY_DEFAULT,
  TH: CURRENCY_FORMAT_BY_COUNTRY_DEFAULT,
  SG: CURRENCY_FORMAT_BY_COUNTRY_DEFAULT,
  HK: CURRENCY_FORMAT_BY_COUNTRY_DEFAULT,
  PH: CURRENCY_FORMAT_BY_COUNTRY_DEFAULT,
};
