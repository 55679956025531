export const LOGIN = '/ss/auth/login';
export const GET_PAYMENT_METHOD = '/mbs/members/{{memberId}}/paymentMethods';
export const EDIT_PAYMENT = '/mbs/members/{{memberId}}/processpayment';
export const VERIFY_AFTER_THREEDS_EDIT_PAYMENT = '/mbs/members/{{memberId}}/submitpayment';
export const GET_MEMBERSHIP = '/mbs/members/details';
export const VERIFY_AFTER_THREEDS = '/mbs/members/{{memberId}}/verified';
export const CALL_BACK_PAYMENT = '/pmt/payments/ss/callback';
export const SAVE_CARD = '/mbs/members/{{memberId}}/payments';
export const GET_ADYEN_JSON_CODE = '/languages/{{countryCode}}/{{lng}}.json?timestamp={{timestamp}}';
export const EDIT_PERSONAL = '/mbs/members/{{memberId}}/personalDetail';
export const GET_THEME = '/cnm/themes/themeUrl?brandCode={code}';
export const GET_CONFIG = '/cnm/system/configs/public?countryCode={{countryCode}}';
export const GET_LANGUAGES = '/cnm/system/configs/languages';
export const GET_ARREARS_INFO = '/mbs/members/{{memberId}}/fees';
export const PAY_WITH_REGISTER_CARD = '/mbs/members/{{memberId}}/pay';
export const UPDATE_MARKETING_PREFERENCES = '/mbs/members/{{memberId}}/personalDetail';
export const PACKAGE_PLANS = '/mbs/members/{{memberId}}/packageplans';
export const GET_FIELDS = '/cnm/features/fields?code={{featureCode}}';
export const MAKE_PAYMENT = '/mbs/members/{{memberId}}/makepayment';
export const CHANGE_PASSWORD = '/idp/accounts/password';
export const REVOKE_TOKEN = '/connect/revocation';
export const SEARCH_INBOX = '/ads/memberNotifications/search';
export const GET_ADDON = '/mbs/members/{{memberId}}/addons';
export const GET_BILLING_DATE = '/cnm/system/billings/earliest?date={{currentDate}}';
export const CANCEL_ADDON = '/mbs/members/{{memberId}}/canceladdon';
export const RECENT_ACTIVITY = '/ads/memberNotifications/recentactivity';
export const GET_TRANSACTIONS = '/mbs/members/{{memberId}}/transactions?startDate={{startDate}}&endDate={{endDate}}';
export const GET_SYSTEM_DATE = '/cnm/system/configs/systemDate';
export const GET_CLUBS_BY_BRAND = '/cnm/brands/{{brandCode}}';
export const ARREARS_PREVIEW = '/mbs/members/arrears/preview';
export const GET_PLAN_BY_CLUB = '/plm/websales/clubs/{{clubId}}';
export const GET_PREVIEW_PAYMENT_SUMMARY = '/mbs/members/arrears/preview';
export const SUBMIT_INQUIRY = '/mbs/members/{{memberId}}/inquiry';
export const GET_BILLING_STATUS = '/cnm/system/billingStatus?countryCode={{countryCode}}';
export const GET_ALL_CLUBS = '/cnm/clubs';
