export const GET_MEMBERSHIP = 'membership/GET_MEMBERSHIP';
export interface GetMembershipAction {
  type: typeof GET_MEMBERSHIP;
}

export const GET_MEMBERSHIP_SUCCESS = 'membership/GET_MEMBERSHIP_SUCCESS';
export interface GetMembershipSucessAction {
  type: typeof GET_MEMBERSHIP_SUCCESS;
  membershipDetails: any;
}

export const GET_MEMBERSHIP_FAIL = 'membership/GET_MEMBERSHIP_FAIL';
export interface GetMembershipFailAction {
  type: typeof GET_MEMBERSHIP_FAIL;
  error: any;
}

export const GET_PACKAGE_PLANS_SUCCESS = 'membership/GET_PACKAGE_PLANS_SUCCESS';
export interface GetPackagePlansSuccessAction {
  type: typeof GET_PACKAGE_PLANS_SUCCESS;
  data: {
    brands: any;
    clusterName: string;
    onlinePaymentMethod: string;
    addons: [];
    totalRecurringFee: number;
  };
}

export const GET_BILLING_DATE_ADDONS_SUCCESS = 'membership/GET_BILLING_DATE_ADDONS_SUCCESS';
export interface GetBillingDateAddonsSuccessAction {
  type: typeof GET_BILLING_DATE_ADDONS_SUCCESS;
  billingDateAddons: string;
}

export type MembershipAction =
  | GetMembershipAction
  | GetMembershipSucessAction
  | GetMembershipFailAction
  | GetPackagePlansSuccessAction
  | GetBillingDateAddonsSuccessAction;
