import { CloseIcon } from 'components/Icons';
import PERSONAL_CONSTANTS, { SESSION_STORAGE_NAMES } from 'constants/personalConstant';
import moment from 'moment';
import format from 'number-format.js';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useTypedSelector } from 'redux/configureStore';
import i18n from 'translations/i18n';
import { replaceToHTMLElement } from 'utility/helpers';

interface UpgradeThankyouSummaryProps {
  setShowThankUpgrade: (bool: boolean) => void;
  showThankUpgrade: boolean;
}

const UpgradeThankyouSummary: React.FC<UpgradeThankyouSummaryProps> = ({ setShowThankUpgrade, showThankUpgrade }) => {
  const { t } = useTranslation();
  const { regexs } = useTypedSelector((state) => state.personal);
  const tJOL = i18n.getFixedT(`${i18n.language}-adyen`, 'translations');
  const currencyCode = regexs.find((regex) => regex.key === PERSONAL_CONSTANTS.CURRENCY_REGEX) || {
    key: '',
    value: '',
  };
  const currencyFormat = regexs.find((regex) => regex.key === PERSONAL_CONSTANTS.CURRENCY_FORMAT_REGEX) || {
    key: '',
    value: '',
  };
  const formatDate = 'ddd, DD MMMM YYYY';
  const upgradedPreview = JSON.parse(sessionStorage.getItem(SESSION_STORAGE_NAMES.UPGRADED_PREVIEW) || '""');

  const handleClose = () => {
    setShowThankUpgrade(false);
    sessionStorage.removeItem(SESSION_STORAGE_NAMES.UPGRADED_PREVIEW);
    sessionStorage.removeItem(SESSION_STORAGE_NAMES.UPGRADED_MEMBERSHIP);
  };

  return (
    upgradedPreview &&
    showThankUpgrade && (
      <div className="upgraded-summary">
        <div className="upgraded-summary__thankyou">{t('SS_UPGRADE_MEMBERSHIP_THANKYOU_FF')}</div>
        <div className="upgraded-summary__heading">
          <div className="upgraded-summary__heading__title">{t('SS_UPGRADE_MEMBERSHIP_PLAN_DETAILS')}</div>
        </div>
        <div className="upgraded-summary__content">
          <div className="upgraded-summary__content__location">
            <div className="upgraded-summary__content__location__title">
              <p>{t('SS_UPGRADE_MEMBERSHIP_LOCATION')}</p>
              <div className="upgraded-summary__content__location__title--strong">
                {upgradedPreview?.basicPlanClubName}
              </div>
            </div>
            <div className="upgraded-summary__content__location__date">
              <p>{t('SS_UPGRADE_MEMBERSHIP_START_DATE')}</p>
              <div className="upgraded-summary__content__location__date--strong">
                {moment(upgradedPreview.startDate).format(formatDate)}
              </div>
            </div>
          </div>
          <div className="upgraded-summary__content__detail">
            <div className="upgraded-summary__content__detail__item">
              <div>
                {t('SS_UPGRADE_MEMBERSHIP_YOUR_PLAN_INCLUDES')}
                <div>{upgradedPreview.basicPlanShortDescriptionBaseValue}</div>
                <div>{upgradedPreview.basicPlanClubName}</div>
              </div>
              <div>
                {t('SS_UPGRADE_MEMBERSHIP_MONEY_FORMAT', {
                  currency: currencyCode.value,
                  amount: format(currencyFormat.value, upgradedPreview.basicPlanRecurringAmount),
                  perMonth: '/mth',
                })}
              </div>
            </div>
            <div className="upgraded-summary__content__detail__item">
              <div>
                {t('SS_UPGRADE_MEMBERSHIP_YOUR_CLASSES_INCLUDES')}
                {upgradedPreview.addonClasses.map((item: any) => (
                  <div key={`classes-includes-${item.id}`}>{item.name}</div>
                ))}
              </div>
              <div>
                {(upgradedPreview.addonClasses.reduce((total: number, item: any) => total + item.amount.recurring, 0) &&
                  t('SS_UPGRADE_MEMBERSHIP_MONEY_FORMAT', {
                    currency: currencyCode.value,
                    amount: format(
                      currencyFormat.value,
                      upgradedPreview.addonClasses.reduce(
                        (total: number, item: any) => total + item.amount.recurring,
                        0
                      )
                    ),
                    perMonth: '',
                  })) ||
                  t('SS_UPGRADE_MEMBERSHIP_INCLUDED')}
              </div>
            </div>
            <div className="upgraded-summary__content__detail__item">
              <div>
                {t('SS_UPGRADE_MEMBERSHIP_ACCESS_LEVEL')}
                <div
                  dangerouslySetInnerHTML={{
                    __html: replaceToHTMLElement(tJOL(upgradedPreview.accessLevel.name)),
                  }}
                />
              </div>
              <div>
                {(upgradedPreview.accessLevel.recurring.amount &&
                  t('SS_UPGRADE_MEMBERSHIP_MONEY_FORMAT', {
                    currency: currencyCode.value,
                    amount: format(currencyFormat.value, upgradedPreview.accessLevel.recurring.amount),
                    perMonth: '',
                  })) ||
                  t('SS_UPGRADE_MEMBERSHIP_INCLUDED')}
              </div>
            </div>

            <div className="upgraded-summary__content__detail__item">
              <div>
                {t('SS_UPGRADE_MEMBERSHIP_COMMITMENT_LENGTH')}
                <div>{upgradedPreview.commitmentLength.name}</div>
              </div>
              <div>
                {(upgradedPreview.commitmentLength.recurring.amount &&
                  t('SS_UPGRADE_MEMBERSHIP_MONEY_FORMAT', {
                    currency: currencyCode.value,
                    amount: format(currencyFormat.value, upgradedPreview.commitmentLength.recurring.amount),
                    perMonth: '',
                  })) ||
                  t('SS_UPGRADE_MEMBERSHIP_INCLUDED')}
              </div>
            </div>
            {upgradedPreview.recurringAddons.some((item: any) => !item.isAddonClass) && (
              <div className="upgraded-summary__content__detail__item">
                <div>
                  {t('SS_UPGRADE_MEMBERSHIP_RECURRING_ADD-ONS')}
                  {upgradedPreview.recurringAddons.map(
                    (item: any) =>
                      !item.isAddonClass && <div key={`addon-recurring-${item.addonId}`}>{item.addonName}</div>
                  )}
                </div>
                <div>
                  {t('SS_UPGRADE_MEMBERSHIP_MONEY_FORMAT', {
                    currency: currencyCode.value,
                    amount: format(
                      currencyFormat.value,
                      upgradedPreview.recurringAddons.reduce(
                        (total: number, item: any) => (!item.isAddonClass ? total + item.amount : total),
                        0
                      )
                    ),
                    perMonth: '/mth',
                  })}
                </div>
              </div>
            )}
          </div>

          {upgradedPreview.recurringPromotionCode && (
            <div className="upgraded-summary__content__total upgraded-summary__content__total--original">
              <div>
                <div className="upgraded-summary__content__total__title">
                  <div>
                    {t('SS_UPGRADE_MEMBERSHIP_TOTAL_MONTHLY_RECURRING_PAYMENT')}
                    <div>{t('SS_UPGRADE_MEMBERSHIP_INCLUDE_OF_VAT')}</div>
                  </div>
                </div>
                <div>
                  {t('SS_UPGRADE_MEMBERSHIP_MONEY_FORMAT', {
                    currency: currencyCode.value,
                    amount: format(currencyFormat.value, upgradedPreview.recurringOriginalAmount),
                    perMonth: '',
                  })}
                </div>
              </div>
            </div>
          )}

          {upgradedPreview.recurringPromotionCode && !!upgradedPreview.recurringDiscountAmount && (
            <div className="upgraded-summary__content__promotion upgraded-summary__content__detail__item">
              <div>
                {t('SS_UPGRADE_MEMBERSHIP_PROMO_CODE_DISCOUNT')}
                <div>{upgradedPreview.recurringPromotionCode}</div>
              </div>
              <div>
                -
                {t('SS_UPGRADE_MEMBERSHIP_MONEY_FORMAT', {
                  currency: currencyCode.value,
                  amount: format(currencyFormat.value, upgradedPreview.recurringDiscountAmount),
                  perMonth: '',
                })}
              </div>
            </div>
          )}

          <div className="upgraded-summary__content__total">
            <div>
              <div className="upgraded-summary__content__total__title">
                <div>
                  {t('SS_UPGRADE_MEMBERSHIP_TOTAL_MONTHLY_RECURRING_PAYMENT')}
                  <div>{t('SS_UPGRADE_MEMBERSHIP_INCLUDE_OF_VAT')}</div>
                </div>
              </div>
              <div>
                {t('SS_UPGRADE_MEMBERSHIP_MONEY_FORMAT', {
                  currency: currencyCode.value,
                  amount: format(currencyFormat.value, upgradedPreview.recurringAmount),
                  perMonth: '',
                })}
              </div>
            </div>
          </div>
          {upgradedPreview.addons.length > 0 && (
            <div className="upgraded-summary__content__detail upgraded-summary__content__detail--no-border">
              <div className="upgraded-summary__content__detail__title">{t('SS_UPGRADE_MEMBERSHIP_ADD-ONS')}</div>
              {upgradedPreview.addons.map((item: any) => (
                <div
                  className="upgraded-summary__content__detail__item upgraded-summary__content__detail__item--no-margin"
                  key={`addon-${item.id}`}
                >
                  <div>
                    <div>{item.name}</div>
                  </div>
                  <div>
                    <div>
                      {t('SS_UPGRADE_MEMBERSHIP_MONEY_FORMAT', {
                        currency: currencyCode.value,
                        amount: format(currencyFormat.value, item.amount.payNow),
                        perMonth: '',
                      })}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
          <div className="upgraded-summary__content__detail upgraded-summary__content__detail--no-border">
            <div className="upgraded-summary__content__detail__title">
              {t('SS_UPGRADE_MEMBERSHIP_PRO_RATA_PACKAGE_PLAN')}
            </div>
            <div className="upgraded-summary__content__detail__item upgraded-summary__content__detail__item--no-margin">
              <div>
                <div>{upgradedPreview.basicPlanShortDescriptionBaseValue}</div>
              </div>
              <div>
                <div>
                  {t('SS_UPGRADE_MEMBERSHIP_MONEY_FORMAT', {
                    currency: currencyCode.value,
                    amount: format(
                      currencyFormat.value,
                      upgradedPreview.payNowPackageFees.reduce(
                        (total: number, item: any) =>
                          item && item.feeCode === 'PR' ? total + item.originalAmount : total,
                        0
                      )
                    ),
                    perMonth: '',
                  })}
                </div>
              </div>
            </div>
            {upgradedPreview.payNowAddonFees.map(
              (item: any) =>
                item.feeCode === 'PR' && (
                  <div
                    key={`payNowAddon-${item.addonId}`}
                    className="upgraded-summary__content__detail__item upgraded-summary__content__detail__item--no-margin"
                  >
                    <div>
                      <div>{item.addonName}</div>
                    </div>
                    <div>
                      <div>
                        {t('SS_UPGRADE_MEMBERSHIP_MONEY_FORMAT', {
                          currency: currencyCode.value,
                          amount: format(currencyFormat.value, item.originalAmount),
                          perMonth: '',
                        })}
                      </div>
                    </div>
                  </div>
                )
            )}
          </div>
          <div className="upgraded-summary__content__prorata">
            <div className="row-detail">
              <div className="row-detail__title">
                {t('SS_UPGRADE_MEMBERSHIP_PRO_RATA_FIRST_MONTH_PAYMENT')}
                <div className="row-detail__title--note">{t('SS_UPGRADE_MEMBERSHIP_INCLUDE_OF_VAT')}</div>
              </div>
              <div className="row-detail__price">
                {t('SS_UPGRADE_MEMBERSHIP_MONEY_FORMAT', {
                  currency: currencyCode.value,
                  amount: format(
                    currencyFormat.value,
                    upgradedPreview.payNowPackageFees.reduce(
                      (total: number, item: any) => (item.feeCode === 'PR' ? total + item.originalAmount : total),
                      0
                    ) +
                    upgradedPreview.payNowAddonFees.reduce(
                      (total: number, item: any) => (item.feeCode === 'PR' ? total + item.originalAmount : total),
                      0
                    )
                  ),
                  perMonth: '',
                })}
              </div>
            </div>

            <div className="upgraded-summary__content__total upgraded-summary__content__total--gray">
              <div>
                <div className="upgraded-summary__content__total__title">
                  <div>
                    {t('SS_UPGRADE_MEMBERSHIP_TOTAL_PAYMENT')}
                    <div>{t('SS_UPGRADE_MEMBERSHIP_INCLUDE_OF_VAT')}</div>
                  </div>
                </div>
                <div>
                  {t('SS_UPGRADE_MEMBERSHIP_MONEY_FORMAT', {
                    currency: currencyCode.value,
                    amount: format(currencyFormat.value, upgradedPreview.payNowOriginalAmount),
                    perMonth: '',
                  })}
                </div>
              </div>
            </div>
            {upgradedPreview.payNowPromotionCode && !!upgradedPreview.payNowDiscountAmount && (
              <div className="upgraded-summary__content__promotion upgraded-summary__content__detail__item">
                <div>
                  {t('SS_UPGRADE_MEMBERSHIP_PROMO_CODE_DISCOUNT')}
                  <div>{upgradedPreview.payNowPromotionCode}</div>
                </div>
                <div>
                  -
                  {t('SS_UPGRADE_MEMBERSHIP_MONEY_FORMAT', {
                    currency: currencyCode.value,
                    amount: format(currencyFormat.value, upgradedPreview.payNowDiscountAmount),
                    perMonth: '',
                  })}
                </div>
              </div>
            )}
          </div>
          <div className="upgraded-summary__content__totally">
            <div className="row-detail">
              <div className="row-detail__title">
                {t('SS_UPGRADE_MEMBERSHIP_TO_PAY_THIS_MONTH')}
                <div className="row-detail__title--note">{t('SS_UPGRADE_MEMBERSHIP_INCLUDE_OF_VAT')}</div>
              </div>
              <div className="row-detail__price">
                {t('SS_UPGRADE_MEMBERSHIP_MONEY_FORMAT', {
                  currency: currencyCode.value,
                  amount: format(currencyFormat.value, upgradedPreview.payNowAmount),
                  perMonth: '',
                })}
              </div>
            </div>
          </div>
          <div className="upgraded-summary__content__amount-paid">
            <div className="row-detail">
              <div className="row-detail__title">{t('SS_UPGRADE_MEMBERSHIP_AMOUNT_PAID')}</div>
              <div className="row-detail__price">
                {t('SS_UPGRADE_MEMBERSHIP_MONEY_FORMAT', {
                  currency: currencyCode.value,
                  amount: format(currencyFormat.value, upgradedPreview.payNowAmount),
                  perMonth: '',
                })}
              </div>
            </div>
          </div>
        </div>
        <span className="upgraded-summary__close" onClick={handleClose}>
          <span>
            <CloseIcon />
          </span>
        </span>
      </div>
    )
  );
};

export default UpgradeThankyouSummary;
