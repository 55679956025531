import { Button, IconButton, InputAdornment, OutlinedInput } from '@material-ui/core';
import React, { useEffect, useState } from 'react';

import { MEMBERSHIP_URL } from 'utility/routesURL';
import ModalConFirm from 'components/ModalConfirm';
import { changePasswordService } from 'services';
import { getIconUrl } from 'utility/helpers';
import PERSONAL_CONSTANTS from 'constants/personalConstant';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useTypedSelector } from 'redux/configureStore';
import emitNotify from 'utility/emitNotify';

interface ChangePasswordProps {
  setChangePasswordStatus: Function;
}

const ChangePassword = (props: ChangePasswordProps) => {
  const { setChangePasswordStatus } = props;
  const history = useHistory();
  const { t } = useTranslation();
  const regexs = useTypedSelector((state) => state.personal.regexs);
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [currentPasswordError, setCurrentPasswordError] = useState('');
  const [newPasswordError, setNewPasswordError] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState('');
  const [showPassword, setShowPassword] = useState<any>({});
  const [confirmBeforeExit, setConfirmBeforeExit] = useState(false);

  const passwordRegex = regexs.find((re) => re.key === PERSONAL_CONSTANTS.PASSWORD_REGEX) || { key: '', value: '' };
  const regexValidate = new RegExp(passwordRegex.value);

  const enableSave = confirmPasswordError === t('CHANGE_PASSWORD_VALIDATE_MATCH') && newPasswordError === '';

  useEffect(() => {
    document.getElementsByName('current-password')[0].focus();
  }, []);

  useEffect(() => {
    document.title = t('SS_CHANGE_PASSWORD');
  }, [t('SS_LOCAL')]);

  const onChangeCurrentPassword = (value: string) => {
    setCurrentPassword(value);
    if (value) {
      setCurrentPasswordError('');
    }
  };

  const { enqueueSnackbar } = useSnackbar();

  const compareTwoPasswords = (newPassword: string, confirmPassword: string) => {
    if (!!newPassword && !!confirmPassword) {
      if (newPassword === confirmPassword) {
        setConfirmPasswordError(t('CHANGE_PASSWORD_VALIDATE_MATCH'));
      } else {
        setConfirmPasswordError(t('CHANGE_PASSWORD_VALIDATE_UNMATCH'));
      }
    }
  };

  const onChangePassword = (value: string) => {
    setNewPassword(value);
    if (value) {
      if (!regexValidate.test(value)) {
        setNewPasswordError(t('CHANGE_PASSWORD_VALIDATE_CONDITIONER'));
      } else {
        compareTwoPasswords(value, confirmPassword);
        setNewPasswordError('');
      }
    } else {
      setNewPasswordError('');
    }
  };

  const onChangeConfirmPassword = (value: string) => {
    setConfirmPassword(value);
    if (value) {
      compareTwoPasswords(newPassword, value);
    } else {
      setConfirmPasswordError('');
    }
  };

  const handleSubmit = async () => {
    if (confirmPasswordError === t('CHANGE_PASSWORD_VALIDATE_MATCH') && newPasswordError === '') {
      emitNotify(enqueueSnackbar, t('SS_PERSONAL'), t('CHANGE_PASSWORD_SAVING'), 'success');
      gtag('event', 'Button', {
        event_action: 'CPSavePasswordChange',
        event_label: 'CPSavePasswordChangeClicked',
      });
      try {
        const { data } = await changePasswordService({
          currentPassword,
          newPassword,
        });
        if (data.isSuccess) {
          setChangePasswordStatus({ status: 'SUCCESS', GUID: Date.now() });
          emitNotify(enqueueSnackbar, t('SS_PERSONAL'), t('CHANGE_PASSWORD_SUCCESS'), 'success');
          history.push(MEMBERSHIP_URL);
        }
      } catch (error) {
        setChangePasswordStatus({
          status: error.response.data.message.replace('MEMBER.CHANGE_PASSWORD.', 'CHANGE_PASSWORD_'),
          GUID: Date.now(),
        });
      }
    }
  };

  const backToMembership = () => {
    history.push(MEMBERSHIP_URL);
  };
  const handleCancel = () => {
    if (currentPassword === '' && newPassword === '' && confirmPassword === '') {
      backToMembership();
    } else {
      setConfirmBeforeExit(true);
    }
  };

  const onBlur = (value: string, name: string) => {
    if (!value) {
      switch (name) {
        case t('SS_CHANGE_PASSWORD_NEW_PASSWORD'):
          setNewPasswordError(t('CHANGE_PASSWORD_REQUIRED_NEW_PASSWORD'));
          break;
        case t('SS_CHANGE_PASSWORD_CONFIRM_PASSWORD'):
          setConfirmPasswordError(t('CHANGE_PASSWORD_REQUIRED_CONFIRM_PASSWORD'));
          break;
        default:
          setCurrentPasswordError(t('CHANGE_PASSWORD_REQUIRED_CURRENT_PASSWORD'));
          break;
      }
    }
  };

  const handleClickShowPassword = (name: string) => {
    setShowPassword({ ...showPassword, [name]: !showPassword[name] });
  };

  return (
    <div className="change-password__wrapper">
      <div className="change-password__title">{t('SS_CHANGE_PASSWORD')}</div>
      <form autoComplete="off">
        <div className="change-password__group">
          <div className="change-password__field">
            <label htmlFor="">{t('SS_CHANGE_PASSWORD_CURRENT_PASSWORD')}</label>
            <OutlinedInput
              name="current-password"
              autoComplete="new-password"
              type={!showPassword[t('SS_CHANGE_PASSWORD_CURRENT_PASSWORD')] ? 'password' : 'text'}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeCurrentPassword(e.target.value)}
              error={!!currentPasswordError}
              onBlur={(e: any) => onBlur(e.target.value, t('SS_CHANGE_PASSWORD_CURRENT_PASSWORD'))}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => handleClickShowPassword(t('SS_CHANGE_PASSWORD_CURRENT_PASSWORD'))}
                    edge="end"
                  >
                    <img
                      src={
                        !showPassword[t('SS_CHANGE_PASSWORD_CURRENT_PASSWORD')]
                          ? getIconUrl('Eye')
                          : getIconUrl('EyeClose')
                      }
                      alt="current-show"
                    />
                  </IconButton>
                </InputAdornment>
              }
            ></OutlinedInput>
          </div>
          <div className="change-password__error">{currentPasswordError && <span>{currentPasswordError}</span>}</div>
          <div className="change-password__field">
            <label htmlFor="">{t('SS_CHANGE_PASSWORD_NEW_PASSWORD')}</label>
            <OutlinedInput
              name="new-password"
              type={!showPassword[t('SS_CHANGE_PASSWORD_NEW_PASSWORD')] ? 'password' : 'text'}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangePassword(e.target.value)}
              error={!!newPasswordError}
              onBlur={(e: any) => onBlur(e.target.value, t('SS_CHANGE_PASSWORD_NEW_PASSWORD'))}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => handleClickShowPassword(t('SS_CHANGE_PASSWORD_NEW_PASSWORD'))}
                    edge="end"
                  >
                    <img
                      src={
                        !showPassword[t('SS_CHANGE_PASSWORD_NEW_PASSWORD')] ? getIconUrl('Eye') : getIconUrl('EyeClose')
                      }
                      alt="new-show"
                    />
                  </IconButton>
                </InputAdornment>
              }
            ></OutlinedInput>
          </div>
          <div className="change-password__error">{newPasswordError && <span>{newPasswordError}</span>}</div>
          <div className="change-password__field">
            <label htmlFor="">{t('SS_CHANGE_PASSWORD_CONFIRM_PASSWORD')}</label>
            <OutlinedInput
              name="confirm-password"
              type={!showPassword[t('SS_CHANGE_PASSWORD_CONFIRM_PASSWORD')] ? 'password' : 'text'}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeConfirmPassword(e.target.value)}
              error={!!confirmPasswordError && confirmPasswordError !== t('CHANGE_PASSWORD_VALIDATE_MATCH')}
              onBlur={(e: any) => onBlur(e.target.value, t('SS_CHANGE_PASSWORD_CONFIRM_PASSWORD'))}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => handleClickShowPassword(t('SS_CHANGE_PASSWORD_CONFIRM_PASSWORD'))}
                    edge="end"
                  >
                    <img
                      src={
                        !showPassword[t('SS_CHANGE_PASSWORD_CONFIRM_PASSWORD')]
                          ? getIconUrl('Eye')
                          : getIconUrl('EyeClose')
                      }
                      alt="confirm-show"
                    />
                  </IconButton>
                </InputAdornment>
              }
            ></OutlinedInput>
          </div>
          <div
            className={`change-password__error ${confirmPasswordError === t('CHANGE_PASSWORD_VALIDATE_MATCH') && 'change-password__error--green'
              }`}
          >
            {confirmPasswordError && <span>{confirmPasswordError}</span>}
          </div>
        </div>
        <div className="change-password__button-actions">
          <Button className="change-password__button-actions--save" onClick={handleSubmit} disabled={!enableSave}>
            {t('SS_SAVE')}
          </Button>
          <Button onClick={handleCancel}>{t('SS_CANCEL')}</Button>
        </div>
      </form>

      <ModalConFirm
        confirmLabel={t('SS_LEAVE')}
        confirmAction={backToMembership}
        displayMessage={t('SS_CONFIRM_MESS')}
        openModal={confirmBeforeExit}
        handleOpenModal={setConfirmBeforeExit}
      />
    </div>
  );
};

export default ChangePassword;
