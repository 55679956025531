import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import PERSONAL_CONSTANTS from 'constants/personalConstant';

// not like to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init
i18n
  // load translation using xhr -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-xhr-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: PERSONAL_CONSTANTS.UI_LOCALES_DEFAULT,
    load: 'currentOnly',
    detection: {
      order: ['querystring', 'localStorage'],
      lookupFromPathIndex: 0,
      lookupLocalStorage: PERSONAL_CONSTANTS.LANGUAGE,
      lookupQuerystring: PERSONAL_CONSTANTS.LANGUAGE,
    },
    backend: {
      // wait BE upload translate file on blob, ex: https://ewssaas2.blob.core.windows.net/pos/i18n
      loadPath: `${_env.BLOB_SERVER_URL}/i18n/ss/${_env.COUNTRY_CODE}/{{lng}}.json?timestamp=${Date.now().toString()}`,
    },

    // have a common namespace used around the full app
    ns: ['translations'],
    defaultNS: 'translations',
    debug: false,
    keySeparator: false,
    initImmediate: false,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },

    react: {
      wait: true,
      useSuspense: false,
    },
  });

export default i18n;
