import { all, fork, call, put, takeLatest } from 'redux-saga/effects';

import { membershipActionTypes as actionTypes } from '../actionTypes';
import { membershipActions as actionCreators, notificationActions } from '../actionCreators';

import { getBillingDate, getMembershipService, getPackagePlansService, getSystemDate } from 'services';
import moment from 'moment';
import { SYSTEM_DATE_FORMAT } from 'constants/personalConstant';

function* getMembership() {
  try {
    const { data } = yield call(getMembershipService);
    const { systemDate } = yield call(getSystemDate);
    const { actionDate: billingDateAddons } = yield call(
      getBillingDate,
      moment.utc(moment(systemDate).add(1, 'days')).format(SYSTEM_DATE_FORMAT)
    );

    if (data && data.memberId > 0) {
      // Member
      // Only member should call packageplan
      const { data: packagePlan } = yield call(getPackagePlansService, data.memberId || 0);
      if (packagePlan) {
        yield put(
          actionCreators.getPackagePlansSuccess({
            brands: packagePlan?.accessClub?.brands,
            clusterName: packagePlan.clusterName,
            onlinePaymentMethod: packagePlan.onlinePaymentMethod,
            addons: packagePlan.addons,
            totalRecurringFee: packagePlan.totalRecurringFee,
          })
        );
      }
      yield put(actionCreators.getMembershipSuccess({ ...data, addons: packagePlan.addons }));
    } else {
      // Non-member
      yield put(actionCreators.getMembershipSuccess(data));
    }

    if (billingDateAddons) {
      yield put(actionCreators.getBillingdateAddonsSuccess(billingDateAddons));
    }
  } catch (error: any) {
    if (error.response.status === 400) {
      yield put(
        notificationActions.setNotification({
          title: 'SS_NOTIFICATION_SERVICES',
          type: 'normal',
          message: 'SS_NOTIFICATION_SERVICE_ERROR',
          id: Date.now().toString(),
          isSuccess: false,
        })
      );
    }
  }
}

function* watchMembership() {
  yield takeLatest(actionTypes.GET_MEMBERSHIP, getMembership);
}

export default function* membershipSaga() {
  yield all([fork(watchMembership)]);
}
