import { baseApi, blobApi } from './base';
import {
  GET_PAYMENT_METHOD,
  EDIT_PAYMENT,
  VERIFY_AFTER_THREEDS,
  SAVE_CARD,
  CALL_BACK_PAYMENT,
  GET_ARREARS_INFO,
  PAY_WITH_REGISTER_CARD,
  MAKE_PAYMENT,
  GET_ADYEN_JSON_CODE,
  VERIFY_AFTER_THREEDS_EDIT_PAYMENT,
  GET_TRANSACTIONS,
} from './apis';

export async function getPaymentMethodService(memberId: string): Promise<any> {
  return await baseApi.get(GET_PAYMENT_METHOD.replace('{{memberId}}', memberId));
}

export async function editPaymentService(memberId: string, body: any): Promise<any> {
  return await baseApi.post(EDIT_PAYMENT.replace('{{memberId}}', memberId), body);
}

export async function verifyAfterThreeDSEditPaymentService(memberId: string, body: any): Promise<any> {
  return await baseApi.post(VERIFY_AFTER_THREEDS_EDIT_PAYMENT.replace('{{memberId}}', memberId), body);
}

export async function verifyAfterThreeDSService(memberId: string, body: any): Promise<any> {
  return await baseApi.post(VERIFY_AFTER_THREEDS.replace('{{memberId}}', memberId), body);
}

export async function getAdyenJSONCode(countryCode: string, lng: string): Promise<any> {
  return await blobApi.get(
    GET_ADYEN_JSON_CODE.replace('{{countryCode}}', countryCode)
      .replace('{{lng}}', lng)
      .replace('{{timestamp}}', Date.now().toString())
  );
}

export async function saveCardService(memberId: string, body: any): Promise<any> {
  return await baseApi.put(SAVE_CARD.replace('{{memberId}}', memberId), body);
}

export async function callbackPaymentService(token: string): Promise<any> {
  return await baseApi.get(`${CALL_BACK_PAYMENT}?token=${token}`);
}

export async function getArrearInfoService(memberId: string): Promise<any> {
  return await baseApi.get(`${GET_ARREARS_INFO.replace('{{memberId}}', memberId)}`);
}

export async function payWithRegisterCardService(memberId: string, body: any): Promise<any> {
  return await baseApi.put(`${PAY_WITH_REGISTER_CARD.replace('{{memberId}}', memberId)}`, body);
}

export async function makePaymentService(memberId: string, body: any): Promise<any> {
  return await baseApi.post(MAKE_PAYMENT.replace('{{memberId}}', memberId), body);
}

export async function getTransactions(memberId: string, startDate: string, endDate: string): Promise<any> {
  return await baseApi.get(
    GET_TRANSACTIONS.replace('{{memberId}}', memberId)
      .replace('{{startDate}}', startDate)
      .replace('{{endDate}}', endDate)
  );
}
