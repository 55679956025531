import { Accordion, AccordionDetails, AccordionSummary, CircularProgress } from '@material-ui/core';
import BusinessRuleModal from 'components/BusinessRuleModal';
import { CheckIcon, PreviousIcon } from 'components/Icons';
import commonConstants from 'constants/commonContants';
import PERSONAL_CONSTANTS from 'constants/personalConstant';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTypedSelector } from 'redux/configureStore';
import { replaceToHTMLElement } from 'utility/helpers';
import { renderPrice } from '..';
import AccessLevel from '../AccessLevel';
import AddonClass from '../AddonClass';
import CommitmentLength from '../CommitmentLength';
import SelectDate from '../SelectDate';
import {
  Class,
  Behavior,
  CommitmentLengthFull,
  PlanProps,
  SingleAccessLevel,
  Length,
  ReciprocalAccessLevel,
  SelectedDataProps,
  PreviewPaymentSummaryProps,
} from '../types';

interface UpgradeMembershipStep1Props {
  clubId: number;
  brandCode: string;
  planInfo: PlanProps | undefined;
  previewData: PreviewPaymentSummaryProps | undefined;
  selectedItem: SelectedDataProps | undefined;
  setSelectedItem: (data: SelectedDataProps) => void;
}

const UpgradeMembershipStep1: React.FC<UpgradeMembershipStep1Props> = ({
  brandCode,
  selectedItem,
  setSelectedItem,
  planInfo,
  previewData,
}) => {
  const [commitmentLengths, setCommitmentLength] = useState<CommitmentLengthFull[]>([]);
  const [accessLevel, setAccessLevel] = useState<ReciprocalAccessLevel>();
  const [initDate, setInitDate] = useState<any>();
  const regexs = useTypedSelector((state) => state.personal.regexs);
  const [businessRuleMessage, setBusinessRuleMessage] = useState<string>('');
  const [needToShowMessage, setNeedToShowMessage] = useState<boolean>(false);

  const notAllowAddOtherAddonClass = useMemo(() => {
    if (!selectedItem || !planInfo) {
      return true;
    }
    const selectedCluster = selectedItem?.accessLevel;
    const selectedCommitmentLength = selectedItem?.commitmentLength;

    const action = planInfo.behaviors.find(
      (behavior) =>
        behavior.condition.clusterId === selectedCluster.clusterId &&
        behavior.condition.reciprocalAccessLevelCode === selectedCluster.code &&
        behavior.condition.commitmentLength === selectedCommitmentLength.duration
    )?.action;

    return !action?.isModular;
  }, [selectedItem, planInfo]);

  useEffect(() => {
    if (needToShowMessage && previewData) {
      setBusinessRuleMessage(previewData.messages[0] || '');
    } else {
      setBusinessRuleMessage('');
    }
  }, [previewData, needToShowMessage]);

  const customBehaviorsByCluster = useMemo(() => {
    return (
      planInfo?.behaviors.reduce(
        (newBehavior: { [key: string]: Behavior }, behavior: Behavior) => ({
          ...newBehavior,
          [`${behavior.condition.clusterId}${behavior.condition.reciprocalAccessLevelCode}${behavior.condition.commitmentLength}`]: behavior,
        }),
        {}
      ) || {}
    );
  }, [planInfo]);

  const { t, i18n } = useTranslation();
  const tJOL = i18n.getFixedT(`${i18n.language}-adyen`, 'translations');

  const currencyCode = regexs.find((regex) => regex.key === PERSONAL_CONSTANTS.CURRENCY_REGEX) || {
    key: '',
    value: '',
  };
  const currencyFormat = regexs.find((regex) => regex.key === PERSONAL_CONSTANTS.CURRENCY_FORMAT_REGEX) || {
    key: '',
    value: '',
  };

  const sortCommitmentLength = (items: Length[]) => {
    return [
      ...items.filter((item) => item.recurring.amount === 0),
      ...items
        .filter((item) => item.recurring.amount !== 0)
        .sort((item1, item2) => {
          if (item1.duration < item2.duration) {
            return 1;
          }

          if (item1.duration > item2.duration) {
            return -1;
          }
          return 0;
        }),
    ];
  };

  useEffect(() => {
    if (planInfo) {
      setAccessLevel(planInfo.basicPlan.options.reciprocalAccessLevel);

      if (!selectedItem) {
        const selectedCluster =
          planInfo.basicPlan.options.reciprocalAccessLevel.dualAccessLevels.find((item) => item.isPreSelected) ||
          planInfo.basicPlan.options.reciprocalAccessLevel.singleAccessLevels.find((item) => item.isPreSelected);

        const tmpCommitmentLengths = planInfo.basicPlan.options.commitmentLength.lengths.map((length) => {
          return {
            ...length,
            ...(planInfo.behaviors.find(
              (item) =>
                item.condition.clusterId === selectedCluster?.clusterId &&
                item.condition.reciprocalAccessLevelCode === selectedCluster.code &&
                item.condition.commitmentLength === length.duration
            )?.action.membershipTerm || {}),
          };
        });

        setCommitmentLength(sortCommitmentLength(tmpCommitmentLengths));
        setSelectedItem({
          commitmentLength: tmpCommitmentLengths.find((item) => item.isPreSelected) as Length,
          accessLevel: selectedCluster as SingleAccessLevel,
          addonClass: planInfo.basicPlan.options.addonClass.classes.filter((item) => item.isPreSelected),
          addons: [],
          startDate: undefined,
          needToCallPreview: true,
        });
      } else if (!commitmentLengths.length) {
        const selectedCluster = selectedItem.accessLevel;
        const tmpCommitmentLengths = planInfo.basicPlan.options.commitmentLength.lengths.map((length) => {
          return {
            ...length,
            ...(planInfo.behaviors.find(
              (item) =>
                item.condition.clusterId === selectedCluster?.clusterId &&
                item.condition.reciprocalAccessLevelCode === selectedCluster.code &&
                item.condition.commitmentLength === length.duration
            )?.action.membershipTerm || {}),
          };
        });

        setCommitmentLength(sortCommitmentLength(tmpCommitmentLengths));
        setNeedToShowMessage(false);
      }
    }
  }, [planInfo, selectedItem]);

  useEffect(() => {
    if (!selectedItem) {
      return;
    }

    const action = planInfo?.behaviors.find(
      (behavior) =>
        behavior.condition.clusterId === selectedItem.accessLevel.clusterId &&
        behavior.condition.reciprocalAccessLevelCode === selectedItem.accessLevel.code &&
        behavior.condition.commitmentLength === selectedItem.commitmentLength.duration
    )?.action;

    if (!action) {
      return;
    }

    const availableClusterIdOptions = action.availableClusterIdOptions.replace(', ', ',').split(',');
    const availableCommitmentLengthOptions = action.availableCommitmentLengthOptions.replace(', ', ',').split(',');

    setCommitmentLength(
      sortCommitmentLength(commitmentLengths).filter((item) =>
        availableCommitmentLengthOptions?.find((length) => Number(length) === item.duration)
      )
    );

    setAccessLevel({
      ...accessLevel,
      singleAccessLevels:
        accessLevel?.singleAccessLevels.map((item) => ({
          ...item,
          isNotAvailable: !availableClusterIdOptions?.find((id) => Number(id) === item.clusterId),
        })) || [],
      dualAccessLevels:
        accessLevel?.dualAccessLevels.map((item) => ({
          ...item,
          isNotAvailable: !availableClusterIdOptions?.find((id) => Number(id) === item.clusterId),
        })) || [],
    });

    setInitDate({
      initStartDate: new Date(action.startDateBegin),
      initEndDate: new Date(action.startDateEnd),
    });
  }, [selectedItem?.commitmentLength, selectedItem?.accessLevel]);

  if (!planInfo || !selectedItem) {
    return <CircularProgress className="loading" />;
  }

  return (
    <>
      <div className="upgrade-membership__container build-plan__container">
        <div className="build-plan">
          <div className="build-plan__title">
            {brandCode === commonConstants.CF_BRAND
              ? t('SS_UPGRADE_MEMBERSHIP_BASIC_PLAN_CF.TITLE')
              : t('SS_UPGRADE_MEMBERSHIP_BASIC_PLAN.TITLE')}
          </div>
          <div className="build-plan__description">
            {brandCode === commonConstants.CF_BRAND
              ? t('SS_UPGRADE_MEMBERSHIP_BASIC_PLAN_CF.DESCRIPTION')
              : t('SS_UPGRADE_MEMBERSHIP_BASIC_PLAN.DESCRIPTION')}
          </div>
          <div className="items upgrade-membership__row">
            <div className="item__wrapper active upgrade-membership__col--custom">
              <div className="item">
                <span className="item__icon">
                  <CheckIcon />
                </span>
                <div className="item__title">
                  <h2>
                    <span>{tJOL(planInfo.basicPlan.shortDescription.key)}</span>
                    <br />
                    <span>{planInfo.basicPlan.club.name}</span>
                  </h2>
                </div>
                <div className="item__price">
                  <b>
                    {renderPrice(
                      planInfo.basicPlan.recurring.amount,
                      { currencyCode: currencyCode.value, currencyFormat: currencyFormat.value },
                      t
                    )}
                  </b>
                </div>
              </div>
              <Accordion classes={{ root: 'item-viewmore', expanded: 'item-viewmore__expanded' }}>
                <AccordionSummary
                  aria-controls="item-viewmore__content"
                  id={`item${planInfo.basicPlan.club.id}__header`}
                  classes={{ root: 'item-viewmore__root', content: 'item-viewmore__summary' }}
                >
                  <PreviousIcon />
                </AccordionSummary>
                <AccordionDetails className="item-viewmore__content">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: replaceToHTMLElement(tJOL(planInfo.basicPlan.description.key)),
                    }}
                  ></div>
                </AccordionDetails>
              </Accordion>
            </div>
          </div>
        </div>
        <div className="build-plan">
          <div className="build-plan__title">
            {brandCode === commonConstants.CF_BRAND
              ? t('SS_UPGRADE_MEMBERSHIP_ADDON_CLASS_CF.TITLE')
              : t('SS_UPGRADE_MEMBERSHIP_ADDON_CLASS.TITLE')}
          </div>
          <div className="build-plan__description">
            {brandCode === commonConstants.CF_BRAND
              ? t('SS_UPGRADE_MEMBERSHIP_ADDON_CLASS_CF.DESCRIPTION')
              : t('SS_UPGRADE_MEMBERSHIP_ADDON_CLASS.DESCRIPTION')}
          </div>
          <AddonClass
            data={planInfo.basicPlan.options.addonClass.classes}
            currencyCode={currencyCode.value}
            currencyFormat={currencyFormat.value}
            selectedItem={selectedItem.addonClass}
            disableItemNotSelected={notAllowAddOtherAddonClass}
            onSelectItem={(data: Class[]) => {
              setSelectedItem({
                ...selectedItem,
                addonClass: data,
                needToCallPreview: true,
              });
              setNeedToShowMessage(true);
            }}
          />
        </div>
        <div className="build-plan">
          <div className="build-plan__title">
            {brandCode === commonConstants.CF_BRAND
              ? t('SS_UPGRADE_MEMBERSHIP_ACCESS_LEVEL_CF.TITLE')
              : t('SS_UPGRADE_MEMBERSHIP_ACCESS_LEVEL.TITLE')}
          </div>
          <div className="build-plan__description">
            {brandCode === commonConstants.CF_BRAND
              ? t('SS_UPGRADE_MEMBERSHIP_ACCESS_LEVEL_CF.DESCRIPTION')
              : t('SS_UPGRADE_MEMBERSHIP_ACCESS_LEVEL.DESCRIPTION')}
          </div>
          <AccessLevel
            data={accessLevel}
            currencyCode={currencyCode.value}
            currencyFormat={currencyFormat.value}
            customBehaviorsByCluster={customBehaviorsByCluster}
            selectedCommitmentLength={selectedItem.commitmentLength}
            brandCode={brandCode}
            selectedItem={selectedItem.accessLevel}
            onSelectItem={(item: any) => {
              setSelectedItem({
                ...selectedItem,
                accessLevel: item,
                needToCallPreview: true,
              });
              setNeedToShowMessage(true);
            }}
          />
        </div>
        <div className="build-plan">
          <div className="build-plan__title">
            {brandCode === commonConstants.CF_BRAND
              ? t('SS_UPGRADE_MEMBERSHIP_COMMITMENT_LENGTH_CF.TITLE')
              : t('SS_UPGRADE_MEMBERSHIP_COMMITMENT_LENGTH.TITLE')}
          </div>
          <div className="build-plan__description">
            {brandCode === commonConstants.CF_BRAND
              ? t('SS_UPGRADE_MEMBERSHIP_COMMITMENT_LENGTH_CF.DESCRIPTION')
              : t('SS_UPGRADE_MEMBERSHIP_COMMITMENT_LENGTH.DESCRIPTION')}
          </div>
          <CommitmentLength
            data={commitmentLengths}
            currencyCode={currencyCode.value}
            currencyFormat={currencyFormat.value}
            selectedItem={selectedItem.commitmentLength}
            onSelectItem={(data: Length) => {
              setSelectedItem({
                ...selectedItem,
                commitmentLength: data,
                needToCallPreview: true,
              });
              setNeedToShowMessage(false);
            }}
          />
        </div>
        <div className="build-plan">
          <div className="build-plan__title">
            {brandCode === commonConstants.CF_BRAND
              ? t('SS_UPGRADE_MEMBERSHIP_COMMITMENCE_DATE_CF.TITLE')
              : t('SS_UPGRADE_MEMBERSHIP_COMMITMENCE_DATE.TITLE')}
          </div>
          <div className="build-plan__description">
            {brandCode === commonConstants.CF_BRAND
              ? t('SS_UPGRADE_MEMBERSHIP_COMMITMENCE_DATE_CF.DESCRIPTION')
              : t('SS_UPGRADE_MEMBERSHIP_COMMITMENCE_DATE.DESCRIPTION')}
          </div>
          {initDate && (
            <SelectDate
              initStartDate={initDate.initStartDate}
              initEndDate={initDate.initEndDate}
              startDate={selectedItem.startDate || null}
              setStartDate={(date: Date) => {
                setSelectedItem({
                  ...selectedItem,
                  needToCallPreview: true,
                  startDate: date,
                });
                setNeedToShowMessage(false);
              }}
            />
          )}
        </div>
      </div>
      <BusinessRuleModal
        isOpen={!!tJOL(businessRuleMessage)}
        content={tJOL(businessRuleMessage)}
        isCF={brandCode === commonConstants.CF_BRAND}
        setOpen={() => setBusinessRuleMessage('')}
      />
    </>
  );
};

export default UpgradeMembershipStep1;
