import { identityApi } from './base';
import { LOGIN, REVOKE_TOKEN } from './apis';

interface loginResponse {
  membershipId: string;
  memberId: number;
  token: string;
  tenantId: string;
}

export async function loginService(membershipId: string, password: string): Promise<loginResponse> {
  return await identityApi.post(LOGIN, {
    membershipId,
    password,
  });
}

export const revokeToken = async ({ token = '', client_id = '' }) => {
  const params = new URLSearchParams();
  params.append('token', token);
  params.append('client_id', client_id);
  return identityApi.post(REVOKE_TOKEN, params);
};
