import '@adyen/adyen-web/dist/adyen.css';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import ListArrearsPayment from 'components/ListArrearsPayment';
import { ADYEN_LOGO_CARD_URL } from 'constants/baseConstants';
import { ANSWER } from 'constants/commonContants';
import { PAYMENT_ERRORS } from 'constants/paymentConstants';
import PERSONAL_CONSTANTS, { BRAND_CODE_ALIAS, SESSION_STORAGE_NAMES } from 'constants/personalConstant';
import format from 'number-format.js';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTypedSelector } from 'redux/configureStore';
import { CheckExpiredCard } from 'utility/checkExpiredCard';

interface MakePaymentProps {
  handleInputChanged: any;
  showRegisterCardFragment: boolean;
  amountValueRef: any;
  amountValue: number;
  setOpenConfirmPayExistCard: (isOpen: boolean) => void;
  isHideNextRecurring: boolean;
  showExistCard: boolean;
  saveForNextCheckboxRef: any;
  showErrorTnC: boolean;
  setShowErrorTnc: Function;
  termsAndConditionsErrorRef: any;
  scrollAndSetErrorTermsAndConditions: () => void;
}

interface ExistCardProps {
  payment: any;
  openConfirm?: () => void;
  isDisable?: boolean;
}

export const ExistCard = (props: ExistCardProps) => {
  const { t } = useTranslation();
  const { payment, openConfirm, isDisable } = props;

  return (
    <div className="exist-card-payment__content">
      <button className="exist-card" onClick={openConfirm} disabled={isDisable}>
        <div className="exist-card__info">
          <img
            src={ADYEN_LOGO_CARD_URL.replace('{cardType}', payment.cardType ? payment.cardType : 'card')}
            alt="card-logo"
          />
          <span>x{payment.accountNumber.slice(-4)}</span>
        </div>
        <span>
          {t('SS_PAYMENT_EXPIRE_DATE')} : {`${payment.expiryDate.slice(0, 2)}/${payment.expiryDate.slice(2)}`}
        </span>
      </button>
    </div>
  );
};

export const MakePayment = (props: MakePaymentProps) => {
  const { t, i18n } = useTranslation();
  const tAdyen = i18n.getFixedT(`${i18n.language}-adyen`, 'translations');

  const {
    showRegisterCardFragment,
    amountValueRef,
    amountValue,
    setOpenConfirmPayExistCard,
    isHideNextRecurring,
    showExistCard,
    saveForNextCheckboxRef,
    showErrorTnC,
    setShowErrorTnc,
    termsAndConditionsErrorRef,
    scrollAndSetErrorTermsAndConditions,
    handleInputChanged,
  } = props;
  const { membershipDetails, totalRecurringFee } = useTypedSelector((state) => state.membership);
  const { error } = useTypedSelector((state) => state.payment);
  const { regexs } = useTypedSelector((state) => state.personal);
  const currencyCode = regexs.find((regex) => regex.key === PERSONAL_CONSTANTS.CURRENCY_REGEX) || {
    key: '',
    value: '',
  };
  const currencyFormat = regexs.find((regex) => regex.key === PERSONAL_CONSTANTS.CURRENCY_FORMAT_REGEX) || {
    key: '',
    value: '',
  };

  const [isCheckAffirmativeSubcriptionTerms, setIsCheckAffirmativeSubcriptionTerms] = useState(false);
  const saveForNextRecurring = sessionStorage.getItem(SESSION_STORAGE_NAMES.SAVE_FOR_NEXT_RECURRING);

  useEffect(() => {
    setIsCheckAffirmativeSubcriptionTerms(saveForNextRecurring === ANSWER.YES || isCheckAffirmativeSubcriptionTerms);
  }, [saveForNextRecurring]);

  const { payment, previousPayment } = membershipDetails;
  const validCardForMakePayment = ['CC', 'Credit Card', 'Conversion', 'EFT'];
  const restrictCards = ['BCA Local Card'];

  const handleCheckAffirmativeSubscriptionTerms = (e: any) => {
    setIsCheckAffirmativeSubcriptionTerms(e.target.checked);
  };

  const openConfirm = () => {
    if (sessionStorage.getItem(SESSION_STORAGE_NAMES.AGREE_TNC) === ANSWER.YES) {
      setOpenConfirmPayExistCard(true);
    } else {
      scrollAndSetErrorTermsAndConditions();
    }
  };

  const renderError = () => {
    switch (error) {
      case PAYMENT_ERRORS.NETWORK_ERROR:
        return t('CONNECTION_INTERRUPTED');
      case PAYMENT_ERRORS.PAYMENT_IS_IN_PROGRESS:
        return t('PAYMENT_IS_LOCKED');
      default:
        return tAdyen(error);
    }
  };

  const renderAffirmativeSubcriptionTerms = () => (
    <div className="new-card-payment__affirmative-subscription-terms">
      <FormControlLabel
        control={
          <Checkbox
            name="checkAffirmativeSubcriptionTerms"
            onChange={handleCheckAffirmativeSubscriptionTerms}
            checked={isCheckAffirmativeSubcriptionTerms}
            inputRef={saveForNextCheckboxRef}
          />
        }
        label={t('SS_AFFIRMATIVE_SUBSCRIPTION_TERMS_MAKE_AND_EDIT_PAYMENT', {
          currency: currencyCode.value,
          recurringFeeAmount: format(currencyFormat.value, totalRecurringFee),
        })}
      />
    </div>
  );

  const renderAffirmativeSubcriptionTermsDefault = () => (
    <>
      <div className="new-card-payment__affirmative-subscription-terms">
        <FormControlLabel
          control={
            <Checkbox
              name="checkAffirmativeSubcriptionTerms"
              onChange={handleCheckAffirmativeSubscriptionTerms}
              checked={isCheckAffirmativeSubcriptionTerms}
              inputRef={saveForNextCheckboxRef}
            />
          }
          label={t('SS_AFFIRMATIVE_SUBSCRIPTION_TERMS_DEFAULT')}
        />
      </div>
      <div className="new-card-payment__note">
        <span>{t('SS_PAYMENT_NOTE')}: </span>
        <span>
          {t('SS_PAYMENT_NOTE_SAVING_CARD', {
            brandName: BRAND_CODE_ALIAS[membershipDetails.brandCode.toLocaleUpperCase()],
          })}
        </span>
      </div>
    </>
  );

  return (
    <>
      <ListArrearsPayment
        amountValueRef={amountValueRef}
        amountValue={amountValue}
        showErrorTnC={showErrorTnC}
        setShowErrorTnc={setShowErrorTnc}
        termsAndConditionsErrorRef={termsAndConditionsErrorRef}
        handleInputChanged={handleInputChanged}
      />
      <div className="make-payment">
        <div className="make-payment__options">
          <div className="make-payment__options__new-card-payment">
            <p>{t('SS_PAY_ARREARS_SELECT_YOUR_PAYMENT_METHOD')}</p>
            <div className="new-card-payment__content">
              {error && <div className="payment-error">{renderError()}</div>}
              {!isHideNextRecurring &&
                validCardForMakePayment.includes(membershipDetails.paymentMethod) &&
                (totalRecurringFee > 0
                  ? renderAffirmativeSubcriptionTerms()
                  : renderAffirmativeSubcriptionTermsDefault())}
              <div id="dropin-container"></div>
            </div>
          </div>
          {showRegisterCardFragment &&
            validCardForMakePayment.includes(membershipDetails.paymentMethod) &&
            ((payment.primary && validCardForMakePayment.includes(payment.primary.paymentMethod) && (
              <div className="make-payment__options__exist-card-payment">
                <p>{t('SS_PAY_ARREARS_REGISTRATION_CARD')}</p>

                <div className="make-payment__options__exist-card-payment__wrapper">
                  {showExistCard && (
                    <ExistCard
                      payment={payment.primary}
                      openConfirm={openConfirm}
                      isDisable={
                        !CheckExpiredCard(payment.primary.expiryDate) ||
                        restrictCards.includes(payment.primary.fiscalCode)
                      }
                    />
                  )}

                  <div className="make-payment__options__exist-card-payment__3ds">
                    <div id="dropin-container1"></div>
                  </div>
                </div>
              </div>
            )) ||
              (previousPayment.primary && validCardForMakePayment.includes(previousPayment.primary.paymentMethod) && (
                <div className="make-payment__options__exist-card-payment">
                  <p>{t('SS_PAY_ARREARS_REGISTRATION_CARD')}</p>
                  <ExistCard payment={previousPayment.primary} isDisable />
                </div>
              )))}
        </div>
      </div>
    </>
  );
};

export default MakePayment;
