import AdyenCheckout from '@adyen/adyen-web';
import '@adyen/adyen-web/dist/adyen.css';
import { Checkbox, CircularProgress, FormControlLabel } from '@material-ui/core';
import { AddonInterface } from 'components/Addon/types';
import ListAddOnPayment from 'components/ListAddOnPayment';
import { ExistCard } from 'components/MakePayment';
import ConfirmModal from 'components/ModalConfirm';
import { ANSWER } from 'constants/commonContants';
import {
  DONE_MAKE_PAYMENT_TYPES,
  PAYMENT_ERRORS,
  PAYMENT_PURPOSES,
} from 'constants/paymentConstants';
import PERSONAL_CONSTANTS, { SESSION_STORAGE_NAMES } from 'constants/personalConstant';
import format from 'number-format.js';
import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { paymentActions } from 'redux/actionCreators';
import { verifyAfterThreeDSRegisterCard } from 'redux/actionCreators/payment';
import { useTypedSelector } from 'redux/configureStore';
import { getAddons } from 'services/membership';
import i18n from 'translations/i18n';
import { translations } from 'utility/adyenTranslations';
import { CheckExpiredCard } from 'utility/checkExpiredCard';
import { elementExistInDom } from 'utility/helpers';
import { MEMBERSHIP_URL } from 'utility/routesURL';
import { useDeviceCheck } from 'utility/screenDetect';
import { 
  hasParamsThreeDs,
  hasPayload, 
  hasPaymentMethodType, 
  hasRedirectResult, 
  hasResultCode 
} from 'utility/adyen';

interface AddonPaymentProps {
  setIsMakePayment: Function;
  isMakePayment: boolean;
}

interface VerifyThreeDSAddonBody {
  paymentData: string;
  verifyData: {
    md?: string;
    paRes?: string;
    details?: any;
  };
  addonIds: [number];
  isVerifyForPayment?: boolean;
}

const PaymentMerchant = (props: any) => {
  const validCardForMakePayment = ['CC', 'Credit Card', 'Conversion', 'EFT'];
  const restrictCards = ['BCA Local Card'];
  const { t } = useTranslation();
  const tAdyen = i18n.getFixedT(`${i18n.language}-adyen`, 'translations');

  const {
    showExistCard,
    setOpenConfirmPayExistCard,
    scrollAndSetErrorTermsAndConditions,
    showRegisterCardFragment,
    finalTotalRecurring,
    showCheckboxSaveForNext,
  } = props;

  const { error } = useTypedSelector((state) => state.payment);
  const { membershipDetails } = useTypedSelector((state) => state.membership);
  const { regexs } = useTypedSelector((state) => state.personal);
  const { payment, previousPayment } = membershipDetails;

  const currencyCode = regexs.find((regex) => regex.key === PERSONAL_CONSTANTS.CURRENCY_REGEX) || {
    key: '',
    value: '',
  };
  const currencyFormat = regexs.find((regex) => regex.key === PERSONAL_CONSTANTS.CURRENCY_FORMAT_REGEX) || {
    key: '',
    value: '',
  };

  const [isCheckAffirmativeSubcriptionTerms, setIsCheckAffirmativeSubcriptionTerms] = useState(false);

  const handleCheckAffirmativeSubscriptionTerms = (e: any) => {
    setIsCheckAffirmativeSubcriptionTerms(e.target.checked);
  };

  const openConfirm = () => {
    if (sessionStorage.getItem(SESSION_STORAGE_NAMES.AGREE_TNC) === ANSWER.YES) {
      setOpenConfirmPayExistCard(true);
    } else {
      scrollAndSetErrorTermsAndConditions();
    }
  };

  const renderError = () => {
    switch (error) {
      case PAYMENT_ERRORS.NETWORK_ERROR:
        return t('CONNECTION_INTERRUPTED');
      case PAYMENT_ERRORS.PAYMENT_IS_IN_PROGRESS:
        return t('PAYMENT_IS_LOCKED');
      case PAYMENT_ERRORS.MEMBER_ADDON_AMOUNT_NOT_MATCH:
        return t('Member_Addon_Amount_Not_Match');
      default:
        return tAdyen(error);
    }
  };

  const renderAffirmativeSubcriptionTerms = () =>
    showCheckboxSaveForNext && finalTotalRecurring > 0 ? (
      <div className="adyen-form-addons__affirmative-subscription-terms">
        <FormControlLabel
          control={
            <Checkbox
              name="checkAffirmativeSubcriptionTerms"
              onChange={handleCheckAffirmativeSubscriptionTerms}
              checked={isCheckAffirmativeSubcriptionTerms}
            />
          }
          label={t('SS_AFFIRMATIVE_SUBSCRIPTION_TERMS_BUY_ADDON', {
            currency: currencyCode.value,
            recurringFeeAmount: format(currencyFormat.value, finalTotalRecurring),
          })}
        />
        {!isCheckAffirmativeSubcriptionTerms && (
          <span className="reminder">{t('SS_AFFIRMATIVE_SUBSCRIPTION_TERMS_REMIND_UPGRADE_AND_BUY_ADDON')}</span>
        )}
      </div>
    ) : (
      <></>
    );

  useEffect(() => {
    const submitPaymentButton = document.getElementsByClassName('adyen-checkout__button--pay')[0] as HTMLInputElement;
    if (submitPaymentButton && finalTotalRecurring > 0) {
      submitPaymentButton.disabled = !isCheckAffirmativeSubcriptionTerms;
    }
  }, [isCheckAffirmativeSubcriptionTerms, finalTotalRecurring]);

  return (
    <div className="adyen-form-addons">
      <div className="adyen-form-addons__options">
        <div className="adyen-form-addons__options__new-card-payment">
          <p className="adyen-form-addons__options__title">{t('SS_PAY_ARREARS_SELECT_YOUR_PAYMENT_METHOD')}</p>
          {renderAffirmativeSubcriptionTerms()}
          <div className="new-card-payment__content">
            {error && <div className="payment-error">{renderError()}</div>}
            <div id="dropin-container"></div>
          </div>
        </div>
        {showRegisterCardFragment &&
          validCardForMakePayment.includes(membershipDetails.paymentMethod) &&
          ((payment.primary && validCardForMakePayment.includes(payment.primary.paymentMethod) && (
            <div className="adyen-form-addons__options__exist-card-payment">
              <p className="adyen-form-addons__options__title">{t('SS_PAY_ARREARS_REGISTRATION_CARD')}</p>

              <div className="adyen-form-addons__options__exist-card-payment__wrapper">
                {showExistCard && (
                  <ExistCard
                    payment={payment.primary}
                    openConfirm={openConfirm}
                    isDisable={
                      !CheckExpiredCard(payment.primary.expiryDate) ||
                      restrictCards.includes(payment.primary.fiscalCode) ||
                      (finalTotalRecurring > 0 && !isCheckAffirmativeSubcriptionTerms)
                    }
                  />
                )}

                <div className="adyen-form-addons__options__exist-card-payment__3ds">
                  <div id="dropin-container1"></div>
                </div>
              </div>
            </div>
          )) ||
            (previousPayment.primary && validCardForMakePayment.includes(previousPayment.primary.paymentMethod) && (
              <div className="adyen-form-addons__options__exist-card-payment">
                <p className="adyen-form-addons__options__title">{t('SS_PAY_ARREARS_REGISTRATION_CARD')}</p>
                <ExistCard payment={previousPayment.primary} isDisable />
              </div>
            )))}
      </div>
    </div>
  );
};

export const AddonPayment = (props: AddonPaymentProps) => {
  const { t } = useTranslation();

  const location = useLocation();
  const query = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const history = useHistory();
  //redux store
  const {
    makePaymentFail,
    clearPaymentState,
    verifyAfterThreeDS,
    setOpenModal,
    callbackPayment,
    makePayment,
    payWithRegisterCard,
  } = paymentActions;
  const {
    paymentMethodsResponse,
    editPaymentSuccess,
    makePaymentSuccess,
    donePayWithRegisterCard,
    paymentResponse,
    paymentData,
    error,
    isOpenConfirm,
    loadingCallback,
    doneMakePayment,
    callbackConfirm,
    callbackPaymentRes,
    payWithRegisterCardSuccess,
  } = useTypedSelector((state) => state.payment);
  const { membershipDetails, totalRecurringFee } = useTypedSelector((state) => state.membership);
  const paymentAction = useTypedSelector((state) => state.payment.paymentAction);

  const [isVerifySuccess, setIsVerifySuccess] = useState(false);
  const [openConfirmPayExistCard, setOpenConfirmPayExistCard] = useState(false);
  const [showRegisterCardFragment, setShowRegisterCardFragment] = useState(false);
  const [showExistCard, setShowExistCard] = useState(true);
  const [showErrorTnC, setShowErrorTnc] = useState(false);
  const [isFirstTimeCallback, setIsFirstTimeCallback] = useState(true);
  const [addons, setAddons] = useState<AddonInterface[]>([]);
  const [showCheckboxSaveForNext, setShowCheckboxSaveForNext] = useState(true);

  const termsAndConditionsErrorRef = useRef<HTMLDivElement>(null);
  const allowProccessPaymentRef = useRef<boolean>(true);
  const [, , isMobile] = useDeviceCheck();
  const dispatch = useDispatch();
  const clearPaymentAction = (clearResponse?: boolean) => dispatch(clearPaymentState(clearResponse));
  const subTotal = useMemo(
    () =>
      addons.reduce(
        (count, item) => (item.amount.payNow ? count + item.amount.payNow : count + item.amount.originalAmount),
        0
      ),
    [addons.length]
  );

  const recurringAmount = useMemo(() => addons.reduce((count, item) => count + item.amount.recurring, 0), [
    addons.length,
  ]);
  const addonIds = JSON.parse(sessionStorage.getItem(SESSION_STORAGE_NAMES.ADDON_IDS) || '') || [];
  const finalTotalRecurring = useMemo(() => totalRecurringFee + recurringAmount, [totalRecurringFee, recurringAmount]);
  const makePaymentFailAction = (error: any) => dispatch(makePaymentFail(error));
  const verifyThreeDSAddonAction = (body: VerifyThreeDSAddonBody) => dispatch(verifyAfterThreeDS(body));
  const verifyThreeDSRegisterCardAddonAction = (body: VerifyThreeDSAddonBody) =>
    dispatch(verifyAfterThreeDSRegisterCard(body));
  const setOpenModalAction = (isOpen: boolean, callback?: Function) => dispatch(setOpenModal(isOpen, callback));
  const makePaymentAddonAction = (
    amountValue: number,
    payment: {
      paymentMethod: any;
      browserInfo: any;
      paymentType: string;
    },
    enableRecurring: boolean,
    dropinForm: any,
    addonIds: [number]
  ) => dispatch(makePayment(amountValue, payment, enableRecurring, dropinForm, addonIds));

  const payArrearsAction = (amount: number, addonIds: [number]) => dispatch(payWithRegisterCard(amount, addonIds));
  const callbackPaymentAction = (token: string) => dispatch(callbackPayment(token));

  const scrollAndSetErrorTermsAndConditions = () => {
    termsAndConditionsErrorRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'start',
    });
    setShowErrorTnc(true);
  };

  const getAddonsData = useCallback(async () => {
    try {
      const data = await getAddons(membershipDetails.memberId);
      const selectedAddons = data.filter((addon) => addonIds.includes(addon.id));
      setAddons(selectedAddons);
    } catch (e) {
      console.log(e);
    }
  }, [membershipDetails.memberId, membershipDetails.currentBalance]);

  useEffect(() => {
    !addons.length && getAddonsData();
  }, [addons.length, getAddonsData]);

  useEffect(() => {
    // Catch PresentToShopper
    if (doneMakePayment === DONE_MAKE_PAYMENT_TYPES.ADDONS) {
      handleLeaveMakePayment();
      history.push(MEMBERSHIP_URL);
    }
    if (donePayWithRegisterCard === DONE_MAKE_PAYMENT_TYPES.ADDONS) {
      setOpenModalAction(false);
      handleLeaveMakePayment();
      history.push(MEMBERSHIP_URL);
    }
  }, [doneMakePayment, donePayWithRegisterCard]);

  useEffect(() => {
    document.title = t('SS_PAYMENT_AND_CREDIT');
  }, [t('SS_LOCAL')]);

  const removeQueryParam = (param: string) => {
    if (query.has(param)) {
      query.delete(param);
      history.replace({
        search: query.toString(),
      });
    }
  };

  useEffect(() => {
    const token = query.get(PERSONAL_CONSTANTS.TOKEN);

    if (token && !callbackPaymentRes && !loadingCallback && getProcessPurpose()) {
      callbackPaymentAction(token);
      removeQueryParam(PERSONAL_CONSTANTS.TOKEN);
    }
  }, [loadingCallback, callbackPaymentRes]);

  const handleVerifyThreeDSMakePayment = (
    isPaymentExist: boolean, 
    callbackPaymentRes: any
  ) => {
    const {
      md, 
      paRes ,
      payload,
      redirectResult
    } = callbackPaymentRes

    const paymentData = localStorage.getItem(PERSONAL_CONSTANTS.PAYMENT_DATA) || '';

    if (isPaymentExist && hasParamsThreeDs(callbackPaymentRes)) {
      verifyThreeDSAddonAction({
        paymentData,
        verifyData: { 
          md, 
          paRes 
        },
        isVerifyForPayment: true,
        addonIds,
      });
    } else if (
      hasPaymentMethodType(callbackPaymentRes) && 
      hasPayload(callbackPaymentRes)
    ) {
      verifyThreeDSAddonAction({
        paymentData,
        verifyData: { 
          details: { 
            payload
          } 
        },
        isVerifyForPayment: true,
        addonIds,
      });
    } else if (
      hasPaymentMethodType(callbackPaymentRes) && 
      hasRedirectResult(callbackPaymentRes)
    ) {
      verifyThreeDSAddonAction({
        paymentData,
        verifyData: { 
          details: { 
            redirectResult 
          } 
        },
        isVerifyForPayment: true,
        addonIds,
      });
    }
  }

  const handleVerifyThreeDSMakePaymentExistCard = (
    isPaymentExist: boolean, 
    callbackPaymentRes: any
  ) => {
    const {
      md, 
      paRes, 
      payload
    } = callbackPaymentRes;
    const paymentData = localStorage.getItem(PERSONAL_CONSTANTS.PAYMENT_DATA) || '';

    if (isPaymentExist && hasParamsThreeDs(callbackPaymentRes)) {
      verifyThreeDSRegisterCardAddonAction({
        paymentData,
        verifyData: { 
          md, 
          paRes 
        },
        isVerifyForPayment: true,
        addonIds,
      });
    } else if (
      hasPaymentMethodType(callbackPaymentRes) && 
      hasPayload(callbackPaymentRes) && 
      hasResultCode(callbackPaymentRes)
    ) {
      verifyThreeDSRegisterCardAddonAction({
        paymentData,
        verifyData: { 
          details: { 
            payload
          } 
        },
        isVerifyForPayment: true,
        addonIds,
      });
    }
  }

  useEffect(() => {
    if (callbackPaymentRes && isFirstTimeCallback && addonIds) {
      setIsFirstTimeCallback(false);
      const paymentData = localStorage.getItem(PERSONAL_CONSTANTS.PAYMENT_DATA) || '';
      const isPaymentExist: boolean = paymentData.length > 0;
      const processPurpose = getProcessPurpose();

      switch(processPurpose) { 
        case PAYMENT_PURPOSES.MAKE_PAYMENT: 
          handleVerifyThreeDSMakePayment(isPaymentExist, callbackPaymentRes)
          break;
        case PAYMENT_PURPOSES.MAKE_PAYMENT_EXIST_CARD:
          handleVerifyThreeDSMakePaymentExistCard(isPaymentExist, callbackPaymentRes);
          break;
      }
    }
  }, [callbackPaymentRes, addonIds]);

  useEffect(() => {
    if (
      (getProcessPurpose() === PAYMENT_PURPOSES.MAKE_PAYMENT_EXIST_CARD ||
        getProcessPurpose() === PAYMENT_PURPOSES.MAKE_PAYMENT) &&
      isVerifySuccess
    ) {
      handleLeaveMakePayment();
    }
  }, [isVerifySuccess]);

  useEffect(() => {
    if (error && getProcessPurpose() === PAYMENT_PURPOSES.MAKE_PAYMENT_EXIST_CARD) {
      setShowExistCard(true);
    }
  }, [error]);

  useEffect(() => {
    if (error) {
      allowProccessPaymentRef.current = true;
      setShowRegisterCardFragment(true);
    }
  }, [error]);

  useEffect(() => {
    const initAdyenCheckout = async () => {
      if (
        subTotal &&
        paymentMethodsResponse &&
        !makePaymentSuccess &&
        !document.getElementById('dropin-container')!.hasChildNodes()
      ) {
        setShowRegisterCardFragment(true);
  
        const configuration: any = {
          paymentMethodsResponse: paymentMethodsResponse,
          clientKey: _env.ADYEN_KEY,
          locale: PERSONAL_CONSTANTS.EN_US,
          translations: translations(t, true),
          environment: _env.ADYEN_ENV,
          setStatusAutomatically: false,
          onSubmit: (state: any, component: any) => {
            if (!allowProccessPaymentRef.current) {
              return;
            }
            const { paymentMethod, shopperName, shopperEmail, browserInfo } = state?.data;
            let paymentMethodCustom = { ...paymentMethod };
            if (shopperEmail) {
              paymentMethodCustom = { ...paymentMethod, ...shopperName, shopperEmail };
            }
            if (state.isValid) {
              if (sessionStorage.getItem(SESSION_STORAGE_NAMES.AGREE_TNC) === ANSWER.YES) {
                setShowRegisterCardFragment(false);
  
                const payment = {
                  paymentMethod: paymentMethodCustom,
                  browserInfo: browserInfo,
                  paymentType: paymentMethod.type,
                };
                if (paymentMethod) {
                  if (shopperEmail) {
                    if (membershipDetails.emailAddress?.toLowerCase() === shopperEmail?.toLowerCase()) {
                      gtag('event', 'Button', {
                        event_action: 'MBAProceed',
                        event_label: 'MBAProceedClicked',
                      });
                      setProcessPurpose(PAYMENT_PURPOSES.MAKE_PAYMENT);
                      makePaymentAddonAction(subTotal, payment, false, component, addonIds);
                      allowProccessPaymentRef.current = false;
                    } else {
                      makePaymentFailAction(t('ONLINE_PAYMENT_EMAIL_NOT_MATCH_EMAIL_DETAIL'));
                    }
                  } else {
                    gtag('event', 'Button', {
                      event_action: 'MBAProceed',
                      event_label: 'MBAProceedClicked',
                    });
                    setProcessPurpose(PAYMENT_PURPOSES.MAKE_PAYMENT);
                    makePaymentAddonAction(subTotal, payment, false, component, addonIds);
                    allowProccessPaymentRef.current = false;
                  }
                }
              } else {
                scrollAndSetErrorTermsAndConditions();
              }
            } else {
              if (sessionStorage.getItem(SESSION_STORAGE_NAMES.AGREE_TNC) !== ANSWER.YES) {
                scrollAndSetErrorTermsAndConditions();
              }
            }
          },
          onChange: (state: any, dropin: any) => {
            const { isValid } = state;
            const verifyButton: any = document.getElementsByClassName('adyen-checkout__button')[0];
            if (verifyButton) {
              if (isValid) {
                verifyButton.classList.remove('adyen-checkout__button--gray-out');
              } else {
                verifyButton.classList.add('adyen-checkout__button--gray-out');
              }
            }
            allowProccessPaymentRef.current = true;
          },
  
          onError: (err: any) => {
            console.log('Error - ', err);
          },
          paymentMethodsConfiguration: {
            paywithgoogle: {
              configuration: {
                merchantName: _env.GOOGLE_MERCHANT_NAME,
                merchantId: _env.GOOGLE_MERCHANT_ID,
                gatewayMerchantId: _env.GOOGLE_GATEWAY_MERCHANT_ID,
              },
              amount: {
                value: Number(subTotal) * _env.MINOR_UNIT[_env.COUNTRY_CODE],
                currency: membershipDetails.currencyCode,
              },
              //Set this to PRODUCTION when you're ready to accept live payments
              environment: _env.ADYEN_ENV,
            },
            applepay: {
              amount: {
                value: Number(subTotal) * _env.MINOR_UNIT[_env.COUNTRY_CODE],
                currency: membershipDetails.currencyCode,
              },
              configuration: {
                merchantName: _env.APPLE_MERCHANT_NAME,
                merchantId: _env.APPLE_MERCHANT_ID,
              },
            },
            card: {
              hasHolderName: true,
              holderNameRequired: true,
              enableStoreDetails: true,
              positionHolderNameOnTop: false,
              hideCVC: false,
              name: t('SS_PAYMENT_PAYMENT_METHOD'),
            },
          },
        };
        const checkout = await AdyenCheckout(configuration);
        if(elementExistInDom("#dropin-container")) {
          checkout
          .create('dropin', {
            onSelect: (component: any) => {
              setShowCheckboxSaveForNext(component.props.name === t('SS_PAYMENT_PAYMENT_METHOD'));
            },
          })
          .mount('#dropin-container');
        }
  
        setImmediate(() => {
          (document.getElementsByClassName('adyen-checkout__button--pay')[0] as HTMLInputElement).disabled =
            !!finalTotalRecurring;
        });
      }
    }
    initAdyenCheckout();
  }, [makePaymentSuccess, paymentMethodsResponse, subTotal, finalTotalRecurring]);

  useEffect(() => {
    if ((editPaymentSuccess || makePaymentSuccess) && Object.keys(paymentResponse).length !== 0) {
      setIsVerifySuccess(true);
    }
  }, [paymentResponse, editPaymentSuccess, error]);

  useEffect(() => {
    //3DS-2
    const init3DS2 = async () =>  {
      if (
        paymentAction &&
        makePaymentSuccess &&
        getProcessPurpose() === PAYMENT_PURPOSES.MAKE_PAYMENT &&
        !isVerifySuccess
      ) {
        const configuration = {
          locale: 'en_US',
          environment: _env.ADYEN_ENV,
          clientKey: _env.ADYEN_KEY,
          onAdditionalDetails: handleMakePaymentOnAdditionalDetails,
        };
        const checkout = await AdyenCheckout(configuration);
        if(elementExistInDom('#dropin-container')) {
            checkout &&
              checkout
              .createFromAction({
                ...paymentAction
              }, {
                size: '05',
              })
              .mount('#dropin-container');
        }
      }
  
      if (
        paymentAction &&
        payWithRegisterCardSuccess &&
        getProcessPurpose() === PAYMENT_PURPOSES.MAKE_PAYMENT_EXIST_CARD &&
        !isVerifySuccess
      ) {
        const configuration = {
          locale: 'en_US',
          environment: _env.ADYEN_ENV,
          clientKey: _env.ADYEN_KEY,
          onAdditionalDetails: handleRegisterCardOnAdditionalDetails,
        };
  
        const checkout = await AdyenCheckout(configuration);
        if(elementExistInDom('#dropin-container1')) {
          checkout &&
            checkout
              .createFromAction({
                ...paymentAction
              }, {
                size: '05',
              })
              .mount('#dropin-container1');
        }
        setShowExistCard(false);
      }
    }
    init3DS2();
  }, [paymentAction, isVerifySuccess, payWithRegisterCardSuccess, makePaymentSuccess]);

  const handleMakePaymentOnAdditionalDetails = (state: any, _: any) => {
    verifyThreeDSAddonAction({
      verifyData: { details: state.data.details },
      paymentData: state.data.paymentData,
      isVerifyForPayment: true,
      addonIds,
    });
  };

  const handleRegisterCardOnAdditionalDetails = (state: any, _: any) => {
    verifyThreeDSRegisterCardAddonAction({
      verifyData: { details: state.data.details },
      paymentData: state.data.paymentData,
      isVerifyForPayment: true,
      addonIds,
    });
  };

  useEffect(() => {
    if (paymentData) {
      localStorage.setItem(SESSION_STORAGE_NAMES.PAYMENT_DATA, paymentData);
    }
  }, [paymentData]);

  const handleLeaveMakePayment = () => {
    if (callbackConfirm) {
      callbackConfirm();
    } else {
      history.push(MEMBERSHIP_URL);
    }
  };

  const setProcessPurpose = (value: string) => {
    sessionStorage.setItem(SESSION_STORAGE_NAMES.PROCESS_PURPOSE, value);
  };

  const getProcessPurpose = () => {
    return sessionStorage.getItem(SESSION_STORAGE_NAMES.PROCESS_PURPOSE);
  };

  const triggerOpenLeavePaymentConfirm = () => {
    setOpenModalAction(true);
  };

  const handlePayWithExistingCard = () => {
    payArrearsAction(subTotal, addonIds);
    setProcessPurpose(PAYMENT_PURPOSES.MAKE_PAYMENT_EXIST_CARD);
  };

  if (Object.keys(membershipDetails).length === 0) {
    return null;
  }
  if (!subTotal)
    return (
      <div className="addon-payment-container--loading">
        <CircularProgress />
      </div>
    );

  return (
    <>
      <div className="addon-payment-container">
        <ListAddOnPayment
          showErrorTnC={showErrorTnC}
          setShowErrorTnc={setShowErrorTnc}
          termsAndConditionsErrorRef={termsAndConditionsErrorRef}
          addons={addons}
          subTotal={subTotal}
          recurringAmount={recurringAmount}
        />
        <PaymentMerchant
          setOpenConfirmPayExistCard={setOpenConfirmPayExistCard}
          showExistCard={showExistCard}
          showRegisterCardFragment={showRegisterCardFragment}
          scrollAndSetErrorTermsAndConditions={scrollAndSetErrorTermsAndConditions}
          finalTotalRecurring={finalTotalRecurring}
          showCheckboxSaveForNext={showCheckboxSaveForNext}
        />
        <div className={isMobile ? 'payment-bottom-button' : 'payment-bottom-button-desktop'}>
          <button tabIndex={8} className="payment-bottom-button__cancel" onClick={triggerOpenLeavePaymentConfirm}>
            {t('SS_CANCEL')}
          </button>
        </div>
      </div>

      <ConfirmModal
        openModal={isOpenConfirm}
        handleOpenModal={setOpenModalAction}
        displayMessage={t('SS_CONFIRM_MESS')}
        confirmLabel={t('SS_LEAVE')}
        cancelLabel={t('SS_CANCEL')}
        confirmAction={() => {
          handleLeaveMakePayment();
          clearPaymentAction(true);
          sessionStorage.removeItem(SESSION_STORAGE_NAMES.PAYMENT_TYPE);
          sessionStorage.removeItem(SESSION_STORAGE_NAMES.PROCESS_PURPOSE);
          sessionStorage.removeItem(SESSION_STORAGE_NAMES.AGREE_TNC);

          if (membershipDetails.currentBalance < 0) {
            sessionStorage.setItem(SESSION_STORAGE_NAMES.AMOUNT_VALUE, `${-membershipDetails.currentBalance}`);
          } else {
            sessionStorage.removeItem(SESSION_STORAGE_NAMES.AMOUNT_VALUE);
          }
        }}
      />

      <ConfirmModal
        openModal={openConfirmPayExistCard}
        handleOpenModal={setOpenConfirmPayExistCard}
        displayMessage={t('SS_PAYMENT_CONFIRM_PAY_EXISTING_CARD')}
        confirmLabel={t('SS_YES')}
        cancelLabel={t('SS_CANCEL')}
        confirmAction={handlePayWithExistingCard}
      />
    </>
  );
};

export default AddonPayment;
