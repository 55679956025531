import { combineReducers } from 'redux';

import { IUser } from 'types/IUser';

import paymentReducer from './payment';
import membershipReducer from './membership';
import loginReducer from './login';
import personalReducer from './personal';
import themeReducer from './theme';
import notificationReducer from './notification';
import systemReducer from './system';
export interface RootState {
  login: any;
  oidc: {
    user: IUser;
    isLoadingUser: boolean;
  };
  payment: {
    paymentMethodsResponse: any;
    loadingCallback: boolean;
    editPaymentSuccess: boolean;
    payWithRegisterCardSuccess: boolean;
    doneUpdatePayment: boolean;
    paymentResponse: any;
    paymentData: string;
    error: any;
    isOpenConfirm: boolean;
    callbackConfirm: Function;
    paymentAction: any;
    callbackPaymentRes: any;
    arrearInfo: any;
    loadingPayArrears: boolean;
    makePaymentSuccess: boolean;
    globalError: any;
    globalErrorGUID: any;
    doneMakePayment: string;
    doneMakePaymentWithReceived: string;
    donePayWithRegisterCard: string;
  };
  membership: {
    membershipDetails: any;
    isLoading: boolean;
    error: any;
    brands: any;
    onlinePaymentMethod: string;
    totalRecurringFee: number;
  };
  personal: {
    isEditSuccess: boolean;
    error: any;
    regexs: Array<{ key: string; value: string }>;
    fields: Array<{
      id: number;
      tenantId: string;
      subTenantId: string;
      name: string;
      isVisible: boolean;
      isRequired: boolean;
      editable: boolean;
      maxLength?: number;
      key: string;
      children: any;
      formatDateTime?: string;
    }>;
  };
  theme: {
    isLoading: boolean;
    cssUrl: string;
    error: any;
  };
  notification: {
    title: string;
    message: string;
    type: string;
    id: string;
    isSuccess: boolean;
    isConnectedSignalR: boolean;
    options?: {
      [key: string]: any;
    };
  };
  system: {
    isBillingNow: boolean;
  };
}

const rootReducer = (oidcReducer: any) =>
  combineReducers({
    oidc: oidcReducer,
    payment: paymentReducer,
    membership: membershipReducer,
    login: loginReducer,
    personal: personalReducer,
    theme: themeReducer,
    notification: notificationReducer,
    system: systemReducer,
  });

export default rootReducer;
