import { paymentActions as actionCreators } from '../actionCreators';
import { paymentActionTypes as actionTypes } from '../actionTypes';
import { DOMAIN_URL } from 'constants/baseConstants';
import { ANSWER } from 'constants/commonContants';
import { ADYEN_RESULT_CODE, DONE_MAKE_PAYMENT_TYPES } from 'constants/paymentConstants';
import { SESSION_STORAGE_NAMES } from 'constants/personalConstant';
import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects';
import { callbackPaymentService, editPaymentService, getArrearInfoService, getPaymentMethodService, makePaymentService, payWithRegisterCardService, verifyAfterThreeDSService } from 'services';
import { verifyAfterThreeDSEditPaymentService } from 'services/payment';
import collectBrowserInfo from 'utility/browserInfo';


function* getPaymentMethod({ memberId }: { type: string; memberId: string }) {
  try {
    const { data } = yield call(getPaymentMethodService, memberId);
    if (data.paymentMethods) {
      yield put(
        actionCreators.getPaymentMethodSuccess({
          ...data,
          paymentMethods: data.paymentMethods.sort((pm: any) => (pm.type === 'scheme' ? -1 : 1)),
        })
      );
    }
  } catch (error) { }
}

function* editPayment({ membershipDetails, dataState, dropinForm }: any) {
  try {
    const body = {
      clubId: membershipDetails.clubId,
      membershipNumber: membershipDetails.memberFirstId,
      countryCode: membershipDetails.countryCode,
      currentBalance: membershipDetails.currentBalance,
      origin: DOMAIN_URL, //The origin URL of the page where you are rendering the Drop-in.
      amount: {
        currency: membershipDetails.currencyCode, //Currency Code
        value: 0, // For verification, charge amount of 0
      },
      chanel: 'Web',
      returnUrl: `${DOMAIN_URL}/payment`, // Return URL for 3DS verification
      additionalData: {
        allow3DS2: true, // If set to true, 3DS uses fingerprint verification
      },
      //paymentMethod get from Drop-In UI
      paymentMethod: dataState.paymentMethod,
      redirectFromIssuerMethod: 'GET',
      //browserInfo get from Drop-In UI
      browserInfo: dataState.browserInfo,
      shopperEmail: membershipDetails.emailAddress,
    };
    yield put(actionCreators.clearPaymentState(true));
    dropinForm.setStatus('loading');
    const { data } = yield call(editPaymentService, membershipDetails.memberId, body);
    switch (true) {
      case !data:
        yield put(actionCreators.editPaymentFail(data.result.errorCode));
        break;
      case data.isSuccess:
        sessionStorage.removeItem(SESSION_STORAGE_NAMES.PROCESS_PURPOSE);
        yield put(actionCreators.doneUpdatePayment());
        break;
      case data.checkAllowPaymentMessage !== '':
        yield put(actionCreators.editPaymentFail(data.checkAllowPaymentMessage));
        dropinForm.setStatus('ready');
        break;
      case !data.data.resultCode:
      case data.data.resultCode === ADYEN_RESULT_CODE.REFUSED:
      case data.data.resultCode === ADYEN_RESULT_CODE.ONE_0_ONE:
        yield put(actionCreators.editPaymentFail(data.data.result.errorCode));
        dropinForm.setStatus('ready');
        break;
      case data.data.resultCode === ADYEN_RESULT_CODE.REDIRECT_SHOPPER:
      case data.data.resultCode === ADYEN_RESULT_CODE.IDENTIFY_SHOPPER:
      case data.data.resultCode === ADYEN_RESULT_CODE.CHALLENGE_SHOPPER:
        yield put(actionCreators.editPaymentSuccess(data.data));
        break;
      default:
        yield put(actionCreators.editPaymentFail(data.data.result.errorCode));
        break;
    }
  } catch (error: any) {
    dropinForm.setStatus('ready');
    yield put(actionCreators.editPaymentFail(error.message));
  }
}

function* verifyAfterThreeDS({ paymentData, verifyData, isVerifyForPayment, addonIds, upgradedMembership }: any) {
  try {
    const { md: MD, paRes: PaRes, details } = verifyData;
    const body = MD && PaRes ? { MD, PaRes } : details;
    const { membershipDetails } = yield select((state) => state.membership);
    const verifyBody = {
      paymentData,
      details: body,
      clubId: membershipDetails.clubId,
      enableRecurring: sessionStorage.getItem(SESSION_STORAGE_NAMES.SAVE_FOR_NEXT_RECURRING) === ANSWER.YES,
      paymentMethodType: sessionStorage.getItem(SESSION_STORAGE_NAMES.PAYMENT_TYPE),
      isMakePayment: !!isVerifyForPayment,
      ...(addonIds && addonIds.length > 0 ? { addonIds } : {}),
      ...(upgradedMembership ? { upgradedMembership } : {}),
    };
    const { data } = yield call(verifyAfterThreeDSService, membershipDetails.memberId, verifyBody);
    switch (true) {
      case !data:
        yield put(actionCreators.makePaymentFail(data.result.errorCode));
        break;
      case data.resultCode === ADYEN_RESULT_CODE.AUTHORISED:
        sessionStorage.removeItem(SESSION_STORAGE_NAMES.PROCESS_PURPOSE);
        sessionStorage.removeItem(SESSION_STORAGE_NAMES.PAYMENT_TYPE);
        sessionStorage.removeItem(SESSION_STORAGE_NAMES.AMOUNT_VALUE);
        sessionStorage.removeItem(SESSION_STORAGE_NAMES.SAVE_FOR_NEXT_RECURRING);
        sessionStorage.removeItem(SESSION_STORAGE_NAMES.AGREE_TNC);
        yield put(actionCreators.makePaymentSuccess(data));
        yield put(actionCreators.clearPaymentState(true));
        yield put(
          actionCreators.doneMakePayment(
            (addonIds && DONE_MAKE_PAYMENT_TYPES.ADDONS) ||
            (upgradedMembership && DONE_MAKE_PAYMENT_TYPES.UPGRADE) ||
            DONE_MAKE_PAYMENT_TYPES.NORMAL
          )
        );
        break;
      case data.resultCode === ADYEN_RESULT_CODE.RECEIVED:
        sessionStorage.removeItem(SESSION_STORAGE_NAMES.PROCESS_PURPOSE);
        sessionStorage.removeItem(SESSION_STORAGE_NAMES.PAYMENT_TYPE);
        sessionStorage.removeItem(SESSION_STORAGE_NAMES.AMOUNT_VALUE);
        sessionStorage.removeItem(SESSION_STORAGE_NAMES.SAVE_FOR_NEXT_RECURRING);
        sessionStorage.removeItem(SESSION_STORAGE_NAMES.AGREE_TNC);
        yield put(actionCreators.makePaymentSuccess(data));
        yield put(actionCreators.clearPaymentState(true));
        yield put(
          actionCreators.doneMakePaymentWithReceived(
            (addonIds && DONE_MAKE_PAYMENT_TYPES.ADDONS) ||
            (upgradedMembership && DONE_MAKE_PAYMENT_TYPES.UPGRADE) ||
            DONE_MAKE_PAYMENT_TYPES.NORMAL
          )
        );
        break;
      case data.resultCode === ADYEN_RESULT_CODE.CHALLENGE_SHOPPER:
        yield put(actionCreators.makePaymentSuccess(data));
        break;
      default:
        yield put(actionCreators.makePaymentFail(data.result.errorCode));
        break;
    }
  } catch (error: any) {
    yield put(actionCreators.makePaymentFail(error.message));
  }
}

function* verifyAfterThreeDSRegisterCard({
  paymentData,
  verifyData,
  isVerifyForPayment,
  addonIds,
  upgradedMembership,
}: any) {
  try {
    const { md: MD, paRes: PaRes, details } = verifyData;
    const body = MD && PaRes ? { MD, PaRes } : details;
    const { membershipDetails } = yield select((state) => state.membership);
    const verifyBody = {
      paymentData,
      details: body,
      clubId: membershipDetails.clubId,
      enableRecurring: sessionStorage.getItem(SESSION_STORAGE_NAMES.SAVE_FOR_NEXT_RECURRING) === ANSWER.YES,
      paymentMethodType: sessionStorage.getItem(SESSION_STORAGE_NAMES.PAYMENT_TYPE),
      isMakePayment: !!isVerifyForPayment,
      ...(addonIds && addonIds.length > 0 ? { addonIds } : {}),
      ...(upgradedMembership ? { upgradedMembership } : {}),
    };
    const { data } = yield call(verifyAfterThreeDSService, membershipDetails.memberId, verifyBody);
    switch (true) {
      case !data:
        yield put(actionCreators.payWithRegisterCardFail(data.result.errorCode));
        break;
      case data.resultCode === ADYEN_RESULT_CODE.REFUSED:
      case data.resultCode === ADYEN_RESULT_CODE.ONE_0_ONE:
        yield put(actionCreators.clearPaymentState(true));
        yield put(actionCreators.payWithRegisterCardFail(data.result.errorCode));
        break;
      case data.resultCode === ADYEN_RESULT_CODE.AUTHORISED:
        sessionStorage.removeItem(SESSION_STORAGE_NAMES.PROCESS_PURPOSE);
        sessionStorage.removeItem(SESSION_STORAGE_NAMES.PAYMENT_TYPE);
        sessionStorage.removeItem(SESSION_STORAGE_NAMES.AMOUNT_VALUE);
        sessionStorage.removeItem(SESSION_STORAGE_NAMES.SAVE_FOR_NEXT_RECURRING);
        sessionStorage.removeItem(SESSION_STORAGE_NAMES.AGREE_TNC);
        yield put(actionCreators.payWithRegisterCardSuccess(data));
        yield put(actionCreators.clearPaymentState(true));
        yield put(
          actionCreators.donePayWithRegisterCard(
            (addonIds && DONE_MAKE_PAYMENT_TYPES.ADDONS) ||
            (upgradedMembership && DONE_MAKE_PAYMENT_TYPES.UPGRADE) ||
            DONE_MAKE_PAYMENT_TYPES.NORMAL
          )
        );
        break;
      case data.resultCode === ADYEN_RESULT_CODE.CHALLENGE_SHOPPER:
        yield put(actionCreators.payWithRegisterCardSuccess(data));
        break;
      default:
        yield put(actionCreators.payWithRegisterCardFail(data.result.errorCode));
        break;
    }
  } catch (error: any) {
    yield put(actionCreators.payWithRegisterCardFail(error.message));
  }
}

function* verifyAfterThreeDSEditPayment({
  paymentData,
  verifyData,
}: {
  type: string;
  paymentData: string;
  verifyData: any;
}) {
  try {
    const { md: MD, paRes: PaRes, details } = verifyData;
    const body = MD && PaRes ? { MD, PaRes } : details;
    const { membershipDetails } = yield select((state) => state.membership);
    const submitBody = {
      paymentData,
      details: body,
    };
    const { data } = yield call(verifyAfterThreeDSEditPaymentService, membershipDetails.memberId, submitBody);
    switch (true) {
      case data.isSuccess:
        sessionStorage.removeItem(SESSION_STORAGE_NAMES.PROCESS_PURPOSE);
        yield put(actionCreators.editPaymentSuccess(data));
        yield put(actionCreators.doneUpdatePayment());
        break;
      case data.checkAllowPaymentMessage !== '':
        yield put(actionCreators.editPaymentFail(data.checkAllowPaymentMessage));
        break;
      case !data.data:
        yield put(actionCreators.editPaymentFail(data.data.result.errorCode));
        break;
      case data.data.resultCode === ADYEN_RESULT_CODE.CHALLENGE_SHOPPER:
        yield put(actionCreators.editPaymentSuccess(data.data));
        break;
      default:
        yield put(actionCreators.editPaymentFail(data.data.result.errorCode));
        break;
    }
  } catch (error: any) {
    yield put(actionCreators.editPaymentFail(error.message));
  }
}

function* callbackPayment({ token }: { type: string; token: string }) {
  try {
    const { data, status } = yield call(callbackPaymentService, token);
    if (data && status === 200) {
      yield put(actionCreators.callbackPaymentSuccess(data));
    }
  } catch (error: any) {
    yield put(actionCreators.callbackPaymentFail({ error: error.response }));
  }
}

function* getArrearsInformation({ memberId }: { type: string; memberId: string }) {
  try {
    const { data, status } = yield call(getArrearInfoService, memberId);
    if (status === 200 && data) {
      yield put(actionCreators.getArrearInformationSuccess(data));
    }
  } catch (error) { }
}

const switchRedirectForPayment = (addonIds: [], upgradedMembership: any) => {
  switch (true) {
    case !!upgradedMembership:
      return `${DOMAIN_URL}/upgrade-membership/step-3`;
    case addonIds && addonIds.length > 0:
      return `${DOMAIN_URL}/addon-payment`;
    default:
      return `${DOMAIN_URL}/payment`;
  }
};

function* payWithRegisterCard({ amount, addonIds, upgradedMembership }: any) {
  try {
    yield put(actionCreators.clearPaymentState(true));
    const browserInf = collectBrowserInfo();

    const { membershipDetails } = yield select((state) => state.membership);
    const browserInfo = {
      acceptHeader: '*/*',
      colorDepth: browserInf.colorDepth,
      javaEnabled: browserInf.javaEnabled,
      language: browserInf.language,
      screenHeight: browserInf.screenHeight,
      screenWidth: browserInf.screenWidth,
      timeZoneOffset: browserInf.timeZoneOffset,
      userAgent: browserInf.userAgent,
    };
    const configMustHave = {
      origin: DOMAIN_URL,
      amount,
      captureDelayHours: _env.CAPTURE_DELAY_HOURS,
      membershipNumber: membershipDetails.memberFirstId,
      clubId: membershipDetails.clubId,
      chanel: 'Web',
      countryCode: membershipDetails.countryCode,
      additionalData: {
        allow3DS2: true,
      },
      redirectFromIssuerMethod: 'GET',
      currentBalance: membershipDetails.currentBalance,
      enableRecurring: false,
      browserInfo,
      paymentMethod: {
        type: 'scheme',
      },
      shopperEmail: membershipDetails.emailAddress,
    };
    const payBody = {
      ...(addonIds && addonIds.length > 0 ? { addonIds } : {}),
      ...(upgradedMembership ? { upgradedMembership } : {}),
      ...configMustHave,
      returnUrl: switchRedirectForPayment(addonIds, upgradedMembership),
    };
    const { data } = yield call(payWithRegisterCardService, membershipDetails.memberId, payBody);
    sessionStorage.setItem(SESSION_STORAGE_NAMES.PAYMENT_TYPE, 'scheme');
    switch (true) {
      case !data:
        yield put(actionCreators.payWithRegisterCardFail(data.result.errorCode));
        break;
      case !data.resultCode:
      case data.resultCode === ADYEN_RESULT_CODE.REFUSED:
      case data.resultCode === ADYEN_RESULT_CODE.ONE_0_ONE:
        yield put(actionCreators.payWithRegisterCardFail(data.result.errorCode));
        break;
      case data.resultCode === ADYEN_RESULT_CODE.AUTHORISED:
        sessionStorage.removeItem(SESSION_STORAGE_NAMES.PROCESS_PURPOSE);
        sessionStorage.removeItem(SESSION_STORAGE_NAMES.PAYMENT_TYPE);
        sessionStorage.removeItem(SESSION_STORAGE_NAMES.AGREE_TNC);
        yield put(actionCreators.payWithRegisterCardSuccess(data));
        yield put(actionCreators.clearPaymentState(true));
        yield put(
          actionCreators.donePayWithRegisterCard(
            (addonIds && DONE_MAKE_PAYMENT_TYPES.ADDONS) ||
            (upgradedMembership && DONE_MAKE_PAYMENT_TYPES.UPGRADE) ||
            DONE_MAKE_PAYMENT_TYPES.NORMAL
          )
        );
        break;
      case data.resultCode === ADYEN_RESULT_CODE.REDIRECT_SHOPPER:
        sessionStorage.setItem(SESSION_STORAGE_NAMES.AMOUNT_VALUE, amount.toString());
        yield put(actionCreators.payWithRegisterCardSuccess(data));
        break;
      case data.resultCode === ADYEN_RESULT_CODE.IDENTIFY_SHOPPER:
      case data.resultCode === ADYEN_RESULT_CODE.CHALLENGE_SHOPPER:
        yield put(actionCreators.payWithRegisterCardSuccess(data));
        break;
      default:
        yield put(actionCreators.payWithRegisterCardFail(data.result.errorCode));
        break;
    }
  } catch (error: any) {
    if (error.response?.data && error.response?.data.isSuccess) {
      yield put(actionCreators.payWithRegisterCardFail(error.response.data.message));
    } else {
      yield put(actionCreators.payWithRegisterCardFail(error.message));
    }
  }
}

function* makePayment({ amountValue, payment, enableRecurring, dropinForm, addonIds, upgradedMembership }: any) {
  try {
    const { membershipDetails } = yield select((state) => state.membership);
    const configMustHave = {
      paymentMethod: payment.paymentMethod,
      browserInfo: payment.browserInfo,
      origin: DOMAIN_URL,
      amount: {
        value: amountValue,
        currency: membershipDetails.currencyCode,
      },
      clientStateDataIndicator: true,
      enableRecurring: enableRecurring,
      captureDelayHours: _env.CAPTURE_DELAY_HOURS,
      membershipNumber: membershipDetails.memberFirstId,
      clubId: membershipDetails.clubId,
      chanel: 'Web',
      countryCode: membershipDetails.countryCode,
      additionalData: {
        allow3DS2: true,
      },
      redirectFromIssuerMethod: 'GET',
      currentBalance: membershipDetails.currentBalance,
      shopperEmail: membershipDetails.emailAddress,
    };
    const body = {
      ...configMustHave,
      // Make payment for Buy Add-ons
      ...(addonIds && addonIds.length > 0 ? { addonIds } : {}),
      ...(upgradedMembership ? { upgradedMembership } : {}),
      // Return URL for 3DS verification
      returnUrl: switchRedirectForPayment(addonIds, upgradedMembership),
    };

    sessionStorage.setItem(SESSION_STORAGE_NAMES.PAYMENT_TYPE, payment.paymentType);
    dropinForm.setStatus('loading');
    yield put(actionCreators.clearPaymentState(true));
    const { data } = yield call(makePaymentService, membershipDetails.memberId, body);
    switch (true) {
      case !data:
        yield put(actionCreators.editPaymentFail(data.result.errorCode));
        break;
      case !data.resultCode:
      case data.resultCode === ADYEN_RESULT_CODE.REFUSED:
      case data.resultCode === ADYEN_RESULT_CODE.ONE_0_ONE:
        yield put(actionCreators.makePaymentFail(data.result.errorCode));
        dropinForm.setStatus('ready');
        break;
      case data.resultCode === ADYEN_RESULT_CODE.REDIRECT_SHOPPER:
        sessionStorage.setItem(SESSION_STORAGE_NAMES.SAVE_FOR_NEXT_RECURRING, enableRecurring ? ANSWER.YES : ANSWER.NO);
        sessionStorage.setItem(SESSION_STORAGE_NAMES.AMOUNT_VALUE, amountValue);
        yield put(actionCreators.makePaymentSuccess(data));
        dropinForm.setStatus('ready');
        break;
      case data.resultCode === ADYEN_RESULT_CODE.AUTHORISED:
        sessionStorage.removeItem(SESSION_STORAGE_NAMES.PROCESS_PURPOSE);
        sessionStorage.removeItem(SESSION_STORAGE_NAMES.PAYMENT_TYPE);
        sessionStorage.removeItem(SESSION_STORAGE_NAMES.AGREE_TNC);
        sessionStorage.removeItem(SESSION_STORAGE_NAMES.SAVE_FOR_NEXT_RECURRING);
        yield put(actionCreators.makePaymentSuccess(data));
        yield put(actionCreators.clearPaymentState(true));
        yield put(
          actionCreators.doneMakePayment(
            (addonIds && DONE_MAKE_PAYMENT_TYPES.ADDONS) ||
            (upgradedMembership && DONE_MAKE_PAYMENT_TYPES.UPGRADE) ||
            DONE_MAKE_PAYMENT_TYPES.NORMAL
          )
        );
        dropinForm.setStatus('ready');
        break;
      case data.resultCode === ADYEN_RESULT_CODE.IDENTIFY_SHOPPER:
      case data.resultCode === ADYEN_RESULT_CODE.CHALLENGE_SHOPPER:
      case data.resultCode === ADYEN_RESULT_CODE.PRESENT_TO_SHOPPER:
        yield put(actionCreators.makePaymentSuccess(data));
        dropinForm.setStatus('ready');
        break;
      default:
        yield put(actionCreators.makePaymentFail(data.result.errorCode));
        break;
    }
  } catch (error: any) {
    dropinForm.setStatus('ready');
    yield put(actionCreators.makePaymentFail(error.response?.data.message || error.message));
  }
}

function* watchPayment() {
  yield takeLatest(actionTypes.GET_PAYMENT_METHOD, getPaymentMethod);
  yield takeLatest(actionTypes.EDIT_PAYMENT, editPayment);
  yield takeLatest(actionTypes.VERIFY_AFTER_THREEDS_EDIT_PAYMENT, verifyAfterThreeDSEditPayment);
  yield takeLatest(actionTypes.VERIFY_AFTER_THREEDS, verifyAfterThreeDS);
  yield takeLatest(actionTypes.VERIFY_AFTER_THREEDS_REGISTER_CARD, verifyAfterThreeDSRegisterCard);
  yield takeLatest(actionTypes.CALLBACK_PAYMENT, callbackPayment);
  yield takeLatest(actionTypes.GET_ARREARS_INFOMATION, getArrearsInformation);
  yield takeLatest(actionTypes.PAY_WITH_REGISTER_CARD, payWithRegisterCard);
  yield takeLatest(actionTypes.MAKE_PAYMENT, makePayment);
}

export default function* paymentSaga() {
  yield all([fork(watchPayment)]);
}