import { Length, PlanProps, PreviewPaymentSummaryProps, SelectedDataProps, SingleAccessLevel } from './types';
import {
  MEMBERSHIP_URL,
  UPGRADE_MEMBERSHIP_CONFIRM_PAYMENT_URL,
  UPGRADE_MEMBERSHIP_STEP0_URL,
  UPGRADE_MEMBERSHIP_STEP1_URL,
  UPGRADE_MEMBERSHIP_STEP2_URL,
  UPGRADE_MEMBERSHIP_STEP3_URL,
} from 'utility/routesURL';
import React, { SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { Route, Switch, useHistory, useLocation, useRouteMatch } from 'react-router';
import { clearPaymentState, getPaymentMethod } from 'redux/actionCreators/payment';
import { getPlanData, getPreviewData } from 'services/upgradeMembership';
import PERSONAL_CONSTANTS, {
  SESSION_STORAGE_NAMES,
  SYSTEM_DATE_FORMAT,
} from 'constants/personalConstant';

import { CircularProgress } from '@material-ui/core';
import Footer from './Footer';
import ModalConFirm from 'components/ModalConfirm';
import PackagePreview from './PackagePreview';
import Steps from './Steps';
import UpgradeMembershipStep0 from './Step0';
import UpgradeMembershipStep1 from './Step1';
import UpgradeMembershipStep2 from './Step2';
import UpgradeMembershipStep3 from './Step3';
import classNames from 'classnames';
import commonConstants from 'constants/commonContants';
import format from 'number-format.js';
import { getMembership } from 'redux/actionCreators/membership';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useTypedSelector } from 'redux/configureStore';
import emitNotify from 'utility/emitNotify';

const MEMBERSHIP_STATUS_CODE_ALLOW_UPGRADE = ['OK', 'FZ', 'JN'];
const brandsAllowUpgrade: { [key: string]: boolean } = {
  CF: true,
  FF: true,
};

export const renderPrice = (amount = 0, { currencyFormat, currencyCode }: any, translate: any) => {
  if (amount === 0) {
    return translate('SS_UPGRADE_MEMBERSHIP_BASIC_PLAN.INCLUDED');
  }
  return `${currencyCode} ${format(currencyFormat, amount)}/${translate('SS_MEMBERSHIP_BY_ADDON_MONTH')}`;
};

const UpgradeMembership = () => {
  const storageData = JSON.parse(sessionStorage.getItem(SESSION_STORAGE_NAMES.UPGRADED_PREVIEW) || '""');
  const [selectedClub, setSelectedClub] = useState<number>(storageData.clubId || -1);
  const [isConfirmUnsave, setConfirmUnsave] = useState<boolean>(false);
  const [viewDetail, setViewDetail] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<SelectedDataProps>();
  const [planInfo, setPlanInfo] = useState<PlanProps>();
  const [previewData, setPreviewData] = useState<PreviewPaymentSummaryProps>();
  const [lockScreen, setLockScreen] = useState(false);

  const history = useHistory();
  let { path } = useRouteMatch();
  const { t } = useTranslation();
  const user = useTypedSelector((state) => state.login.user);
  const { paymentMethodsResponse } = useTypedSelector((state) => state.payment);
  const notification = useTypedSelector((state) => state.notification);
  const brandCode = useMemo(() => user.profile?.brandCode || '', [user]);
  const regexs = useTypedSelector((state) => state.personal.regexs);
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();

  const { membershipDetails, isLoading } = useTypedSelector((state) => state.membership);
  const existedAddonIds = useMemo(() => membershipDetails.addons?.map((addon: any) => addon.addonId), [
    membershipDetails,
  ]);
  const currencyCode = regexs.find((regex) => regex.key === PERSONAL_CONSTANTS.CURRENCY_REGEX) || {
    key: '',
    value: '',
  };
  const currencyFormat = regexs.find((regex) => regex.key === PERSONAL_CONSTANTS.CURRENCY_FORMAT_REGEX) || {
    key: '',
    value: '',
  };

  const dispatch = useDispatch();
  const getMembershipAction = () => dispatch(getMembership());
  const clearPaymentAction = (clearResponse?: boolean) => dispatch(clearPaymentState(clearResponse));
  const getPaymentMethodAction = (memberId: number) => dispatch(getPaymentMethod(memberId));

  useEffect(() => {
    if (Object.keys(membershipDetails).length === 0) {
      getMembershipAction();
    }
    if (membershipDetails.memberId && !paymentMethodsResponse) {
      getPaymentMethodAction(membershipDetails.memberId);
    }
  }, [isLoading, membershipDetails, paymentMethodsResponse]);

  useEffect(() => {
    switch (true) {
      case !!sessionStorage.getItem(SESSION_STORAGE_NAMES.PROCESS_PURPOSE):
        break;
      default:
        history.push(UPGRADE_MEMBERSHIP_STEP0_URL);
    }
  }, []);

  useEffect(() => {
    if (membershipDetails.memberId > -1) {
      const isAllowUpgradeMembership =
        membershipDetails.clubTierCode === commonConstants.VISUAL_STUDIO_CLUB_CODE &&
        MEMBERSHIP_STATUS_CODE_ALLOW_UPGRADE.includes(membershipDetails.memberStatusCode) &&
        membershipDetails.currentBalance >= 0 &&
        !!brandsAllowUpgrade[brandCode];

      if (!isAllowUpgradeMembership) {
        history.push(MEMBERSHIP_URL);
      }
    }
  }, [brandCode, membershipDetails, isLoading]);

  useEffect(() => {
    switch (location.pathname) {
      case UPGRADE_MEMBERSHIP_STEP0_URL:
        setViewDetail(false);
        setSelectedItem(undefined);
        setPlanInfo(undefined);
        break;
      case UPGRADE_MEMBERSHIP_STEP1_URL:
        setViewDetail(false);
        selectedItem &&
          setSelectedItem({
            ...selectedItem,
            addons: [],
          });
        break;
      case UPGRADE_MEMBERSHIP_STEP2_URL:
        setViewDetail(false);
        break;
      case UPGRADE_MEMBERSHIP_CONFIRM_PAYMENT_URL:
        setViewDetail(false);
        break;
      case UPGRADE_MEMBERSHIP_STEP3_URL:
        break;
      default:
        break;
    }
  }, [location.pathname]);

  useEffect(() => {
    if (!selectedItem && storageData) {
      setSelectedItem({
        commitmentLength: storageData.commitmentLength as Length,
        accessLevel: storageData.accessLevel as SingleAccessLevel,
        addonClass: storageData.addonClasses,
        addons: storageData.addons,
        startDate: new Date(storageData.startDate) || undefined,
        promotionCode: storageData.promotionCode,
        needToCallPreview: true,
      } as SelectedDataProps);
    }
  }, []);

  const getData = useCallback(async () => {
    if (selectedClub < 0) {
      history.push(UPGRADE_MEMBERSHIP_STEP0_URL);
      return;
    }
    try {
      const res = await getPlanData(`${selectedClub}`, existedAddonIds);
      setPlanInfo(res);
    } catch (e) {
      if (e.response.status === 400) {
        emitNotify(enqueueSnackbar, t('SS_UPGRADE_MEMBERSHIP_TITLE'), t('SS_UPGRADE_MEMBERSHIP.ERROR'), 'error');
      }
    }
  }, [selectedClub, existedAddonIds]);

  const upgradeMembershipFailMessage = [
    'ONLINE_PAYMENT_FUNDING_SOURCE_IS_INVALID_WITH_DEBIT_METHOD',
    'ONLINE_PAYMENT_METHOD_IS_EMPTY',
    'ONLINE_PAYMENT_METHOD_IS_NOT_ALLOWED',
    'ONLINE_PAYMENT_FUNDING_SOURCE_IS_INVALID_WITH_CREDIT_METHOD',
  ];

  useEffect(() => {
    getData();
  }, [getData]);

  useEffect(() => {
    if (upgradeMembershipFailMessage.includes(notification.message)) {
      setLockScreen(false);
      clearPaymentAction(true);
    }
  }, [notification.id]);

  const getPreviewPaymentSummary = useCallback(async () => {
    if (!selectedItem || !selectedItem.needToCallPreview) {
      return;
    }
    try {
      const action = planInfo?.behaviors.find(
        (behavior) =>
          behavior.condition.clusterId === selectedItem.accessLevel.clusterId &&
          behavior.condition.reciprocalAccessLevelCode === selectedItem.accessLevel.code &&
          behavior.condition.commitmentLength === selectedItem.commitmentLength.duration
      )?.action;

      const startDateParam = moment(selectedItem.startDate || action?.startDateBegin).format(SYSTEM_DATE_FORMAT);
      const addonIds = selectedItem.addons.map((item) => item.id);
      const res = await getPreviewData({
        clubId: selectedClub,
        reciprocalAccessCode: selectedItem.accessLevel.code as string,
        clusterId: selectedItem.accessLevel.clusterId as number,
        membershipLength: selectedItem.commitmentLength.duration || 0,
        startDate: startDateParam,
        addonClassIds: selectedItem.addonClass.map((item) => item.id),
        addonIds,
        promotionCode: selectedItem.promotionCode || null,
      });
      setSelectedItem({
        ...selectedItem,
        addonClass: res.addonClasses,
        needToCallPreview: false,
      });
      setPreviewData(res);
    } catch (e: any) {
      if (e?.response?.status === 400) {
        emitNotify(enqueueSnackbar, t('SS_UPGRADE_MEMBERSHIP_TITLE'), t('SS_UPGRADE_MEMBERSHIP.ERROR'), 'error');
      }
    }
  }, [selectedClub, selectedItem, planInfo]);

  useEffect(() => {
    getPreviewPaymentSummary();
  }, [getPreviewPaymentSummary]);

  const currentStep = useMemo(() => {
    switch (location.pathname) {
      case UPGRADE_MEMBERSHIP_STEP1_URL:
        return 1;
      case UPGRADE_MEMBERSHIP_STEP2_URL:
      case UPGRADE_MEMBERSHIP_CONFIRM_PAYMENT_URL:
        return 2;
      case UPGRADE_MEMBERSHIP_STEP3_URL:
        return 3;
      default:
        return -1;
    }
  }, [location.pathname]);

  const stepStyle = useMemo(() => {
    if (viewDetail && selectedItem) {
      return {
        opacity: 0,
        overflow: 'hidden',
        height: '0px',
      };
    }
    return {};
  }, [viewDetail, selectedItem]);

  const gotoNextStep = (clubId: number) => {
    if (clubId !== selectedClub) {
      setSelectedClub(clubId);
      setSelectedItem(undefined);
    }
    history.push(UPGRADE_MEMBERSHIP_STEP1_URL);
  };

  useEffect(() => {
    if (selectedItem && planInfo && previewData) {
      const upgradedPreview = {
        clubId: selectedClub,
        startDate: selectedItem.startDate,
        accessLevel: selectedItem.accessLevel,
        commitmentLength: selectedItem.commitmentLength,
        basicPlanShortDescriptionBaseValue: planInfo.basicPlan.shortDescription.baseValue,
        basicPlanClubName: planInfo.basicPlan.club.name,
        basicPlanRecurringAmount: planInfo.basicPlan.recurring.amount,
        payNowAmount: previewData.paynow.amount,
        payNowOriginalAmount: previewData.paynow.originalAmount,
        payNowDiscountAmount: previewData.paynow.discountAmount,
        recurringAmount: previewData.recurring.amount,
        recurringOriginalAmount: previewData.recurring.originalAmount,
        recurringDiscountAmount: previewData.recurring.discountAmount,
        addonClasses: previewData.addonClasses?.map((item: any) => {
          return {
            id: item.id,
            name: item.name,
            amount: item.amount,
          };
        }),
        addons: previewData.addons?.map((item: any) => {
          return {
            id: item.id,
            name: item.name,
            amount: item.amount,
          };
        }),
        recurringAddons: previewData.recurring.addonFees?.map((item: any) => {
          return {
            addonId: item.addonId,
            addonName: item.addonName,
            amount: item.amount,
            isAddonClass: item.isAddonClass,
            promotion: item.promotion,
          };
        }),
        recurringPackageFees: previewData.recurring.packageFees?.map((item: any) => {
          return {
            promotion: item.promotion,
          };
        }),
        firstBilling: previewData.firstBilling.startDate,
        payNowPackageFees: previewData.paynow.packageFees?.filter((item: any) => {
          if (item.feeCode === 'PR')
            return {
              feeCode: item.feeCode,
              amount: item.amount,
            };
        }),
        payNowAddonFees: previewData.paynow.addonFees?.filter((item: any) => {
          if (item.feeCode === 'PR')
            return {
              feeCode: item.feeCode,
              amount: item.amount,
              addonId: item.addonId,
              addonName: item.addonName,
            };
        }),
        recurringPromotionCode:
          previewData &&
          (previewData.recurring.addonFees.find((item) => item?.promotion)?.promotion.code ||
            previewData.recurring.packageFees.find((item) => item?.promotion)?.promotion.code),
        payNowPromotionCode:
          previewData &&
          (previewData.paynow.addonFees.find((item) => item?.promotion)?.promotion.code ||
            previewData.paynow.packageFees.find((item) => item?.promotion)?.promotion.code),
      };
      sessionStorage.setItem(SESSION_STORAGE_NAMES.UPGRADED_PREVIEW, JSON.stringify(upgradedPreview));
    }
  }, [selectedItem, planInfo, previewData]);

  if (isLoading) return <CircularProgress className="loading" />;

  return (
    <>
      <div
        className={classNames({
          'upgrade-membership': true,
          'upgrade-membership--cf': brandCode === commonConstants.CF_BRAND,
        })}
      >
        {lockScreen && (
          <div className="upgrade-membership__overlay">
            <CircularProgress className="loading" />
          </div>
        )}
        <div className="upgrade-membership__button">
          <button
            className="btn btn-primary btn-effect"
            onClick={() => {
              gtag('event', 'Button', {
                event_action: 'MUMBackToSS',
                event_label: 'MUMBackToSSClicked',
              });
              setConfirmUnsave(true);
            }}
          >
            {t('SS_UPGRADE_MEMBERSHIP_BACK_TO_SS')}
          </button>
        </div>
        {currentStep > 0 ? <Steps currentStep={currentStep} brandCode={brandCode} /> : null}
        {viewDetail && selectedItem && planInfo && previewData && (
          <div className="upgrade-membership__container order__container">
            <PackagePreview
              startDate={selectedItem.startDate}
              accessLevel={selectedItem.accessLevel}
              commitmentLength={selectedItem.commitmentLength}
              basicPlan={planInfo.basicPlan}
              addonClass={selectedItem.addonClass}
              previewData={previewData}
              addons={selectedItem.addons}
              setViewDetail={setViewDetail}
              setSelectedItem={setSelectedItem}
              selectedItem={selectedItem}
              showCloseButton
            />
          </div>
        )}
        <Switch>
          <Route path={`${path}/step-0`}>
            <div style={stepStyle}>
              <UpgradeMembershipStep0 brandCode={brandCode} setSelectedClub={gotoNextStep} />
            </div>
          </Route>
          <Route path={`${path}/step-1`}>
            <div style={stepStyle}>
              <UpgradeMembershipStep1
                clubId={selectedClub}
                brandCode={brandCode}
                planInfo={planInfo}
                selectedItem={selectedItem}
                setSelectedItem={setSelectedItem}
                previewData={previewData}
              />
            </div>
          </Route>
          <Route path={`${path}/step-2`}>
            <div style={stepStyle}>
              <UpgradeMembershipStep2
                brandCode={brandCode}
                addons={planInfo?.basicPlan.options.addons}
                currencyCode={currencyCode.value}
                currencyFormat={currencyFormat.value}
                selectedItem={selectedItem}
                setSelectedItem={setSelectedItem}
              />
            </div>
          </Route>
          <Route path={`${path}/confirmation-payment`}>
            <div style={stepStyle}>
              {selectedItem && planInfo && previewData && (
                <div className="upgrade-membership__container order__container">
                  <PackagePreview
                    startDate={selectedItem.startDate}
                    accessLevel={selectedItem.accessLevel}
                    commitmentLength={selectedItem.commitmentLength}
                    basicPlan={planInfo.basicPlan}
                    addonClass={selectedItem.addonClass}
                    previewData={previewData}
                    addons={selectedItem.addons}
                    setViewDetail={setViewDetail}
                    showCloseButton={false}
                    setSelectedItem={setSelectedItem}
                    selectedItem={selectedItem}
                  />
                </div>
              )}
            </div>
          </Route>
          <Route path={`${path}/step-3`}>
            <div style={stepStyle}>
              <div className="upgrade-membership__container order__container">
                <UpgradeMembershipStep3
                  selectedItem={selectedItem}
                  selectedClub={selectedClub}
                  paynowAmount={previewData?.paynow.amount}
                  recurringAmount={previewData?.recurring.amount}
                  setLockScreen={setLockScreen}
                />
              </div>
            </div>
          </Route>
        </Switch>

        {window.location.pathname !== UPGRADE_MEMBERSHIP_STEP0_URL && selectedItem ? (
          <Footer
            selectedItem={selectedItem}
            currencyCode={currencyCode.value}
            currencyFormat={currencyFormat.value}
            currentStep={currentStep}
            paynowAmount={previewData?.paynow.amount}
            isViewDetail={viewDetail}
            setViewDetail={setViewDetail}
          />
        ) : null}
      </div>
      <ModalConFirm
        confirmLabel={t('SS_LEAVE')}
        confirmAction={() => {
          gtag('event', 'Button', {
            event_action: 'MUMExitConfirm',
            event_label: 'MUMExitConfirmClicked',
          });
          history.push(MEMBERSHIP_URL);
          clearPaymentAction(true);
          sessionStorage.removeItem(SESSION_STORAGE_NAMES.PAYMENT_TYPE);
          sessionStorage.removeItem(SESSION_STORAGE_NAMES.PROCESS_PURPOSE);
          sessionStorage.removeItem(SESSION_STORAGE_NAMES.AMOUNT_VALUE);
          sessionStorage.removeItem(SESSION_STORAGE_NAMES.UPGRADED_MEMBERSHIP);
          sessionStorage.removeItem(SESSION_STORAGE_NAMES.UPGRADED_PREVIEW);
        }}
        displayMessage={t('SS_CONFIRM_MESS')}
        openModal={isConfirmUnsave}
        handleOpenModal={(value: SetStateAction<boolean>) => {
          gtag('event', 'Button', {
            event_action: 'MUMCancelConfirm',
            event_label: 'MUMCancelConfirmClicked',
          });
          setConfirmUnsave(value);
        }}
      />
    </>
  );
};

export default UpgradeMembership;
