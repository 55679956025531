import React, { useState, useEffect, useMemo, MutableRefObject } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';

import { useTypedSelector } from 'redux/configureStore';
import MenuAndInfo from 'components/MenuAndInfo';
import { membershipActions, personalActions } from 'redux/actionCreators';

import MembershipInfo from './MembershipInfo';
import Payments from './Payments';
import PersonalInfo from './PersonalInfo';
import { useSnackbar } from 'notistack';
import ChangePassword from './ChangePassword';
import { FEATURE_CODE } from 'constants/baseConstants';
import Inbox from './Inbox';
import PERSONAL_CONSTANTS, { SESSION_STORAGE_NAMES } from 'constants/personalConstant';
import { getRecentActivity } from 'services/inbox';
import ContactUs from './ContactUs';
import AddonPayment from './AddonPayment';
import {
  CHANGE_PASSWORD_URL,
  INBOX_URL,
  MEMBERSHIP_URL,
  PAYMENT_URL,
  PERSONAL_URL,
  CONTACT_US_URL,
} from 'utility/routesURL';
import { setNotification } from 'redux/actionCreators/notification';
import { Message } from 'components/CustomSnackbar';

const theme = createMuiTheme({
  overrides: {
    MuiOutlinedInput: {
      root: {
        '& $notchedOutline': {
          borderColor: '#E0E0E0',
        },
        '&:hover $notchedOutline': {
          borderColor: '#E0E0E0',
        },
        '&$focused $notchedOutline': {
          borderColor: '#1825F5',
        },
      },
    },
  },
});

interface MemberRouteProps {
  hasChangeRef: MutableRefObject<boolean>;
  setChangePasswordStatus: any;
}
const MemberRoute: React.FC<MemberRouteProps> = ({ hasChangeRef, setChangePasswordStatus }) => {
  //redux store
  const { getMembership } = membershipActions;
  const { getPersonalFields } = personalActions;

  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const getMembershipAction = () => dispatch(getMembership());

  const getPersonalFieldsAction = () => dispatch(getPersonalFields(FEATURE_CODE));

  const membershipDetails = useTypedSelector((state) => state.membership.membershipDetails);
  const isLoadingMembership = useTypedSelector((state) => state.membership.isLoading);
  const isLoadingTheme = useTypedSelector((state) => state.theme.isLoading);
  const notification = useTypedSelector((state) => state.notification);
  const isSocialLogin = useTypedSelector((state) => state.oidc.user.profile?.isSocialLogin);
  const regexs = useTypedSelector((state) => state.personal.regexs);
  const user = useTypedSelector((state) => state.login.user);

  const [isEditPayment, setIsEditPayment] = useState(false);
  const [isMakePayment, setIsMakePayment] = useState(false);
  const [dirtyTabForm, setDirtyTabForm] = useState(false);
  const [openAddons, setOpenAddons] = useState(false);
  const [openAccessClubs, setOpenAccessClubs] = useState(false);
  const [loginActivities, setLoginActivities] = useState([]);
  const [toggleMenu, setToggleMenu] = useState(true);

  const showLocation = regexs.find((ph) => ph.key === PERSONAL_CONSTANTS.SHOW_LOCATION) || {
    key: '',
    value: '',
  };
  const { t } = useTranslation();

  const isMember = !!membershipDetails.memberId && membershipDetails.memberId > 0;
  const MEMBERSHIP_STATUS_CODE_ALLOW_ADD_ADDON = ['OK', 'FZ', 'JN'];
  const isAllowAddAddon = useMemo(() => {
    return (
      MEMBERSHIP_STATUS_CODE_ALLOW_ADD_ADDON.includes(membershipDetails.memberStatusCode) &&
      membershipDetails.currentBalance >= 0
    );
  }, [membershipDetails.memberStatusCode, membershipDetails.currentBalance]);

  useEffect(() => {
    if (Object.keys(membershipDetails).length === 0) {
      getMembershipAction();
    }
  }, []);

  useEffect(() => {
    getPersonalFieldsAction();
  }, []);

  useEffect(() => {
    hasChangeRef.current = isEditPayment || isMakePayment || dirtyTabForm;
  }, [isEditPayment, isMakePayment, dirtyTabForm]);

  useEffect(() => {
    if (user.profile && membershipDetails.countryCode) {
      (async () => {
        const { data } = await getRecentActivity({
          emailAddress: user.profile?.email,
          countryCode: membershipDetails.countryCode,
          brandCode: user.profile?.brandCode,
          amount: 10,
        });
        if (showLocation.value && !sessionStorage.getItem(SESSION_STORAGE_NAMES.LOCATION_IP)) {
          sessionStorage.setItem(SESSION_STORAGE_NAMES.LOCATION_IP, `${data[0].location} (${data[0].ipAddress})`);
        }
        setLoginActivities(data);
      })();
    }
  }, [user, membershipDetails.countryCode, window.location.pathname]);

  const renderMessageComponent = (notification: any) => {
    switch (notification.type) {
      case 'addAddon':
        return (
          <Message
            title={t(`${notification.title}`)}
            message={t('SS_NOTIFICATION_MEMBERSHIP_INFORMATION_UPDATED', {
              addon: notification.message,
            })}
            variant={notification.isSuccess ? 'success' : 'error'}
          />
        );
      case 'cancelAddon':
        return (
          <Message
            title={t(`${notification.title}`)}
            message={t('SS_NOTIFICATION_MEMBERSHIP_ADDON_CANCELED', {
              addon: notification.message,
            })}
            variant={notification.isSuccess ? 'success' : 'error'}
          />
        );
      default:
        return (
          <Message
            title={t(`${notification.title}`)}
            message={t(`${notification.message}`)}
            variant={notification.isSuccess ? 'checked' : 'error'}
          />
        );
    }
  };

  useEffect(() => {
    if (notification.id) {
      const snackbarOptions = notification.options || {};
      const autoHideDuration = snackbarOptions.autoHideDuration ?? 3000;

      enqueueSnackbar(renderMessageComponent(notification), {
        ...snackbarOptions,
        autoHideDuration,
      });
      dispatch(setNotification({ title: '', message: '', type: '', id: '', isSuccess: true }));
    }
  }, [notification.id]);

  const renderMemberRoute = () => (
    <Switch>
      <Route
        path={MEMBERSHIP_URL}
        exact
        render={() => (
          <MembershipInfo
            setOpenAddons={setOpenAddons}
            openAddons={openAddons}
            setOpenAccessClubs={setOpenAccessClubs}
            openAccessClubs={openAccessClubs}
            isAllowAddAddon={isAllowAddAddon}
            setToggleMenu={setToggleMenu}
          />
        )}
      />
      {isAllowAddAddon && (
        <Route
          path="/addon-payment"
          exact
          render={() => <AddonPayment setIsMakePayment={setIsMakePayment} isMakePayment={isMakePayment}></AddonPayment>}
        />
      )}

      <Route path={PERSONAL_URL} render={() => <PersonalInfo setDirtyTabForm={setDirtyTabForm} />} />
      <Route
        path={PAYMENT_URL}
        render={() => (
          <Payments
            setIsEditPayment={setIsEditPayment}
            isEditPayment={isEditPayment}
            setIsMakePayment={setIsMakePayment}
            isMakePayment={isMakePayment}
            userInfo={user.profile}
          />
        )}
      />
      <Route path={INBOX_URL} render={() => <Inbox />} />

      {isSocialLogin?.toLocaleLowerCase() === 'false' && (
        <Route
          path={CHANGE_PASSWORD_URL}
          render={() => <ChangePassword setChangePasswordStatus={setChangePasswordStatus} />}
        />
      )}

      <Route path={CONTACT_US_URL} render={() => <ContactUs />} />

      <Route render={() => <Redirect to={MEMBERSHIP_URL} />} />
    </Switch>
  );

  const renderChangePasswordRoute = () =>
    isSocialLogin?.toLocaleLowerCase() === 'false' ? (
      <Switch>
        <Route
          path={CHANGE_PASSWORD_URL}
          render={() => <ChangePassword setChangePasswordStatus={setChangePasswordStatus} />}
        />
      </Switch>
    ) : (
      <></>
    );

  const renderNonMemberRoute = () => (
    <h4 style={{ textAlign: 'center', color: '#0C1FFF' }}>
      {t('SS_WELCOME_NON_MEMBER', {
        name: user.profile?.name,
      })}
    </h4>
  );

  const renderContent = () => {
    if (membershipDetails && isMember) {
      return renderMemberRoute();
    } else if (Object.keys(membershipDetails).length > 0) {
      return (
        <div className="non-member__wrapper">
          {window.location.pathname === CHANGE_PASSWORD_URL ? renderChangePasswordRoute() : renderNonMemberRoute()}
        </div>
      );
    }
    return <></>;
  };

  return (
    <MuiThemeProvider theme={theme}>
      <MenuAndInfo
        setDirtyTabForm={setDirtyTabForm}
        dirtyTabForm={dirtyTabForm}
        isEditPayment={isEditPayment}
        isMakePayment={isMakePayment}
        setIsMakePayment={setIsMakePayment}
        membershipDetails={membershipDetails}
        isLoading={isLoadingMembership || isLoadingTheme}
        loginActivities={loginActivities}
        setToggleMenu={setToggleMenu}
        toggleMenu={toggleMenu}
      >
        {renderContent()}
      </MenuAndInfo>
    </MuiThemeProvider>
  );
};

export default MemberRoute;
