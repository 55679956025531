import {
  CANCEL_ADDON,
  GET_ADDON,
  GET_ALL_CLUBS,
  GET_BILLING_DATE,
  GET_MEMBERSHIP,
  GET_SYSTEM_DATE,
  PACKAGE_PLANS,
  SUBMIT_INQUIRY,
} from './apis';

import { AddonInterface } from 'components/Addon/types';
import axios from 'axios';
import { baseApi } from './base';

export async function getMembershipService(): Promise<any> {
  return await baseApi.get(GET_MEMBERSHIP);
}

export async function getPackagePlansService(memberId: string): Promise<any> {
  return await baseApi.get(PACKAGE_PLANS.replace('{{memberId}}', memberId));
}

export async function checkAvatarUrl(url: string): Promise<any> {
  try {
    const res = await axios.get(url);

    if (res.status === 204) {
      return false;
    }
  } catch {}

  return true;
}

export const getAddons = async (memberId: string): Promise<AddonInterface[]> => {
  const { data } = await baseApi.get(GET_ADDON.replace('{{memberId}}', memberId));
  return data;
};

export const getSystemDate = async (): Promise<any> => {
  const { data } = await baseApi.get(GET_SYSTEM_DATE);
  return data;
};

export const getBillingDate = async (currentDate: string): Promise<any> => {
  const { data } = await baseApi.get(GET_BILLING_DATE.replace('{{currentDate}}', currentDate));
  return data;
};

export const cancelAddon = async (memberId: string, addonId: string): Promise<any> => {
  const { data } = await baseApi.put(CANCEL_ADDON.replace('{{memberId}}', memberId), { addonId });
  return data;
};

export const submitInquiry = async (memberId: string, body: any): Promise<any> => {
  const { data } = await baseApi.post(SUBMIT_INQUIRY.replace('{{memberId}}', memberId), body);
  return data;
};

export const getAllClubs = async (): Promise<any> => {
  const { data } = await baseApi.get(GET_ALL_CLUBS);
  return data;
};
